import React from "react";
import {trackPromise} from "react-promise-tracker";
import {Col, Container, Row} from "react-bootstrap";
import "../../css/login.css"
import {ModalBase} from "../../js/modal";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "identity": "",
            "isLogin": false,
        }
    }

    componentDidMount() {
        this.checkIdentity();
    }

    checkIdentity() {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity && checkIdentity !== "") {
            if (this.state.identity === "") {
                this.setState({"identity": checkIdentity})
                this.setState({"isLogin": true});
            }
        } else {
            this.setState({"isLogin": false});
        }
    }

    showInfo(msg) {
        this.setState({"showInfo": !this.state.showInfo, "message": msg});
    }

    redirectTo = () => {
        const path = window.location.href.replace("management", "")
        window.location.href = path;
    }

    login = () => {
        let account = document.getElementById("account").value;
        let password = document.getElementById("password").value;
        let info = ""
        if (!account) {
            info = "請輸入帳號"
        } else if (!password) {
            info = "請輸入密碼"
        } else {
            trackPromise(
                fetch(`/login`, {
                    method: "POST",
                    body: JSON.stringify({
                        "account": account,
                        "password": password
                    }),
                    headers: {
                        "Content-type": "application/json",
                    }
                }).then(response => {
                    for (const header of response.headers) {
                        if (header[0] === "x-auth-token") {
                            localStorage.setItem("token", "Bearer " + header[1])
                        }
                    }

                    if (response.status === 200) {
                        return response.json()
                    } else {
                        return {"result": false, "message": "登入失敗"}
                    }
                }).then(response => {
                    if (response.result) {
                        const identity = response.data.identity;
                        localStorage.setItem("identity", identity)
                        localStorage.setItem("account", account)
                        this.setState({"identity": identity})
                        this.setState({"isLogin": true});

                        this.redirectTo();
                    } else {
                        document.getElementById("loginErrorInfo").innerHTML = response.message
                    }
                })
                    .catch(e => {
                        console.log("catch error:", e)
                        this.showInfo("登入失敗，請重新輸入帳號密碼")
                        document.getElementById("loginErrorInfo").innerHTML = "登入失敗"

                    })
            )
        }
        if (info !== "") {
            this.showInfo(info)
        }

    }

    render() {
        return (
            <>
                <Container className="login px-0" fluid={true}>
                    <Row className="m-0">
                        <Col className="d-flex justify-content-center align-items-center">
                            <div className="loginWrap">
                                <h3 className="mb-3 text-center">新北市工業會</h3>
                                <input type="Text" className="form-control mb-4 w-100" placeholder="請輸入帳號"
                                       id="account"/>
                                <input type="password" className="form-control mb-4" placeholder="請輸入密碼"
                                       id="password" type="password"/>
                                <div className="text-center">
                                    <button type="button" className="w-100 btn loginBtn rounded" onClick={this.login}>
                                        <span className="font-weight-bold">登入</span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <ModalBase show={this.state.showInfo} ok={() => {
                    this.showInfo()
                }} close={() => {
                    this.showInfo()
                }} message={this.state.message} error={this.errorPage}/>

            </>
        )
    }
}

export default {
    routeProps: {
        path: "/management",
        component: Login
    },
    name: "登入",
}