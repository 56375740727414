import React from "react";
import { Col, Row } from "react-bootstrap"

import { trackPromise } from "react-promise-tracker";
import { TrainRequest } from "../../js/request/trainRequest"
import { ActivityRequest } from "../../js/request/activityRequest";
import { AnnouncementRequest } from "../../js/request/announcementRequest";

import { ModalBase, ModalCourseInfo, ModalHasContent, ModalLectureApply, ModalSureSend } from "../../js/modal.js"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            identity: false,
            "contentNew": [0, 1, 2, 3, 4],
            "contentTrain": [0, 1, 2, 3, 4, 5],
            "lectureTopItem": [],
            "albumTopItem": "",
            "announcementTopItem": "",
            "showlectureInfo": false,
            "showApply": false,
            "isOSAH": false,
            "sureSend": false,
            "showInfo": false,
            "infoMessage": "",
            "lectureItem": {
                "id": "",
                "name": "",
                "address": "",
                "startClassTime": "",
                "endClassTime": "",
                "deadLine": "",
                "studentCount": "",
                "lectureoverPeople": "",
                "memberPrice": "",
                "notMemberPrice": "",
                "teacherName": "",
                "teacherDescription": "",
                "content": "",
                "attachment": "",
                "classHour": null,
                "courseMemoArray": [],
                "safetyMemo": []
            },
            "modalShow": false,
        };
    }

    componentDidMount = () => {
        this.getTopLecture();
    }

    getTopLecture = () => {
        trackPromise(
            TrainRequest.getTop()
                .then(response => {
                    this.setState({ lectureTopItem: response.data })
                })
        )
        trackPromise(
            ActivityRequest.getTop()
                .then(response => {
                    this.setState({ albumTopItem: response.data });
                })
        )
        trackPromise((
            AnnouncementRequest.getTop()
                .then(response => {
                    this.setState({ announcementTopItem: response.data });
                })
        ))
    }

    getDetail = (id) => {
        trackPromise(
            TrainRequest.getDetail(id)
                .then(response => {
                    let lectureItem = response.data
                    this.getMemo(response.data.templateId)
                    this.setState({ "lectureItem": lectureItem })
                    document.getElementById("lectureInfo").innerHTML = response.data.content.replace(/\n/g, "<br />");
                })
        )
    }

    getMemo = (templateId) => {
        trackPromise(
            TrainRequest.getOSFMemo(templateId)
                .then(response => {
                    this.setState({ "safetyMemo": response.data.courseMemoArray })
                })
        )
    }

    showDetail = (no, type) => {
        if (!this.state.showlectureInfo) {
            this.getDetail(no)
        }
        this.setState({ "type": type })
        this.setState({ "showlectureInfo": !this.state.showlectureInfo })
    }

    showApply = (no, type) => {
        if (this.state.showlectureInfo) {
            this.setState({ "showlectureInfo": false })
        }
        if (!this.state.showApply) {
            this.getDetail(no)
        }
        this.setState({ "type": type })
        this.setState({ "showApply": !this.state.showApply })
    }

    applySubhead = (type) => {
        this.setState({ "isOSAH": type === "1" })
    }

    handleModalShow = (index) => {
        this.setState({ targetIndex: index });
        this.setState({ modalShow: true })
    }

    handleModalClose = () => {
        this.setState({ modalShow: false })
    }

    sureSend = () => {
        this.setState({ "sureSend": !this.state.sureSend })
    }

    showInfo = (message) => {
        this.setState({ "showInfo": !this.state.showInfo })
        this.setState({ "infoMessage": message });
    }

    signUp = () => {
        const educationElement = document.getElementById("education");
        const requestData = {
            "courseId": this.state.lectureItem.id,
            "memberId": document.getElementById("memberId").value,
            "companyName": document.getElementById("companyName") ? document.getElementById("companyName").value : null,
            "name": document.getElementById("name").value,
            "email": document.getElementById("email").value,
            "cellPhone": document.getElementById("cellPhone").value,
            "phone": document.getElementById("phone").value,
            "faxNumber": document.getElementById("faxNumber").value,
            "address": document.getElementById("address").value,
            "mealRequirement": document.querySelector("input[name=\"mealRequirement\"]:checked") ? document.querySelector("input[name=\"mealRequirement\"]:checked").value : null,
            "memo": document.getElementById("memo").value,
            "birthday": document.getElementById("birthday") ? document.getElementById("birthday").value : null,
            "identity": document.getElementById("identity") ? document.getElementById("identity").value : null,
            "education": educationElement ? educationElement.options[educationElement.selectedIndex].value : null,
            "graduatedSchool": document.getElementById("graduatedSchool") ? document.getElementById("graduatedSchool").value : null,
            "certificateOfCompletionNumber": document.getElementById("certificateOfCompletionNumber") ? document.getElementById("certificateOfCompletionNumber").value : null,
            "serviceUnit": document.getElementById("serviceUnit") ? document.getElementById("serviceUnit").value : null,
            "residenceAddress": document.getElementById("residenceAddress").value,
            "contactName": document.getElementById("contactName").value,

        }

        let reuqestMethod = "";
        let info = "";
        if (!requestData.name) {
            info = "請輸入姓名";
        } else if (!requestData.email) {
            info = "請輸入電子信箱";
        } else if (!requestData.cellPhone) {
            info = "請輸入手機號碼";
        } else if (!requestData.phone) {
            info = "請輸入電話號碼";
        } else if (!requestData.address) {
            info = "請輸入聯絡地址";
        } else {
            if (this.state.type === 1) {
                if (!requestData.birthday) {
                    info = "請輸入生日";
                } else if (!requestData.identity) {
                    info = "請輸入身分證字號"
                } else if (!requestData.education) {
                    info = "請輸入學歷";
                } else if (!requestData.graduatedSchool) {
                    info = "請輸入畢業學校";
                } else if (!requestData.serviceUnit) {
                    info = "請輸入服務單位";
                } else if (!requestData.residenceAddress) {
                    info = "請輸入戶籍地址";
                } else if (!requestData.contactName) {
                    info = "請輸入聯絡人姓名";
                } else {
                    reuqestMethod = TrainRequest.createSignUpSafetyHealth;
                }
            } else {
                if (!requestData.companyName) {
                    info = "請輸入公司名稱";
                } else if (!requestData.mealRequirement) {
                    info = "請輸入用餐需求";
                } else {
                    reuqestMethod = TrainRequest.createSignUpLecture;
                }
            }
        }

        if (reuqestMethod) {
            trackPromise(
                reuqestMethod(requestData)
                    .then(response => {
                        this.setState({ "sureSend": !this.state.sureSend })
                        this.showInfo("新增成功")
                        window.location.reload();
                    })
                    .catch(error => {
                        this.setState({ "sureSend": !this.state.sureSend })
                        this.showInfo("新增失敗")
                        document.getElementById("addLectureErrorInfo").innerHTML = error.message
                        console.log(error.message)
                    })
            )
        } else {
            this.showInfo(info);
            this.setState({ "sureSend": !this.state.sureSend })
        }

    }

    render() {
        return (
            <>
                <div>
                    <div>
                        <Row noGutters={ true } className="title">
                            <Col xs={ 1.5 } className="titleFontSize">最新消息</Col>
                            <Col/>
                            <Col xs={ 1.5 } className="contentRight contentFontSize">
                                <a href="/announcement" className="more">more ></a>
                            </Col>
                        </Row>
                    </div>
                    <div className="contentPadding">
                        { this.state.announcementTopItem &&
                            this.state.announcementTopItem.map((data, index) => {
                                return (
                                    <div className="contentBorder" key={ index }>
                                        <Row noGutters={ true } className="contentRow">
                                            <Col xs={ 1.5 }>
                                                <div className="contentDiv newDiv" style={ { marginRight: "15px" } }>NEW
                                                </div>
                                            </Col>
                                            <Col className="contentFontSize ellipsis"
                                                 onClick={ () => this.handleModalShow(index) }>{ data.title }</Col>
                                            <Col xs={ 2.5 }
                                                 className="contentRight dateFontSize hiddenDate">{ data.createDate }</Col>
                                        </Row>
                                    </div>
                                )
                            }) }
                    </div>
                    <div className="contentPadding">
                        <Row noGutters={ true } className="title">
                            <Col xs={ 1.5 } className="titleFontSize">活動翦影</Col>
                        </Row>
                        <Row className="contentRow homeAlbumPadding">
                            { this.state.albumTopItem &&
                                this.state.albumTopItem.map((data, index) => {
                                    return (
                                        <>
                                            <Col>
                                                <a href={ `/album/${ data.id }` } key={ index }>
                                                    <img src={ data.cover } alt="" className="contentImg"/>
                                                </a>
                                            </Col>
                                            { this.state.albumTopItem.length < 2 ? <Col/> : "" }

                                        </>
                                    )
                                })
                            }
                        </Row>
                        <Row className="homeAlbumPadding">
                            { this.state.albumTopItem &&
                                this.state.albumTopItem.map(data => {
                                    return (
                                        <>
                                            <Col>
                                                <div className="contentImgFont">{ data.title }</div>
                                            </Col>
                                            { this.state.albumTopItem.length < 2 ? <Col/> : "" }
                                        </>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    <div>
                        <Row noGutters={ true } className="title">
                            <Col xs={ 1.5 } className="titleFontSize">教育訓練</Col>
                            <Col/>
                            <Col xs={ 1.5 } className="contentRight contentFontSize">
                                <a href="/Education-And-Train/Lecture"
                                   className="more">more >
                                </a>
                            </Col>
                        </Row>
                    </div>
                    <div className="contentPadding">
                        { this.state.lectureTopItem.map((item, index) => {
                            let subhead;
                            switch (item.type) {
                                case 0:
                                    subhead = "講習課程"
                                    break;
                                case 1:
                                    subhead = "職安衛課程"
                                    break;
                                case 2:
                                    subhead = "產投課程"
                                    break;
                                case 3:
                                    subhead = "其他課程"
                                    break;
                                default:
                                    break;
                            }
                            return (
                                <div className="contentBorder" key={ index }>
                                    <Row noGutters={ true } className="contentRow">
                                        <Col xs={ 2.5 }>
                                            <div className="contentDiv educationDiv"
                                                 style={ { marginRight: "15px" } }>{ subhead }</div>
                                        </Col>
                                        <Col className="contentFontSize ellipsis homeTrain" onClick={ () => {
                                            this.showDetail(item.id, item.type)
                                        } }>{ item.name }</Col>
                                        <Col xs={ 2.5 } className="contentRight dateFontSize hiddenDate"
                                             style={ { marginLeft: "20px" } }>{ item.startClassTime }</Col>
                                    </Row>
                                </div>
                            )
                        }) }
                    </div>
                </div>
                <ModalCourseInfo lectureItem={ this.state.lectureItem } safetyMemo={ this.state.safetyMemo }
                                 show={ this.state.showlectureInfo } hasApply={ true } type={ this.state.type }
                                 close={ () => {
                                     this.showDetail()
                                 } }
                                 showApply={ () => {
                                     this.showApply(this.state.lectureItem.id, this.state.type)
                                 } }/>
                <ModalLectureApply lectureItem={ this.state.lectureItem } show={ this.state.showApply }
                                   type={ this.state.type } close={ () => {
                    this.showApply()
                } } ok={ () => {
                    this.sureSend()
                } }/>
                <ModalSureSend show={ this.state.sureSend } close={ () => {
                    this.sureSend()
                } } ok={ () => {
                    this.signUp()
                } } message={ "確認送出" }/>
                <ModalBase show={ this.state.showInfo } close={ () => {
                    this.showInfo()
                } } ok={ () => {
                    this.showInfo()
                } } message={ this.state.infoMessage }
                           error={ "addLecture" }/>
                <ModalHasContent show={ this.state.modalShow } close={ this.handleModalClose }
                                 content={ this.state.announcementTopItem[this.state.targetIndex] && this.state.announcementTopItem[this.state.targetIndex].title }
                                 innerHTML={ { __html: this.state.announcementTopItem[this.state.targetIndex] && this.state.announcementTopItem[this.state.targetIndex].content } }/>
            </>
        );
    }
}

export default {
    routeProps: {
        path: "/",
        component: Home
    },
    name: "首頁",
}
