import React from "react";

import BreadcrumbObject from "../../../../js/breadcrumb"
import { trackPromise } from "react-promise-tracker";
import { CommitteeRequest } from "../../../../js/request/committeeRequest";
import { Button } from "react-bootstrap";
import { ModalBase, ModalSureSend } from "../../../../js/modal";


class Detail extends React.Component {
    groupId = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            isAdmin: localStorage.getItem("identity") === "admin",
            isLogin: !!localStorage.getItem("token"),
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        }
    }

    componentDidMount() {
        trackPromise(
            CommitteeRequest.getDetail(this.groupId)
                .then(response => {
                    this.setState(response.data)
                })
        )
    }

    redirectTo = () => {
        window.location.href = `/committee/edit/${ this.groupId }`;
    }

    sureSend = (toSend) => {
        if (toSend && this.state.sureSend) {
            trackPromise(
                CommitteeRequest.delete(this.groupId)
                    .then(response => {
                        this.showInfo("刪除成功")
                        window.location.href = `/committee`;
                    })
                    .catch(response => {
                        this.showInfo("刪除失敗")
                    })
            )
        } else {
            this.setState({ "sureSend": !this.state.sureSend })
        }
    }

    showInfo = (message) => {
        this.setState({ "showInfo": !this.state.showInfo })
        this.setState({ "infoMessage": message });
    }

    render() {
        return (
            <>
                <BreadcrumbObject secondTitle={ "專業委員會" } thirdTitle={ this.state.title }/>
                { this.state.isLogin && this.state.isAdmin ?
                    <div>
                        <Button className="edit kanEdit" variant="success"
                                onClick={ this.redirectTo }>修改</Button>
                        <Button className="edit kanEdit" variant="success"
                                onClick={ () => this.sureSend() }>刪除</Button>
                    </div> :
                    <></>
                }
                <div className="editContent">
                    <div dangerouslySetInnerHTML={
                        { __html: this.state.content && this.state.content }
                    }/>
                </div>
                <ModalSureSend show={ this.state.sureSend } close={ () => this.sureSend(false) }
                               ok={ () => this.sureSend(true) } message={ "確認送出" }/>
                <ModalBase show={ this.state.showInfo } ok={ () => this.showInfo() } close={ () => this.showInfo() }
                           message={ this.state.infoMessage } error={ this.errorPage }/>
            </>
        );
    }
}

export default {
    routeProps: {
        path: '/committee/detail/:id',
        component: Detail
    },
    name: '專業委員會介紹',
}
