import React from "react";
import aboutType from "../../js/about.js"
import { Editors } from "../../js/editorTool";
import BreadcrumbObject from "../../js/breadcrumb"


class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.links.id
        };
    }

    render() {
        return (
            <div id="links">
                <BreadcrumbObject secondTitle={ "相關連結" }/>
                <Editors editorTypeId={ this.state.editorTypeId } isLinkPage={true}/>
            </div>
        );
    }
}

export default {
    routeProps: {
        path: "/links",
        component: Links
    },
    name: "相關連結",
}
