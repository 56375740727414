import React from "react";
import {Col} from "react-bootstrap"
import {FormDownloadRequest} from "../../../js/request/formDownloadRequest"
import {DisplayList} from "../../../js/displayList";

class Form extends React.Component {
    render() {
        return (
            <>
                <DisplayList secondTitle={"表單下載"} thirdTitle={""} createButtonTitle={"新增檔案"} maintainButtonTitle={"表單維護"}
                             subLabel={
                                 <>
                                     <Col style={{textAlign: "left"}}>檔名</Col>
                                     <Col xs={2}>下載</Col>
                                 </>
                             }
                             isFormDownload={true}
                             getSize={FormDownloadRequest.getSize} searchList={FormDownloadRequest.searchList}/>
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/form",
        component: Form
    },
    name: "表單下載",
}
