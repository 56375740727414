import React from "react";

import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";
import logo from "../../img/icon/icon.png";

import {KanbanRequest} from "../../js/request/kanbanRequest"
import {ModalBase, ModalEditPhoto, ModalPasswordChange, ModalSureSend} from "../../js/modal"
import Hamburger from "./hamburger"

export default class Kanban extends React.Component {
    errorPage = "kanban"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            "searchOn": false,
            "identity": "",
            "isLogin": false,
            "showPicture": false,
            "showLogout": false,
            "showInfo": false,
            "showPwdChange": false,
            "message": "",
            "sureSend": false
        }
    }

    componentDidMount() {
        this.showPicture();
        this.checkIdentity();
    }

    showPicture = () => {
        trackPromise(
            KanbanRequest.getKanbanPhoto()
                .then(response => {
                    var styleElem = document.head.appendChild(document.createElement("style"));
                    styleElem.innerHTML = `.back:before {background: url(${response.data.url}) no-repeat;}`;
                })
        )
    }

    searchChange() {
        this.setState({"searchOn": !this.state.searchOn});
    }


    showPictureModal = () => {
        this.setState({"showPicture": !this.state.showPicture});
    }

    showLogout = () => {
        this.setState({"showLogout": !this.state.showLogout});
    }

    showPwdChange = () => {
        this.setState({"showPwdChange": !this.state.showPwdChange});
    }

    logout = () => {
        trackPromise(
            fetch(`/logout`, {
                method: "GET"
            }).then(res => {
                localStorage.setItem("identity", "")
                localStorage.removeItem("token")
                localStorage.removeItem("account")
                this.setState({"identity": ""});
                this.setState({"isLogin": false});
                this.showLogout(false);
                let homePath = window.location.origin
                let pageArrayPath = window.location.pathname.split("/")
                let pagePath = "";
                let change = false;
                let activity = false;
                for (var i = 0; i < pageArrayPath.length; i++) {
                    if (pageArrayPath[i] === "create" || pageArrayPath[i] === "maintain" || pageArrayPath[i] === "edit" || pageArrayPath[i] === "SignUpList") {
                        change = true;
                    }
                    if (pageArrayPath[i] === "activity") {
                        activity = true;
                    }
                }
                if (change) {
                    if (activity) {
                        pagePath = "activity/-1/1"
                    } else {
                        for (let i = 0; i < pageArrayPath.length - 1; i++) {
                            if (i === pageArrayPath.length - 2) {
                                pagePath += pageArrayPath[i]
                            } else {
                                pagePath += pageArrayPath[i] + "/"
                            }
                        }
                    }
                    window.location.href = homePath + pagePath
                } else {
                    window.location.reload()
                }
            })
        )
    }

    showInfo(msg) {
        this.setState({"showInfo": !this.state.showInfo, "message": msg});
    }

    checkIdentity() {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity && checkIdentity !== "") {
            if (this.state.identity === "") {
                this.setState({"identity": checkIdentity})
                this.setState({"isLogin": true});
            }
        } else {
            this.setState({"isLogin": false});
        }
    }

    editPicture() {
        let formData = new FormData();
        formData.append("photoFile", document.getElementById("changePicture").files[0]);
        if (!formData.get("photoFile")) {
            this.showInfo("請點選圖片")
        } else {
            trackPromise(
                KanbanRequest.updatePhoto(formData)
                    .then(response => {
                        this.showPictureModal(false)
                        this.showInfo(response.message)
                        var styleElem = document.head.appendChild(document.createElement("style"));
                        styleElem.innerHTML = `.back:before {background: url(${response.data.url}) no-repeat;}`;
                    })
                    .catch(error => {
                        this.showInfo("更換背景圖失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )
        }

    }

    editPassword = () => {
        let userData = localStorage.getItem("account")
        trackPromise(
            KanbanRequest.updatePassword(
                userData,
                {
                    "password": document.getElementById("newPwd").value
                }
            ).then(response => {
                this.setState({"sureSend": !this.state.sureSend})
                this.showInfo(response.message)
            }).catch(error => {
                this.showInfo("修改密碼失敗")
                document.getElementById(this.errorId).innerHTML = error.message
            })
        )
    }

    handleValueChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    sureSend = () => {
        this.setState({"sureSend": !this.state.sureSend})
    }


    render() {
        let iconInfo = [{"title": "首頁", "className": "kanHome", "href": "/", "tarrget": ""},
            {
                "title": "本會地點",
                "className": "kanLogin",
                "href": "https://www.google.com/maps?ll=25.006444,121.461789&z=15&t=m&hl=zh-TW&gl=TW&mapclient=embed&cid=4469597076909945018",
                "tarrget": "_blank"
            }]
        return (
            <>
                <div className="back">
                    <div className="kanButtons">
                        {this.state.isLogin && this.state.identity === "admin" ? (
                            <Button className="edit kanEdit" variant="success" onClick={() => {
                                this.showPictureModal(true)
                            }}>編輯</Button>) : ""}
                        {[0, 1].map((placement, index) => {
                            let item;
                            if (Number(placement) !== 3) {
                                item = (<a href={`${iconInfo[placement].href}`}
                                           className={`kanIcon ${iconInfo[placement].className}`}
                                           target={`${iconInfo[placement].tarrget}`}> </a>)
                            } else {
                                item = (<div className="kanIcon kanSearch " onClick={() => {
                                    this.searchChange()
                                }}/>)
                            }
                            return (
                                <OverlayTrigger key={index} placement={"bottom"}
                                                overlay={
                                                    <Tooltip id={`${placement}`}>
                                                        {iconInfo[placement].title}
                                                    </Tooltip>
                                                }>
                                    {item}
                                </OverlayTrigger>
                            )
                        })}
                        {this.state.isLogin && this.state.identity === "admin" ? (
                            <OverlayTrigger placement={"bottom"}
                                            overlay={
                                                <Tooltip>
                                                    修改密碼
                                                </Tooltip>
                                            }>
                                <div className="kanIcon kanPwdChange" onClick={this.showPwdChange}/>
                            </OverlayTrigger>) : ""
                        }
                        {this.state.isLogin && this.state.identity === "admin" ? (
                            <OverlayTrigger placement={"bottom"}
                                            overlay={
                                                <Tooltip>
                                                    登出
                                                </Tooltip>
                                            }>
                                <div className="kanIcon kanUser" onClick={() => {
                                    this.showLogout()
                                }}/>
                            </OverlayTrigger>) : ""
                        }
                    </div>
                    <div className="outLogo">
                        <img className="logo" alt="" src={logo}/>
                    </div>
                </div>
                <Hamburger/>
                <ModalBase show={this.state.showInfo} ok={() => {
                    this.showInfo()
                    window.location.reload();
                }} close={() => {
                    this.showInfo()
                }} message={this.state.message} error={this.errorPage}/>
                <ModalBase message={"會員登出"} show={this.state.showLogout} ok={this.logout}
                           close={this.showLogout}/>
                <ModalEditPhoto show={this.state.showPicture} ok={() => {
                    this.editPicture()
                }} close={this.showPictureModal}/>
                <ModalPasswordChange show={this.state.showPwdChange} close={this.showPwdChange}
                                     ok={() => {
                                         this.sureSend()
                                     }}
                                     password={this.state.password}
                                     handleValueChange={(event) => this.handleValueChange(event)}
                />
                <ModalSureSend show={this.state.sureSend}
                               close={() => {
                                   this.sureSend()
                               }}
                               ok={() => {
                                   this.editPassword()
                               }}
                               message={"確認送出"}/>

            </>
        );
    }

}

