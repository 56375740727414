import React from "react";
import { Row, Col } from "react-bootstrap"

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Supervisor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.supervisorChairman.id
        };
    }


    categoryChange = () => {
        let value = document.getElementById("category")
        this.setState({ editorTypeId: value.options[value.selectedIndex].value })
    }

    render() {
        const supervisor = [
            { "id": aboutType.supervisorChairman.id, "name": aboutType.supervisorChairman.name },
            { "id": aboutType.supervisorDirector.id, "name": aboutType.supervisorDirector.name },
            { "id": aboutType.supervisorStanding.id, "name": aboutType.supervisorStanding.name },
            { "id": aboutType.supervisorAdviser.id, "name": aboutType.supervisorAdviser.name }
        ]
        return (
            <div>
                <Row className="align-items-center controlButtons">
                    <Col sm={"auto"} className="label">理監事</Col>
                    <Col sm={"auto"}>
                        <select onChange={this.categoryChange} id="category">
                            {supervisor.map((item, index) => {
                                return (<option value={item.id} key={index}>{item.name}</option>)
                            })}
                        </select>
                    </Col>
                </Row>
                <BreadcrumbObject secondTitle={"關於本會"} thirdTitle={"理監事簡介"} />
                <Editors editorTypeId={this.state.editorTypeId} key={this.state.editorTypeId}/>
            </div>
        );
    }
}


export default {
    routeProps: {
        path: "/supervisor",
        component: Supervisor
    },
    name: "理監事",
}
