import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Hall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.hall.id,
            editorTypeName: aboutType.hall.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "關於本會" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );
    }
}

export default {
    routeProps: {
        path: "/hall",
        component: Hall
    },
    name: "會館介紹",
}
