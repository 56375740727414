import React from "react";
import { Col, Row } from "react-bootstrap"
import BreadcrumbObject from "./breadcrumb"
import { trackPromise } from "react-promise-tracker";
import edit from "../img/edit.png"
import deleteIcon from "../img/icon/delete-24px.svg"
import { Paging } from "./pagination";
import { ModalBase, ModalHasContent, ModalSureSend } from "./modal";
import { AnnouncementRequest } from "./request/announcementRequest";
import { DecreeRequest } from "./request/decreeRequest";
import { FormDownloadRequest } from "./request/formDownloadRequest";

export class AnnouncementMaintain extends React.Component {
    pageTotal = 0;
    size = 11;

    constructor(props) {
        super(props);
        this.state = {
            "nowPage": 1,
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token"),
            "responseData": [],
            "targetIndex": 0,
            "modalShow": false,
            "showDel": false,
            "deleteListId": null,
            "showInfo": false,
            "infoMessage": "",
        };
    }

    componentDidMount = () => {
        this.getSize();
    }

    getSize = () => {
        trackPromise(
            this.props.getSize({
                "size": this.size
            })
                .then(response => {
                    this.setState({ "pageTotal": response.data.totalPage })
                    this.getList(1);
                })
        )
    }

    getList = (now) => {
        trackPromise(
            this.props.searchList({
                "count": this.size,
                "page": now
            }).then(response => {
                this.setState({ "responseData": response.data })
            })
        )
    }

    pageChange = (now) => {
        this.setState({ "nowPage": now })
        this.getList(now);
    }

    redirectTo = (aim) => {
        const path = window.location.href.replace("maintain", aim)
        window.location.href = path;
    }

    showInfo = (message) => {
        this.setState({ "showInfo": !this.state.showInfo })
        this.setState({ "infoMessage": message });
    }

    showDel = (id) => {
        if (!this.state.showDel) {
            this.setState({ "deleteListId": id })
        }
        this.setState({ "showDel": !this.state.showDel })
    }

    deleteList = () => {
        let request = window.location.pathname.split("/")[1]
        switch (request) {
            case "announcement":
                trackPromise(
                    AnnouncementRequest.deleteList(this.state.deleteListId)
                        .then(response => {
                            this.showInfo(response.message)
                            this.showDel()
                            window.location.reload();
                        }).catch()
                )
                break;
            case "decree":
                trackPromise(
                    DecreeRequest.deleteList(this.state.deleteListId)
                        .then(response => {
                            this.showInfo(response.message)
                            this.showDel()
                            window.location.reload();
                        }).catch()
                )
                break;
            case "form":
                trackPromise(
                    FormDownloadRequest.deleteList(this.state.deleteListId)
                        .then(response => {
                            this.showInfo(response.message)
                            this.showDel()
                            window.location.reload();
                        }).catch()
                )
                break;
            default:
                break;
        }
    }

    handleModalShow = (index) => {
        this.setState({ targetIndex: index });
        this.setState({ modalShow: true })
    }

    handleModalClose = () => {
        this.setState({ modalShow: false })
    }

    render() {
        return (
            <>
                <BreadcrumbObject secondTitle={ this.props.secondTitle } thirdTitle={ this.props.thirdTitle }/>
                <div className="subLabel">
                    <Row noGutters={ true }>
                        { this.props.subLabel }
                    </Row>
                </div>
                <hr/>
                <div className="announceContent content">
                    { this.state.responseData &&
                        this.state.responseData.map((data, index) => {
                            return (
                                <div className="contentBorder announceContentItem" key={ index }>
                                    <Row noGutters={ true }>
                                        <Col className="ellipsis verticalCenter" style={ { textAlign: "left" } }
                                             onClick={ () => this.props.isFormDownload ? "" : this.handleModalShow(index) }>{ data.title }</Col>
                                        <Col xs={ 3 } sm={ 2 }>{ data.createDate }</Col>
                                        <Col xs={ 2 } sm={ 1 }><img src={ edit } alt="" className="imgIcon"
                                                                    onClick={ () => this.redirectTo(`edit/${ data.id }`) }/></Col>
                                        <Col xs={ 2 } sm={ 1 }><img src={ deleteIcon } alt="" className="imgIcon"
                                                                    onClick={ () => this.showDel(data.id) }/></Col>
                                    </Row>
                                </div>
                            )
                        }) }
                </div>
                <Paging pageTotal={ this.state.pageTotal } pageChange={ this.pageChange }
                        nowPage={ this.state.nowPage }/>
                <ModalHasContent show={ this.state.modalShow } close={ this.handleModalClose }
                                 content={ this.state.responseData[this.state.targetIndex] && this.state.responseData[this.state.targetIndex].title }
                                 innerHTML={ { __html: this.state.responseData[this.state.targetIndex] && this.state.responseData[this.state.targetIndex].content } }/>
                <ModalBase show={ this.state.showInfo } close={ () => {
                    this.showInfo()
                } } ok={ () => {
                    this.showInfo()
                } } message={ this.state.infoMessage }/>
                <ModalSureSend show={ this.state.showDel } message={ "確認刪除" }
                               close={ () =>
                                   this.showDel() }
                               ok={ () => {
                                   this.deleteList()
                               } }
                />
            </>
        );
    }
}






