import React from "react";

import BreadcrumbObject from "../../js/breadcrumb"
import {Editors} from "../../js/editorTool"
import aboutType from "../../js/about"


class Traffic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.traffic.id,
            editorTypeName: aboutType.traffic.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={this.state.editorTypeName} thirdTitle={""}/>
                <iframe className="trafficMap"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.8137482986267!2d121.45960021500575!3d25.006443983985307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346802a73eadda4d%3A0x3e0732dc4a5770ba!2z5paw5YyX5biC5bel5qWt5pyD!5e0!3m2!1szh-TW!2stw!4v1590667481085!5m2!1szh-TW!2stw"
                        title="map"/>
                <Editors editorTypeId={this.state.editorTypeId}/>
            </div>
        );

    }

}

export default {
    routeProps: {
        path: "/traffic",
        component: Traffic
    },
    name: "交通方式",
}
