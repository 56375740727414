import { FetchUtil } from "../util/fetchUtil"

const BASE_URL = "/decree"

export class DecreeRequest {

    static create(data) {
        return FetchUtil.postHasTokenAPI(BASE_URL, data);
    }

    static getSize(data) {
        return FetchUtil.getAPI(`${ BASE_URL }/size`, data);
    }

    static searchList(data) {
        return FetchUtil.getAPI(`${ BASE_URL }/search`, data);
    }

    static getEdit(id) {
        return FetchUtil.getHasTokenAPI(`${ BASE_URL }/detail/${ id }`)
    }

    static patchEdit(id, data) {
        return FetchUtil.patchAPI(`${ BASE_URL }/detail/${ id }`, data)
    }

    static deleteList(id) {
        return FetchUtil.deleteHasTokenAPI(`${ BASE_URL }/detail/${ id }`)
    }
}