import React from "react";
import {Col} from "react-bootstrap"
import {DecreeRequest} from "../../../js/request/decreeRequest"
import {DisplayList} from "../../../js/displayList";

class Decree extends React.Component {
    render() {
        return (
            <>
                <DisplayList secondTitle={"法令公告"} thirdTitle={""} createButtonTitle={"新增公告"}
                             maintainButtonTitle={"公告維護"}
                             subLabel={
                                 <>
                                     <Col style={{textAlign: "left"}}>主旨</Col>
                                     <Col xs={3} sm={2} style={{textAlign: "right"}}>公告日期</Col>
                                 </>
                             }
                             getSize={DecreeRequest.getSize} searchList={DecreeRequest.searchList}/>
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/decree",
        component: Decree
    },
    name: "法令公告",
}
