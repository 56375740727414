import React from "react";
import {Button, Col, Row} from "react-bootstrap"

import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {ActivityRequest} from "../../../js/request/activityRequest"
import {ModalBase, ModalSureSend, ModalTopSelect} from "../../../js/modal.js"
import {DynamicFormInput} from "../../../js/formInput"

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";

class AddActivity extends React.Component {
    errorPage = "addActivity"
    errorId = `${this.errorPage}ErrorInfo`
    type = 0;
    pageName = ""

    constructor(props) {
        super(props)
        this.state = {
            "pageName": this.pageName,
            "sureSend": false,
            "showInfo": false,
            "infoMessage": "",
            "category": [],
            "showTopSelect": false,
            "photo": [],
            "photoSelect": [],
            "photoChecked": 0,
            "forPreview": ""
        }
    }

    componentDidMount = () => {
        trackPromise(ActivityRequest.getCategory().then(response => {
            this.setState({"category": response.data})
        }).catch(error => {
            console.log(error)
        }))
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showTopSelect = () => {
        if (!this.state.showTopSelect) {
            let inputs = document.getElementById("photoFileArray")
            let photos = []
            if (FileReader && inputs.files && inputs.files.length) {
                for (let i = 0; i < inputs.files.length; i++) {
                    let fr = new FileReader()
                    fr.onload = () => {
                        photos.push(fr.result)
                        this.setState({"photo": photos})
                    }
                    fr.readAsDataURL(inputs.files[i])
                }
            }
        }
        this.setState({"showTopSelect": !this.state.showTopSelect})
    }

    topSelect = () => {
        let topSelect = document.getElementsByName("topSelect")
        let checkSelect = false
        let photoSelect = []
        let photoCheck = 0;
        let forPreview = ""
        for (let i = 0; i < topSelect.length; i++) {
            photoSelect.push(topSelect[i].checked)
            if (topSelect[i].checked) {
                photoCheck = i
                checkSelect = true
                forPreview = this.state.photo[i]
            }
        }
        if (checkSelect) {
            this.setState({
                "showTopSelect": !this.state.showTopSelect,
                "photoSelect": photoSelect,
                "photoChecked": photoCheck,
                "forPreview": forPreview
            })
        } else {
            this.showInfo("請選擇封面圖片")
        }
    }

    sureSend = () => {
        this.setState({"sureSend": !this.state.sureSend})
    }

    send = () => {
        let formData = new FormData();
        formData.append("activityTime", document.getElementById("activityTime").value);
        formData.append("place", document.getElementById("place").value);
        formData.append("content", document.getElementById("content").value);
        formData.append("categoryId", document.getElementById("categoryId").value);
        formData.append("top", document.getElementById("top").checked);
        formData.append("title", document.getElementById("photoTitle").value)
        let inputs = document.getElementById("photoFileArray")
        let topPhoto = this.state.photoSelect
        for (let i = 0; i < inputs.files.length; i++) {
            formData.append("photoFileArray", inputs.files[i])
            formData.append("typeArray", topPhoto[i])
        }
        let info = "";
        if (!formData.get("title")) {
            info = "請輸入活動標題";
        } else if (!formData.get("activityTime")) {
            info = "請輸入活動時間";
        } else if (!formData.get("place")) {
            info = "請輸入活動地點";
        } else if (!formData.get("content")) {
            info = "請輸入活動內容";
        } else if (!formData.get("categoryId")) {
            info = "請選擇相簿類別";
        } else if (!formData.get("photoFileArray")) {
            info = "請選擇照片";
        } else if (!formData.get("typeArray")) {
            info = "請選擇封面照片";
        } else {
            trackPromise(
                ActivityRequest.addActivity(formData).then(response => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("新增成功")
                    window.location.reload();
                }).catch(error => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("新增失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                    console.log(error)
                })
            )
        }
        if (info) {
            this.showInfo(info);
        }
    }

    render() {
        let item = [
            {"title": "活動標題", "require": true, "input": (<><input type="text" id="photoTitle"/></>)},
            {"title": "活動時間", "require": true, "input": (<><input type="date" id="activityTime"/></>)},
            {"title": "活動地點", "require": true, "input": (<><input type="text" id="place"/></>)},
            {"title": "活動內容", "require": true, "input": (<><textarea id="content"/></>)},
            {
                "title": "相簿類別", "require": true, "input": (<><select type="number" id="categoryId">
                    {this.state.category.map((placement, index) => {
                        return <option value={placement.id} key={index}>{placement.category}</option>
                    })}</select>(若沒有類別，請至活動花絮新增相簿類別)
                </>)
            },
            {
                "title": "選擇相片",
                "require": true,
                "input": (<><input type="file" id="photoFileArray" multiple="multiple"/></>)
            },
            {
                "title": "選擇封面相片", "require": true,
                "input": (<>
                    <button onClick={() => {
                        this.showTopSelect(false)
                    }}>選擇(請先選擇相片)
                    </button>
                    <p className="forPreview">
                        <img src={this.state.forPreview} className="previewImg" alt=""/>
                    </p></>)
            },
            {"title": "是否置頂", "require": false, "input": (<><input type="checkbox" id="top"/></>)},

        ]
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage addActivity">
                        <BreadcrumbObject secondTitle={"活動花絮"} thirdTitle={"新增相簿"}/>
                        <DynamicFormInput data={item}/>
                        <Row className="justify-content-end">
                            <Col sm={"auto"}><Button variant="success" onClick={() => {
                                this.sureSend(false)
                            }}>送出資料</Button></Col>
                        </Row>

                        <ModalSureSend show={this.state.sureSend} close={() => {
                            this.sureSend()
                        }} ok={() => {
                            this.send()
                        }} error={this.forError} message={"確認送出"}/>
                        <ModalTopSelect show={this.state.showTopSelect} close={() => {
                            this.showTopSelect()
                        }} ok={() => {
                            this.topSelect()
                        }} photo={this.state.photo} isNeedUrl={false}/>
                        <ModalBase message={this.state.infoMessage} show={this.state.showInfo} ok={() => {
                            this.showInfo()
                        }} close={() => {
                            this.showInfo()
                        }} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}

export const addActivity = {
    routeProps: {
        path: "/activity/create",
        component: AddActivity
    },
    name: "新增相簿"
}


