import React from "react";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap"

import BreadcrumbObject from "../../../js/breadcrumb"

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import download from "../../../img/download.png";
import edit from "../../../img/edit.png"
import search from "../../../img/banner/search.png"
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "../../../js/request/trainRequest"
import {ModalBase, ModalCourseInfo, ModalLectureApply, ModalSureSend} from "../../../js/modal"
import {Paging} from "../../../js/pagination"


class Lecture extends React.Component {
    errorPage = "lecture"
    errorId = "lectureErrorInfo"
    type = 0;
    pageTotal = 0

    constructor(props) {
        super(props);
        this.state = {
            "isLogin": !!localStorage.getItem("token"),
            "isAdmin": localStorage.getItem("identity") === "admin",
            "contentNo": [],
            "pageName": "",
            "pageTotal": this.pageTotal,
            "nowPage": 1,
            "showlectureInfo": false,
            "showApply": false,
            "showInfo": false,
            "sureSend": false,
            "infoMessage": "",
            "lectureItem": {},
        }
    }

    componentDidMount = () => {
        switch (window.location.href.split("Education-And-Train")[1]) {
            case "/Lecture":
                this.setState({"pageName": "講習課程"});
                this.type = 0;
                break;
            case "/other":
                this.setState({"pageName": "其他課程"});
                this.type = 3;
                break;
            default:
                break;
        }
        trackPromise(
            TrainRequest.getSize({"type": this.type})
                .then(response => {
                    this.pageTotal = Math.ceil(response.data.size / 12)
                    this.setState({"pageTotal": this.pageTotal})
                    this.getCourse(1);
                })
        )
    }

    getCourse = (now) => {
        trackPromise(
            TrainRequest.searchList({
                "type": this.type,
                "size": 12,
                "page": now
            }).then(response => {
                this.setState({"contentNo": response.data})
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        this.getCourse(now)
    }

    getDetail = (no) => {
        trackPromise(
            TrainRequest.getDetail(no)
                .then(response => {
                    this.setState({"lectureItem": response.data})
                    document.getElementById("lectureInfo").innerHTML = response.data.content.replace(/\n/g, "<br />");
                })
        )
    }

    showDetail = (show, no) => {
        if (!this.state.showlectureInfo) {
            this.getDetail(no)
        }
        this.setState({"showlectureInfo": show})
    }

    showApply = (show, no) => {
        if (this.state.showlectureInfo) {
            this.setState({"showlectureInfo": false})
        }
        if (!this.state.showApply) {
            this.getDetail(no)
        }
        this.setState({"showApply": show})
    }


    sureSend = () => {
        this.setState({"sureSend": !this.state.sureSend})
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    redirectTo = (aim) => {
        const path = `${window.location.href}/${aim}`
        window.location.href = path;
    }

    signUp = () => {
        const educationElement = document.getElementById("education");
        const requestData = {
            "courseId": this.state.lectureItem.id,
            "memberId": document.getElementById("memberId").value,
            "companyName": document.getElementById("companyName").value ? document.getElementById("companyName").value : null,
            "name": document.getElementById("name").value,
            "email": document.getElementById("email").value,
            "cellPhone": document.getElementById("cellPhone").value,
            "phone": document.getElementById("phone").value,
            "faxNumber": document.getElementById("faxNumber").value,
            "address": document.getElementById("address").value,
            "mealRequirement": document.querySelector("input[name=\"mealRequirement\"]:checked") ? document.querySelector("input[name=\"mealRequirement\"]:checked").value : null,
            "memo": document.getElementById("memo").value,
            "birthday": document.getElementById("birthday") ? document.getElementById("birthday").value : null,
            "identity": document.getElementById("identity") ? document.getElementById("identity").value : null,
            "education": educationElement ? educationElement.options[educationElement.selectedIndex].value : null,
            "graduatedSchool": document.getElementById("graduatedSchool") ? document.getElementById("graduatedSchool").value : null,
            "certificateOfCompletionNumber": document.getElementById("certificateOfCompletionNumber") ? document.getElementById("certificateOfCompletionNumber").value : null,
            "serviceUnit": document.getElementById("serviceUnit") ? document.getElementById("serviceUnit").value : null,

        }

        let info = "";
        if (!requestData.name) {
            info = "請輸入姓名";
        } else if (!requestData.email) {
            info = "請輸入電子信箱";
        } else if (!requestData.cellPhone) {
            info = "請輸入手機號碼";
        } else if (!requestData.phone) {
            info = "請輸入電話號碼";
        } else if (!requestData.address) {
            info = "請輸入聯絡地址";
        } else if (!requestData.mealRequirement) {
            info = "請輸入用餐需求";
        } else {
            if (this.type === 0) {
                trackPromise(
                    TrainRequest.createSignUpLecture(requestData)
                        .then(response => {
                            this.setState({"sureSend": !this.state.sureSend})
                            this.showInfo("新增成功")
                            window.location.reload();
                        })
                        .catch(error => {
                            this.setState({"sureSend": !this.state.sureSend})
                            this.showInfo("新增失敗")
                            document.getElementById(this.errorId).innerHTML = error.message
                        })
                )
            } else if (this.type === 3) {
                trackPromise(
                    TrainRequest.createSignUpOther(requestData)
                        .then(response => {
                            this.setState({"sureSend": !this.state.sureSend})
                            this.showInfo("新增成功")
                            window.location.reload();
                        })
                        .catch(error => {
                            this.setState({"sureSend": !this.state.sureSend})
                            this.showInfo("新增失敗")
                            document.getElementById(this.errorId).innerHTML = error.message
                        })
                )
            }

        }

        if (info) {
            this.showInfo(info);
            this.setState({"sureSend": !this.state.sureSend})
        }
    }


    render() {
        return (
            <div className="lecture">
                {this.state.isLogin && this.state.isAdmin ?
                    <Row className="controlButtons">
                        <Col xs={"auto"}>
                            <Button className="edit kanEdit editBlue" onClick={() => {
                                this.redirectTo("maintain")
                            }}>課程維護</Button>
                        </Col>
                        <Col xs={"auto"}>
                            <Button className="edit kanEdit editBlue" onClick={() => {
                                this.redirectTo("SignUpList")
                            }}>報名列表</Button>
                        </Col>
                        <Col xs={"auto"}>
                            <Button className="edit kanEdit" variant="success" onClick={() => {
                                this.redirectTo("create")
                            }}>新增課程</Button>
                        </Col>
                    </Row> : <></>}
                <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                <div className="subLabel">
                    <Row noGutters={true}>
                        <Col xs={1}/>
                        <Col className="lectureName">課程名稱</Col>
                        <Col xs={3} sm={2}>上課日期</Col>
                        <Col xs={3} sm={2}>簡章下載</Col>
                        <Col xs={2}/>
                    </Row>
                </div>
                <hr/>
                <div className="content">
                    {this.state.contentNo.map((item, index) => {
                        return (
                            <div className="announceContentItem contentBorder lectureContentItem" key={index}>
                                <Row noGutters={true} className="align-items-center">
                                    <Col className='lectureNo' xs={1}>
                                        <div>{index + 1 + 12 * (this.state.nowPage - 1)}</div>
                                    </Col>
                                    <Col className="lectureName">
                                        <div onClick={() => {
                                            this.showDetail(true, item.id)
                                        }} className="ellipsis">{item.name}</div>
                                    </Col>
                                    <Col xs={3} sm={2}>{item.startClassTime}</Col>
                                    <Col xs={3} sm={2}>
                                        <center>
                                            <OverlayTrigger key={index} placement={'left'}
                                                            overlay={<Tooltip>下載</Tooltip>}>
                                                <a href={item.attachment} download={item.attachment}><img src={download}
                                                                                                          alt=""
                                                                                                          className="imgIcon"/></a>
                                            </OverlayTrigger>
                                        </center>
                                    </Col>
                                    {[{
                                        "title": "查看",
                                        "item": (<img src={search} className="imgIconBlue " alt="/" onClick={() => {
                                                this.showDetail(true, item.id)
                                            }}/>
                                        )
                                    }, {
                                        "title": "報名",
                                        "item": (<img src={edit} className="imgIconBlue " alt="/" onClick={() => {
                                            this.showApply(true, item.id)
                                        }}/>)
                                    }].map((placement, index) => {
                                        return (<Col xs={1} key={index}>
                                            <OverlayTrigger placement={'left'}
                                                            overlay={<Tooltip id={index}>{placement.title}</Tooltip>}>
                                                {placement.item}
                                            </OverlayTrigger>
                                        </Col>)
                                    })}
                                </Row>
                            </div>
                        )
                    })}
                </div>
                <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange}
                        nowPage={this.state.nowPage}/>
                <ModalLectureApply lectureItem={this.state.lectureItem}
                                   show={this.state.showApply}
                                   close={() => {
                                       this.showApply()
                                   }}
                                   ok={() => {
                                       this.sureSend()
                                   }}
                                   type={this.type}/>
                <ModalSureSend show={this.state.sureSend}
                               close={() => {
                                   this.sureSend()
                               }}
                               ok={() => {
                                   this.signUp()
                               }}
                               message={"確認送出"}/>
                <ModalCourseInfo show={this.state.showlectureInfo}
                                 close={() => {
                                     this.showDetail()
                                 }}
                                 lectureItem={this.state.lectureItem} type={this.type}
                                 showApply={() => {
                                     this.showApply(this.state.lectureItem.id, this.type)
                                 }}
                                 hasApply="true"/>
                <ModalBase show={this.state.showInfo}
                           close={() => {
                               this.showInfo()
                           }} message={this.state.infoMessage} ok={() => {
                    this.showInfo()
                }} error={this.errorPage}/>
            </div>
        );
    }
}

export const lecture = {
    routeProps: {
        path: "/Education-And-Train/Lecture",
        component: Lecture
    },
    name: "講習課程"
}


export const other = {
    routeProps: {
        path: "/Education-And-Train/other",
        component: Lecture
    },
    name: "其他課程"

}
