import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {DecreeRequest} from "../../../js/request/decreeRequest"
import {Button, Col, Row} from "react-bootstrap";

import {EditorTool} from "../../../js/editorTool"
import {ModalBase, ModalSureSend} from "../../../js/modal";

class CreateDecree extends React.Component {
    errorPage = "decree"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            identity: false,
            isEditorOpen: true,
            info: "",
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        };
    }

    componentDidUpdate = () => {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity === "admin") {
            if (!this.state.identity) {
                this.setState({"identity": "true"})
            }
        }
    }

    handleModalShow = (content) => {
        this.setState({modalTitle: content});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    handleEditorOpen = () => {
        this.setState(prevstate => ({isEditorOpen: !prevstate.isEditorOpen}))
    }

    sureSend = (toSend) => {
        if (toSend && this.state.sureSend) {
            trackPromise(
                DecreeRequest.create({
                    "title": document.getElementById("title").value,
                    "content": this.state.info,
                    "top": document.getElementById("top").checked
                }).then(response => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("新增成功")
                    window.location.href = window.location.href.split("/create")[0];
                }).catch(error => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("新增失敗")
                    document.getElementById("errorInfo").innerHTML = error.message
                    console.log(error.messge)
                })
            )
        } else {
            this.setState({"sureSend": !this.state.sureSend})
        }

    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div>
                        <BreadcrumbObject secondTitle={"法令公告"} thirdTitle={"新增法令公告"}/>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2}>是否置頂</Col>
                            <Col><input id="top" type="checkbox" required/></Col>
                        </Row>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2} className="addRedStar">主旨</Col>
                            <Col><input className="title_input" id="title" type="text" required/></Col>
                        </Row>
                        <div className="editor-content">
                            {this.state.isEditorOpen ?
                                (
                                    <>
                                        <Button className="edit kanEdit ckeditorBtn" variant="success"
                                                onClick={this.handleEditorOpen}>切換
                                        </Button>
                                        <EditorTool accessText={(text) => {
                                            this.setState({
                                                info: text,
                                            })
                                        }}
                                                    outputHTML={this.state.info}/>
                                    </>
                                ) :
                                (
                                    <>
                                        <Button className="edit kanEdit ckeditorBtn" variant="success"
                                                onClick={this.handleEditorOpen}>編輯
                                        </Button>
                                        <div className={this.state.identity ? "" : "ckeditorDiv"}
                                             dangerouslySetInnerHTML={{__html: this.state.info}}/>
                                    </>
                                )
                            }
                        </div>
                        <Row style={{paddingTop: "5%"}}>
                            <Col xs={2} style={{margin: "0 auto"}}>
                                <button className="kanEdit ckeditorBtn btn btn-success"
                                        onClick={() => this.sureSend(false)}>送出
                                </button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.sureSend} close={() => this.sureSend(false)}
                                       ok={() => this.sureSend(true)} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                                   message={this.state.infoMessage} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/decree/create",
        component: CreateDecree
    },
    name: "新增法令公告",
}
