import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {Col, Row} from "react-bootstrap";

import {DynamicFormInput} from "../../../js/formInput"
import {FormDownloadRequest} from "../../../js/request/formDownloadRequest"
import {ModalBase, ModalSureSend} from "../../../js/modal";


class FormEdit extends React.Component {
    errorPage = "formDownload"
    errorId = `${this.errorPage}ErrorInfo`
    size = 11;

    constructor(props) {
        super(props);
        this.state = {
            id: window.location.href.split("edit/")[1],
            identity: false,
            sureSend: false,
            showInfo: false,
            infoMessage: "",
            title: "",
        };
    }

    componentDidMount() {
        this.getEdit()
    }

    componentDidUpdate = () => {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity === "admin") {
            if (!this.state.identity) {
                this.setState({"identity": "true"})
            }
        }
    }

    handleModalShow = (content) => {
        this.setState({modalTitle: content});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    getEdit = () => {
        trackPromise(
            FormDownloadRequest.getEdit(this.state.id).then(response => {
                this.setState({
                    title: response.data.title,
                })
            }).catch(error => {
            })
        )
    }

    patchEdit = (toSend) => {
        if (toSend && this.state.sureSend) {
            let formData = new FormData();
            formData.append("title", document.getElementById("title").value);
            formData.append("file", document.getElementById("file").files[0]);
            trackPromise(
                FormDownloadRequest.patchEdit(this.state.id, formData)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("修改成功")
                        this.redirectTo()
                    }).catch(error => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("修改失敗")
                    document.getElementById("errorInfo").innerHTML = error.message
                    console.log(error.message)
                })
            )
        } else {
            this.setState({"sureSend": !this.state.sureSend})
        }
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    }

    redirectTo = () => {
        window.location.href = window.location.href.split("/edit")[0] + "/maintain";
    }

    render() {
        let data = [
            {
                "title": "檔名",
                "require": true,
                "input": (<><input type="text" id="title" required value={this.state.title}
                                   onChange={this.handleTitleChange}/></>)
            },
            {
                "title": "檔案上傳", "require": false, "input": (<><input type="file" id="file"/>
                    <p style={{marginTop: "1%"}}>如未上傳新檔案，則將繼續沿用上次上傳的檔案</p></>)
            },
        ]
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage">
                        <BreadcrumbObject secondTitle={"表單下載"} thirdTitle={"編輯表單下載"}/>
                        <DynamicFormInput data={data}/>
                        <Row style={{paddingTop: "5%"}}>
                            <Col xs={2} style={{margin: "0 auto"}}>
                                <button className="kanEdit ckeditorBtn btn btn-success"
                                        onClick={() => this.patchEdit(false)}>送出
                                </button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.sureSend} close={() => this.patchEdit(false)}
                                       ok={() => this.patchEdit(true)} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                                   message={this.state.infoMessage} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/form/edit/:id",
        component: FormEdit
    },
    name: "編輯表單下載",
}
