import React from "react";
import ReactDOM from "react-dom";
import {Helmet} from "react-helmet"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap"
import {usePromiseTracker} from "react-promise-tracker";
import Loader from "react-loader-spinner"

import Kanban from "./modules/pages/kanban"
import Nav from "./modules/pages/nav.js"
import Footer from "./modules/pages/footer.js"
import {main} from "./modules/router.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";

class App extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>新北市工業會</title>
                    <meta property="og:title" content="新北市工業會"/>
                </Helmet>
                <Container fluid="xl">
                    <Row>
                        <Col>
                            <Kanban/>
                        </Col>
                    </Row>
                    <div className="mainContent">
                        <Row noGutters={true}>
                            <Col md={2} lg={2}>
                                <Nav/>
                            </Col>
                            <Col xs={12} md={10} lg={10}>
                                <div className="indexContent">
                                    <Switch>
                                        {main.map((router, index) => (
                                            <Route exact key={index} {...router.routeProps} />
                                        ))}
                                        <Route path="*" render={() => <Redirect to="/"/>}/>
                                    </Switch>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <Footer/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const LoadingIndicator = props => {
    const {promiseInProgress} = usePromiseTracker();
    return (
        promiseInProgress &&
        <div className="show_loading">
            <Loader type="ThreeDots" color="#46A3FF" height="100" width="100"/>
        </div>
    );
}

ReactDOM.render(
    <BrowserRouter>
        <App/>
        <LoadingIndicator/>
    </BrowserRouter>,
    document.getElementById("root")
);