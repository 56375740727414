import React from "react";
import {Button, Col, Modal, Row} from "react-bootstrap"
import download from "../img/download.png";
import "react-awesome-slider/dist/styles.css";
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "./request/trainRequest";
import {ActivityRequest} from "./request/activityRequest";

export class ModalBase extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static" className={`${this.props.modalSize || ""} xsallModal`}>
                <Modal.Body className="show-grid body">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="justify-content-center modalLabel">{this.props.message}</div>
                            <div className="modalContainer">{this.props.content}</div>
                            <div id={`${this.props.error}ErrorInfo`} className="errorInfo"/>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={"auto"}>
                            <Button variant="success" onClick={this.props.ok}>確認</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}

export class ModalSureSend extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static" className={"xsallModal"} scrollable>
                <Modal.Body className="show-grid body">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="justify-content-center modalLabel">{this.props.message}</div>
                            <div className="justify-content-center modalContainer">{this.props.content}</div>
                            <div id={`${this.props.error}ErrorInfo`}/>
                            <Row className="justify-content-center">
                                <Col xs={"auto"}>
                                    <Button variant="secondary" onClick={this.props.close}>取消</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button variant="success" onClick={this.props.ok}>確認</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}

export class ModalTopSelectSureSend extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static" scrollable>
                <Modal.Body className="show-grid body">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="justify-content-center modalLabel">{this.props.message}</div>
                            <div className="modalContainer">{this.props.content}</div>
                            <div id={`${this.props.error}ErrorInfo`}/>
                            <Row className="justify-content-center">
                                <Col xs={"auto"}>
                                    <Button variant="secondary" onClick={this.props.close}>取消</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button variant="success" onClick={this.props.ok}>確認</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}

export class ModalHasContent extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static">
                <Modal.Body className="show-grid pictureModalBody">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="modalLabel lectureModalLabel">{this.props.content}</div>
                            <div className="modalContent">
                                <div
                                    dangerouslySetInnerHTML={this.props.innerHTML}/>
                            </div>
                            <div className="modalContainer">
                                <Button className="btn btn-secondary" onClick={this.props.close}>關閉視窗</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}

export class ModalLogin extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static" className="loginModal">
                <Modal.Body className="show-grid loginModalBody">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="modalContainer">
                        <Col>
                            <p className="modalLabel">會員登入</p>
                            <p><input placeholder="帳號" id="account"/></p>
                            <p><input placeholder="密碼" id="password" type="password"/></p>
                            <p id="loginErrorInfo"/>
                            <p><Button className="kanbanModalButton" onClick={this.props.ok}>登入</Button></p>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}


export class ModalEditPhoto extends React.Component {
    render() {
        return (<>
            <ModalSureSend
                show={this.props.show}
                ok={this.props.ok}
                close={this.props.close}
                content={(<>
                    <input id="changePicture" type="file"/>
                </>)}
                message={"更換背景圖"}
            >
            </ModalSureSend>
        </>)
    }
}

export class ModalCourseInfo extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static">
                <Modal.Body className="show-Fgrid body">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="justify-content-center modalLabel lectureModalLabel">課程資訊</div>
                            <div className="modalContent">
                                <p id="lectureNo">課程編號：{this.props.lectureItem.id}</p>
                                <p id="lectureName">課程名稱：{this.props.lectureItem.name}</p>
                                <p id="lectureLocation">上課地點：{this.props.lectureItem.address}</p>
                                {this.props.lectureItem.startClassTime !== null ?
                                    <p id="expectedStartClassTime">開課日期：{`${this.props.lectureItem.startClassTime || ""} ~ ${this.props.lectureItem.endClassTime || ""}`}</p> :
                                    <></>
                                }
                                {this.props.type === 1 && this.props.lectureItem.startClassTime === null ?
                                    <p id="expectedStartClassTime">預計開課日期：{this.props.lectureItem.expectedStartClassTime || ""}</p> :
                                    <></>
                                }
                                {/*<p id="lecturePeople">課程人數：{this.props.lectureItem.studentCount}</p>*/}
                                <p id="lectureoverPeople">剩餘名額：{this.props.lectureItem.remainingCount}</p>
                                <p id="lectureMemberFee">會員費用：{this.props.lectureItem.memberPrice}</p>
                                <p id="lectureNormalFee">非會員費用：{this.props.lectureItem.notMemberPrice}</p>
                            </div>
                            {this.props.type === 1 ?
                                <></> :
                                <div className="modalContent">
                                    <p id="lecturer">授課講師：{this.props.lectureItem.teacherName}</p>
                                    <p id="lecturerInfo">講師簡歷：{this.props.lectureItem.teacherDescription}</p>
                                </div>
                            }
                            <div className="modalContent">
                                <p>課程內容:</p>
                                {this.props.lectureItem.courseContentArray ? this.props.lectureItem.courseContentArray.map((placement, index) => {
                                    return (
                                        <p key={index}>{placement}</p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="modalContent">
                                {
                                    this.props.type === 1 ?
                                        <></> :
                                        <p id="lectureDownload">簡章下載：
                                            <a href={this.props.lectureItem.attachment}
                                               download={this.props.lectureItem.lectureDownload}>
                                                <img src={download} alt="" className="modalImgIcon"/>
                                            </a>
                                        </p>
                                }
                            </div>
                            <div className="modalContent">
                                <div>注意事項：
                                    <ol id="lectureNotice">
                                        {this.props.type !== 1 ?
                                            (this.props.lectureItem.courseMemoArray) ? this.props.lectureItem.courseMemoArray.map((placement, index) => {
                                                return (<p style={{paddingLeft: "-2%"}} key={index}>{placement}</p>)
                                            }) : <></>
                                            :
                                            <></>}
                                        {this.props.type === 1 && this.props.safetyMemo ?
                                            this.props.safetyMemo.map((placement, index) => {
                                                return (
                                                    <p key={index}>{placement}</p>
                                                )
                                            }) : <></>
                                        }
                                    </ol>
                                </div>
                            </div>
                            <Row className="justify-content-center">
                                <Col xs={"auto"}>
                                    <Button variant="secondary" onClick={this.props.close}>關閉視窗</Button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}


export class ModalLectureApply extends React.Component {

    render() {
        const lApplyContent = [
            {"title": "會籍號碼", "require": false, "input": (<input id="memberId"/>)},
            {"title": "姓名", "require": true, "input": (<input id="name"/>)},
            {"title": "公司名稱", "require": true, "input": (<input id="companyName"/>)},
            {"title": "電子郵件", "require": true, "input": (<input id="email"/>)},
            {"title": "手機號碼", "require": true, "input": (<input placeholder="範例：0912345678" id="cellPhone"/>)},
            {"title": "電話號碼", "require": true, "input": (<input placeholder="範例：0212345678" id="phone"/>)},
            {
                "title": "傳真號碼",
                "require": false,
                "input": (<input placeholder="範例：0212345678" id="faxNumber"/>)
            },
            {"title": "聯絡地址", "require": true, "input": (<input className="large" id="address"/>)},
            {
                "title": "餐點需求", "require": true, "input":
                    (<div>
                        <input type="radio" name="mealRequirement" id="mealRequirement0" value="0"/>
                        <label for="mealRequirement0">葷食</label>
                        <input type="radio" name="mealRequirement" id="mealRequirement1" value="1"/>
                        <label for="mealRequirement1">素食</label>
                        <input type="radio" name="mealRequirement" id="mealRequirement2" value="2"/>
                        <label for="mealRequirement2">不用餐</label>
                    </div>)
            },
            {"title": "備註", "require": false, "input": (<textarea id="memo"/>)},
        ];

        const oSFApplyContent = [
            {"title": "會籍號碼", "require": false, "input": (<input id="memberId"></input>)},
            {"title": "學員姓名", "require": true, "input": (<input id="name"></input>)},
            {
                "title": "出生日期",
                "require": true,
                "input": (<input type="text" id="birthday" placeholder="年份(四碼)/月份(二碼)/日期(二碼):2021/12/13"></input>)
            },
            {"title": "身分證字號", "require": true, "input": (<input id="identity"></input>)},
            {"title": "戶籍地址", "require": true, "input": (<input id="residenceAddress"></input>)},
            {
                "title": "學歷", "require": true, "input": (
                    <select className="xsall" id="education">
                        <option value="" selected disabled hidden>請選擇</option>
                        <option value="1">博士</option>
                        <option value="2">研究所以上</option>
                        <option value="3">大學</option>
                        <option value="4">專科</option>
                        <option value="5">高中(職)</option>
                        <option value="6">國中</option>
                        <option value="7">國小</option>
                        <option value="0">不識字</option>

                    </select>
                ), "inputClass": "large"
            },
            {"title": "畢業學校", "require": true, "input": (<input id="graduatedSchool"/>)},
            {
                "title": "結業證書字號",
                "require": false,
                "input": (<input id="certificateOfCompletionNumber" placeholder="初訓者免填 (新北市工業證字第OOO號)"/>)
            },
            {"title": "服務單位", "require": true, "input": (<input id="serviceUnit"/>)},
            {"title": "電子郵件", "require": true, "input": (<input id="email"/>)},
            {"title": "手機號碼", "require": true, "input": (<input placeholder="範例：0912345678" id="cellPhone"/>)},
            {"title": "電話號碼", "require": true, "input": (<input placeholder="範例：0212345678" id="phone"/>)},
            {"title": "傳真號碼", "require": true, "input": (<input placeholder="範例：0212345678" id="faxNumber"/>)},
            {"title": "聯絡人姓名", "require": true, "input": (<input id="contactName"/>)},
            {"title": "聯絡地址", "require": true, "input": (<input id="address" className="large"/>)},
            {"title": "備註", "require": false, "input": (<textarea id="memo"/>)},

        ];
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static">
                <Modal.Body className="show-grid">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="justify-content-center modalLabel">課程報名</div>
                            <div className="modalContent inputs">
                                {((this.props.type === 1) ? oSFApplyContent : lApplyContent).map((placement, index) => {
                                    return (<Row className="lectureInfoItem" key={index}>
                                        <Col className={(placement.require ? "require" : "")}
                                             xs={"2"}>{placement.title}</Col>
                                        <Col xs={5}>{placement.input}</Col>
                                    </Row>)
                                })}
                            </div>
                            <div className="modalContent">
                                <p>{this.props.type !== 1 ? "注意事項" : ""}
                                    <ol id="lectureNotice">
                                        {(this.props.lectureItem.courseMemoArray) ? this.props.lectureItem.courseMemoArray.map((placement, index) => {
                                            return (<p key={index}>{placement}</p>)
                                        }) : <></>}
                                    </ol>
                                </p>
                            </div>
                            <div className="modalContainer">
                                <Button variant="success" onClick={this.props.ok}>送出資料</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}


export class ModalAddCategory extends React.Component {

    render() {
        return (<>
            <ModalSureSend
                show={this.props.addShow}
                ok={this.props.addOk}
                close={this.props.addClose}
                message={"新增相簿類別"}
                content={(
                    <Row className="activityCategoryModal">
                        <Col xs={5} className="require">請輸入類別</Col>
                        <Col><input id="addCatagory"/></Col>
                    </Row>
                )}
            />
        </>)
    }
}

export class ModalModifyCategory extends React.Component {

    render() {
        return (<>
            <ModalSureSend
                show={this.props.modifyShow}
                ok={this.props.modifyOk}
                close={this.props.modifyClose}
                message={"修改相簿類別"}
                content={(
                    <>
                        <Row className="activityCategoryModal">
                            <Col className="require" style={{textAlign: "left"}}>請選擇要修改的類別</Col>
                            <Col style={{textAlign: "left"}}>
                                <select id="modifyCategory">
                                    {this.props.modifyCategory.slice(1).map((placement, index) => {
                                        return (<option value={placement.value} key={index}>{placement.name}</option>)
                                    })}
                                </select>
                            </Col>
                        </Row>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col className="require">請輸入類別名稱</Col>
                            <Col><input id="afterModifyCategory"/></Col>
                        </Row>
                    </>
                )}
            />
        </>)
    }
}

export class ModalDeleteCategory extends React.Component {

    render() {
        return (<>
            <ModalSureSend
                show={this.props.deleteShow}
                ok={this.props.deleteOk}
                close={this.props.deleteClose}
                message={"刪除相簿類別"}
                content={(
                    <Row className="activityCategoryModal">
                        <Col className="require">請選擇要刪除的類別</Col>
                        <Col>
                            <select id="deleteCategory">
                                {this.props.deleteCategory.slice(1).map((placement, index) => {
                                    return (<option value={placement.value} key={index}>{placement.name}</option>)
                                })}
                            </select>
                        </Col>
                    </Row>
                )}
            />
        </>)
    }
}

export class ModalModifyAlbum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityTime: "",
        }
    }

    componentDidMount = () => {
        this.getAlbum()
    }

    getAlbum = () => {
        trackPromise(
            ActivityRequest.getAlbum(window.location.href.split("album/")[1]).then(response => {
                let replaceDate = response.data.activityTime.replace("/", "-")
                let activityTime = replaceDate.replace("/", "-")
                this.setState({
                    activityTime: activityTime
                })
            }).catch(error => {
                console.log(error)
            })
        )
    }

    render() {
        return (<>
            <ModalSureSend
                show={this.props.modifyShow}
                ok={this.props.modifyOk}
                close={this.props.modifyClose}
                message={"修改相簿"}
                content={(
                    <>
                        <div className="lectureModalLabel"/>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col xs={"auto"}>活動名稱</Col>
                            <Col><input id="photoTitle" value={this.props.modifyAlbumName} style={{width: "84%"}}
                                        onChange={this.props.modifyAlbumNameChange}/></Col>
                        </Row>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col xs={"auto"}>活動時間</Col>
                            <Col><input id="activityTime" type="date" style={{width: "84%"}}
                                        defaultValue={this.state.activityTime}/></Col>
                        </Row>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col xs={"auto"}>活動地點</Col>
                            <Col><input id="place" value={this.props.modifyAlbumPlace} style={{width: "84%"}}
                                        onChange={this.props.modifyAlbumPlaceChange}/></Col>
                        </Row>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col xs={"auto"}>活動內容</Col>
                            <Col><textarea id="content" value={this.props.modifyAlbumContent}
                                           style={{width: "84%", height: "250px"}}
                                           onChange={this.props.modifyAlbumContentChange}/></Col>
                        </Row>
                        <Row className="activityCategoryModal" style={{textAlign: "left"}}>
                            <Col xs={"auto"}>是否置頂</Col>
                            <Col><input id="top" checked={this.props.modifyAlbumTop || ""}
                                        value={this.props.modifyAlbumTop} onChange={this.props.modifyAlbumTopChange}
                                        type="checkbox"/></Col>
                        </Row>
                    </>
                )}
            />
        </>)
    }
}

export class ModalDeleteAlbum extends React.Component {

    render() {
        return (<>
            <ModalSureSend
                show={this.props.deleteShow}
                ok={this.props.deleteOk}
                close={this.props.deleteClose}
                message={"刪除相簿"}
                content={(<div className="topSelect">
                    {this.props.deleteAlbum &&
                        this.props.deleteAlbum.map((placement, index) => {
                            return (
                                <Row className="align-items-center justify-content-center" key={index}>
                                    <Col xs={"auto"}><input type="checkbox" id={`deleteAlbumSelect${index}`}
                                                            name="deleteAlbumSelect"/>
                                    </Col>
                                    <Col xs={"auto"}>
                                        <label htmlFor={`deleteAlbumSelect${index}`}>
                                            <div><img src={placement.cover} alt=""/></div>
                                            <center className="ellipsis">{placement.title}</center>
                                            <hr/>
                                        </label>
                                    </Col>
                                </Row>
                            )
                        })}</div>)}
            />
        </>)
    }
}


export class ModalTopSelect extends React.Component {

    render() {
        return (<>
            <ModalTopSelectSureSend
                show={this.props.show}
                ok={this.props.ok}
                close={this.props.close}
                content={(<div className="topSelect">
                    {this.props.photo &&
                        this.props.photo.map((placement, index) => {
                            return (<Row className="align-items-center justify-content-center" key={index}>
                                <Col xs={"auto"}><input type="radio" id={`topSelect${index}`}
                                                        name="topSelect"/></Col>
                                {this.props.isNeedUrl ?
                                    <Col xs={"auto"}><label for={`topSelect${index}`}><img src={placement.url}
                                                                                           alt=""/></label></Col>
                                    :
                                    <Col xs={"auto"}><label for={`topSelect${index}`}><img src={placement}
                                                                                           alt=""/></label></Col>
                                }

                            </Row>)
                        })}</div>)}
                message={"選擇封面相片"}
            />
        </>)
    }
}

export class ModalAddPhoto extends React.Component {

    render() {
        return (<>
            <ModalSureSend
                show={this.props.addShow}
                ok={this.props.addOk}
                close={this.props.addClose}
                message={"新增圖片"}
                content={
                    [
                        {
                            "title": "選擇相片",
                            "require": true,
                            "input": (<><input type="file" id="photoFileArray" multiple="multiple"/></>)
                        },
                    ].map((placement, index) => {
                        return (
                            <Row className="modalContent" key={index}>
                                <Col xs={4} className={placement.require ? "require" : ""}>{placement.title}</Col>
                                <Col>{placement.input}</Col>
                            </Row>)
                    })
                }
            />

        </>)
    }
}

export class ModalDeletePhoto extends React.Component {

    render() {
        return (<>
            <ModalTopSelectSureSend
                show={this.props.deleteShow}
                ok={this.props.deleteOk}
                close={this.props.deleteClose}
                message={"請選擇需要刪除的圖片"}
                content={(
                    <div className="topSelect">
                        {this.props.photo &&
                            this.props.photo.map((placement, index) => {
                                return (
                                    <Row className="align-items-center justify-content-center" key={index}>
                                        <Col xs={"auto"}><input type="checkbox" id={`deleteSelect${index}`}
                                                                name="deleteSelect"/></Col>
                                        {this.props.isNeedUrl ?
                                            <Col xs={"auto"}><label for={`deleteSelect${index}`}><img
                                                src={placement.url}
                                                alt=""/></label></Col>
                                            :
                                            <Col xs={"auto"}><label for={`deleteSelect${index}`}><img src={placement}
                                                                                                      alt=""/></label></Col>
                                        }
                                    </Row>
                                )
                            })}
                    </div>
                )}
            />

        </>)
    }
}

export class ModalSignDetail extends React.Component {

    render() {
        return (<>
            <ModalBase
                show={this.props.show}
                message={"課程報名詳細資料"}
                ok={this.props.ok}
                close={this.props.close}
                modalSize={"upsetModal"}
                content={<div className="modalContent">
                    <p>會籍號碼：{this.props.signItem.memberId}</p>
                    <p>姓名：{this.props.signItem.name}</p>
                    {this.props.type === 1 ? <>
                        <p>出生日期：{this.props.signItem.birthday}</p>
                        <p>身分證字號：{this.props.signItem.identity}</p>
                        <p>戶籍地址：{this.props.signItem.residenceAddress}</p>
                        <p>學歷{this.props.signItem.education}</p>
                        <p>畢業學校：{this.props.signItem.graduatedSchool}</p>
                        <p>結業證書字號：{this.props.signItem.certificateOfCompletionNumber}</p>
                        <p>服務單位：{this.props.signItem.serviceUnit}</p>
                    </> : <><p>公司名稱：{this.props.signItem.companyName}</p></>}
                    <p>電子郵件：{this.props.signItem.email}</p>
                    <p>手機號碼：{this.props.signItem.cellPhone}</p>
                    <p>電話號碼：{this.props.signItem.phone}</p>
                    <p>傳真號碼：{this.props.signItem.faxNumber}</p>
                    <p>聯絡地址：{this.props.signItem.address}</p>
                    {this.props.type === 1 ? <></>: <p>餐點需求：{this.props.signItem.meal}</p>}
                    <p>備註：{this.props.signItem.memo}</p>
                </div>}/>
        </>)
    }
}

export class ModalEditSafetyMemo extends React.Component {
    sureSendMemo = []
    errorPage = "maintainOSF"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            template: [],
            safetyMemo: [],
            editMemo: "",
            editMemoIndex: 0,
            select: "create",
            createTemplateId: 1,
            editTemplateId: 1,
            createMemo: "",
            showInfo: false,
            infoMessage: "",
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                this.getTemplate()
            }
        }
    }

    getTemplate = () => {
        trackPromise(
            TrainRequest.getOSF().then(response => {
                this.setState({template: response.data})
                this.getSafetyMemo(1)
            })
        )
    }

    getSafetyMemo = (templateId) => {
        trackPromise(
            TrainRequest.getOSFMemo(templateId)
                .then(response => {
                    this.setState({
                        safetyMemo: response.data.courseMemoArray,
                        editMemo: response.data.courseMemoArray[this.state.editMemoIndex]
                    })
                })
        )
    }

    createTemplateChange = () => {
        let value = document.getElementById("createTemplateId")
        let template = value.options[value.selectedIndex].value
        this.setState({createTemplateId: template})
        this.getSafetyMemo(template)
    }

    editTemplateChange = () => {
        let value = document.getElementById("editTemplateId")
        let template = value.options[value.selectedIndex].value
        this.setState({editTemplateId: template})
        this.getSafetyMemo(template)
    }

    memoChange = () => {
        let value = document.getElementById("memo")
        let memo = value.options[value.selectedIndex].value
        let index = value.options[value.selectedIndex].index
        this.setState({
            editMemo: memo,
            editMemoIndex: index
        })
    }

    radioOnChange = (event) => {
        this.setState({select: event.target.value})
        if (event.target.value === "create") {
            this.getSafetyMemo(this.state.createTemplateId)
        } else if (event.target.value === "edit") {
            this.getSafetyMemo(this.state.editTemplateId)
        }
    }

    createMemo = (event) => {
        this.setState({createMemo: event.target.value})
    }

    editMemo = (event) => {
        this.setState({editMemo: event.target.value})
    }

    sendEditMemo = () => {
        if (this.state.select === "create") {
            for (let i = 0; i < this.state.safetyMemo.length + 1; i++) {
                if (i === this.state.safetyMemo.length) {
                    let object = {
                        "content": this.state.createMemo,
                        "order": i + 1
                    }
                    this.sureSendMemo.push(object)
                } else {
                    let object = {
                        "content": this.state.safetyMemo[i],
                        "order": i + 1
                    }
                    this.sureSendMemo.push(object)
                }
            }
            trackPromise(
                TrainRequest.editSafetyMemo(this.state.createTemplateId, this.sureSendMemo)
                    .then(response => {
                        this.showInfo("編輯成功")
                    })
                    .catch(error => {
                        this.showInfo("編輯失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )
        } else if (this.state.select === "edit") {
            for (let i = 0; i < this.state.safetyMemo.length; i++) {
                if (i === this.state.editMemoIndex) {
                    let object = {
                        "content": this.state.editMemo,
                        "order": i + 1
                    }
                    this.sureSendMemo.push(object)
                } else {
                    let object = {
                        "content": this.state.safetyMemo[i],
                        "order": i + 1
                    }
                    this.sureSendMemo.push(object)
                }
            }
            trackPromise(
                TrainRequest.editSafetyMemo(this.state.editTemplateId, this.sureSendMemo)
                    .then(response => {
                        this.showInfo("編輯成功")
                    })
                    .catch(error => {
                        this.showInfo("編輯失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )
        }
        window.location.reload()
    }

    showInfo = (message) => {
        this.setState({
            showInfo: !this.state.showInfo,
            infoMessage: message
        })
    }

    render() {
        return (<>
            <ModalSureSend
                show={this.props.show}
                ok={this.sendEditMemo}
                close={this.props.close}
                content={(<div className="editTopBottom">
                    <Row className="editSafetyMemo">
                        <Col xs={6}>
                            <input type="radio" name="createOrEdit" id="create" value="create"
                                   checked={this.state.select === "create"}
                                   onClick={this.radioOnChange} className="editMemoRadio"/>
                            <label htmlFor={`create`}>新增</label>
                        </Col>
                        <Col xs={6}>
                            <input type="radio" name="createOrEdit" id="edit" value="edit" onClick={this.radioOnChange}
                                   checked={this.state.select === "edit"}
                                   className="editMemoRadio"/>
                            <label htmlFor={`edit`}>修改</label>
                        </Col>
                    </Row>
                    <Row className="editSafetyMemo">
                        <Col xs={6}>
                            <div disabled={this.state.select === "edit"}>
                                <select id="createTemplateId" onChange={this.createTemplateChange}
                                        name="createTemplateId">{
                                    this.state.template.map((placement, index) => {
                                        return (
                                            <option key={index} value={placement.templateId}>{placement.name}</option>)
                                    })
                                }</select>
                                <textarea type="text" id="createSafetyMemo" className="editMemoInput"
                                          onBlur={this.createMemo}/>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div disabled={this.state.select === "create"}>
                                <select id="editTemplateId" onChange={this.editTemplateChange} name="editTemplateId">{
                                    this.state.template.map((placement, index) => {
                                        return (
                                            <option key={index} value={placement.templateId}>{placement.name}</option>)
                                    })
                                }</select>
                                <select id="memo" onChange={this.memoChange} name="memo">{
                                    this.state.safetyMemo.map((placement, index) => {
                                        return (<option key={index} value={placement}>{placement}</option>)
                                    })
                                }</select>
                                <textarea type="text" value={this.state.editMemo} className="editMemoInput"
                                          onChange={this.editMemo}/>
                            </div>
                        </Col>
                    </Row>
                </div>)}
                message={"編輯注意事項"}
            >
            </ModalSureSend>
        </>)
    }
}

export class ModalPasswordChange extends React.Component {
    render() {
        return (<>
            <Modal show={this.props.show} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered backdrop="static" className="loginModal">
                <Modal.Body className="show-grid loginModalBody">
                    <Row className="justify-content-end">
                        <Col className="modalClose">
                            <div className="close" aria-label="Close" onClick={this.props.close}>
                                <span aria-hidden="true">&times;</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="modalContainer">
                        <Col>
                            <p className="modalLabel">修改密碼</p>
                            <p><input placeholder="請輸入新密碼" type="password" id="newPwd" name="newPwd"
                                      onChange={this.props.handleValueChange}/></p>
                            <p><Button className="kanbanModalButton" onClick={this.props.ok}>確認</Button></p>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>)
    }
}
