import React from "react";
import {Col} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import {AnnouncementMaintain} from "../../../js/announcementMaintain";
import {AnnouncementRequest} from "../../../js/request/announcementRequest";


class Maintain extends React.Component {
    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <AnnouncementMaintain secondTitle={"會務公告"} thirdTitle={"公告維護"} subLabel={
                        <>
                            <Col style={{textAlign: "left"}}>主旨</Col>
                            <Col xs={3} sm={2}>公告日期</Col>
                            <Col xs={2} sm={1}>編輯</Col>
                            <Col xs={2} sm={1}>刪除</Col>
                        </>
                    } getSize={AnnouncementRequest.getSize} searchList={AnnouncementRequest.searchList}/>
                    : this.props.history.push("/")
                }
            </>
        );
    }
}

export default {
    routeProps: {
        path: "/Announcement/maintain",
        component: Maintain
    },
    name: "公告維護"
}






