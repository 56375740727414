import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {Col, Row} from "react-bootstrap";

import {DynamicFormInput} from "../../../js/formInput"
import {FormDownloadRequest} from "../../../js/request/formDownloadRequest"
import {ModalBase, ModalSureSend} from "../../../js/modal";


class CreateForm extends React.Component {
    errorPage = "formDownload"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        };
    }

    handleModalShow = (content) => {
        this.setState({modalTitle: content});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    sureSend = (toSend) => {
        if (toSend && this.state.sureSend) {
            let formData = new FormData();
            formData.append("name", document.getElementById("title").value);
            formData.append("file", document.getElementById("file").files[0]);
            trackPromise(
                FormDownloadRequest.create(formData)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("新增成功")
                        window.location.href = window.location.href.split("/create")[0];
                    })
                    .catch(error => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("新增失敗")
                        document.getElementById("errorInfo").innerHTML = error.message
                        console.log(error.messge)
                    })
            )
        } else {
            this.setState({"sureSend": !this.state.sureSend})
        }

    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    render() {
        let data = [
            {"title": "檔名", "require": true, "input": (<><input type="text" id="title"/></>)},
            {"title": "檔案上傳", "require": true, "input": (<><input type="file" id="file"/></>)},
        ]
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage">
                        <BreadcrumbObject secondTitle={"表單下載"} thirdTitle={"新增表單下載"}/>
                        <DynamicFormInput data={data}/>
                        <Row style={{paddingTop: "5%"}}>
                            <Col xs={2} style={{margin: "0 auto"}}>
                                <button className="kanEdit ckeditorBtn btn btn-success"
                                        onClick={() => this.sureSend(false)}>送出
                                </button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.sureSend} close={() => this.sureSend(false)}
                                       ok={() => this.sureSend(true)} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                                   message={this.state.infoMessage} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/form/create",
        component: CreateForm
    },
    name: "新增表單下載",
}
