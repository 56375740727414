import React from "react";
import {Col, Row} from "react-bootstrap"
import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import edit from "../../../img/edit.png"
import deleteIcon from "../../../img/icon/delete-24px.svg"
import {Paging} from "../../../js/pagination";
import {ModalSureSend} from "../../../js/modal";
import {JournalRequest} from "../../../js/request/journalRequest";


class JournalMaintain extends React.Component {
    pageTotal = 0;
    size = 12;

    constructor(props) {
        super(props);
        this.state = {
            "nowPage": 1,
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token"),
            "responseData": [],
            "showDel": false,
            "deleteJournalId": null,
            "showInfo": false,
            "infoMessage": "",
        };
    }

    componentDidMount = () => {
        this.getSize();
    }

    getSize = () => {
        trackPromise(
            JournalRequest.getSize({"size": this.size})
                .then(response => {
                    this.setState({"pageTotal": response.data.totalPage})
                    this.getList(1);
                })
        )
    }

    getList = (now) => {
        trackPromise(
            JournalRequest.searchList({
                "size": this.size,
                "page": now
            }).then(response => {
                this.setState({"responseData": response.data})
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        this.getList(now);
    }

    redirectTo = (aim) => {
        const path = window.location.href.replace("maintain", aim)
        window.location.href = path;
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showDel = (id) => {
        if (!this.state.showDel) {
            this.setState({"deleteJournalId": id})
        }
        this.setState({"showDel": !this.state.showDel})
    }

    deleteJournal = () => {
        trackPromise(
            JournalRequest.deleteList(this.state.deleteJournalId).then(response => {
                this.showInfo(response.message)
                this.showDel()
                window.location.reload()
            })
        )
    }

    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <>
                        <BreadcrumbObject secondTitle={"工業期刊"} thirdTitle={"期刊維護"}/>
                        <div className="subLabel">
                            <Row noGutters={true}>
                                <Col xs={1}></Col>
                                <Col>期別</Col>
                                <Col xs={3}>出刊日</Col>
                                <Col xs={2}>編輯</Col>
                                <Col xs={2}>刪除</Col>
                            </Row>
                        </div>
                        <hr/>
                        <div className="announceContent content">
                            {this.state.responseData &&
                                this.state.responseData.map((data, index) => {
                                    return (
                                        <div className="contentBorder announceContentItem" key={index}>
                                            <Row noGutters={true}>
                                                <Col className="lectureNo" xs={1}>
                                                    <div>{index + 1}</div>
                                                </Col>
                                                <Col className="journalVerticalCenter">{data.title}</Col>
                                                <Col xs={3} className="journalVerticalCenter">{data.publishDate}</Col>
                                                <Col xs={2}><img src={edit} alt="" className="imgIcon"
                                                                 onClick={() => this.redirectTo(`edit/${data.id}`)}/></Col>
                                                <Col xs={2}><img src={deleteIcon} alt="" className="imgIcon"
                                                                 onClick={() => this.showDel(data.id)}/></Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                        </div>
                        <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange}
                                nowPage={this.state.nowPage}/>
                        <ModalSureSend show={this.state.showDel} message={"確認刪除"} close={() =>
                            this.showDel()}
                                       ok={() => {
                                           this.deleteJournal()
                                       }}
                        />
                    </> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}

export default {
    routeProps: {
        path: "/journal/maintain",
        component: JournalMaintain
    },
    name: "工業期刊",
}




