import React from "react";
import {Button, Col, Row} from "react-bootstrap"
import BreadcrumbObject from "../../../../js/breadcrumb"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../css/main.css";
import edit from "../../../../img/edit.png"
import deleteIcon from "../../../../img/icon/delete-24px.svg"
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "../../../../js/request/trainRequest"
import {Paging} from "../../../../js/pagination"
import {ModalBase, ModalCourseInfo, ModalEditSafetyMemo, ModalSureSend} from "../../../../js/modal"

class MaintainCourse extends React.Component {
    type = 0;
    pageTotal = 0

    constructor(props) {
        super(props);
        this.state = {
            "isLogin": !!localStorage.getItem("token"),
            "isAdmin": localStorage.getItem("identity") === "admin",
            "contentNo": [],
            "pageName": "",
            "pageTotal": this.pageTotal,
            "nowPage": 1,
            "showlectureInfo": false,
            "showApply": false,
            "showInfo": false,
            "showDel": false,
            "infoMessage": "",
            "delCourseID": null,
            "lectureItem": {},
            "isEditSafetyMemo": false
        }
    }

    componentDidMount = () => {
        switch (window.location.href.split("maintain")[0].split("Education-And-Train")[1]) {
            case "/Lecture/":
                this.setState({"pageName": "講習課程"});
                this.type = 0;
                break;
            case "/Occupational-Safety-And-Health/":
                this.setState({"pageName": "職安衛課程"});
                this.type = 1;
                break;
            case "/other/":
                this.setState({"pageName": "其他課程"});
                this.type = 3;
                break;
            default:
                break;

        }
        trackPromise(
            TrainRequest.getSize({"type": this.type})
                .then(response => {
                    this.pageTotal = Math.ceil(response.data.size / 12)
                    this.setState({"pageTotal": this.pageTotal})
                    this.getCourse(1);
                })
        )
    }

    getCourse = (now) => {
        trackPromise(
            TrainRequest.searchList({
                "type": this.type,
                "size": 12,
                "page": now
            }).then(response => {
                this.setState({"contentNo": response.data})
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        this.getCourse(now)
    }

    getDetail = (no) => {
        trackPromise(
            TrainRequest.getDetail(no)
                .then(response => {
                    if (this.type === 1) {
                        this.getMemo(response.data.templateId)
                    }
                    this.setState({"lectureItem": response.data})
                    document.getElementById("lectureInfo").innerHTML = response.data.content.replace(/\n/g, "<br />");
                })
        )
    }

    getMemo = (templateId) => {
        trackPromise(
            TrainRequest.getOSFMemo(templateId)
                .then(response => {
                    this.setState({"safetyMemo": response.data.courseMemoArray})
                })
        )
    }

    showDetail = (no) => {
        if (!this.state.showlectureInfo) {
            this.getDetail(no)
        }
        this.setState({"showlectureInfo": !this.state.showlectureInfo})
    }

    redirectTo = (aim) => {
        const path = window.location.href.replace("maintain", aim)
        window.location.href = path;
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showDel = (id) => {
        if (!this.state.showDel) {
            this.setState({"delCourseID": id})
        }
        this.setState({"showDel": !this.state.showDel})
    }

    showEditSafetyMemo = () => {
        this.setState({"isEditSafetyMemo": !this.state.isEditSafetyMemo})
    }

    editSafetyMemo = () => {

    }

    delCourse = () => {
        trackPromise(
            TrainRequest.delLecture(this.state.delCourseID).then(response => {
                this.showInfo(response.message)
                this.showDel()
                window.location.reload()
            })
        )
    }

    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="maintain">
                        <Row className="align-items-center controlButtons">
                            <Col xs={"auto"} className="label">課程維護</Col>
                            {this.state.isLogin && this.state.isAdmin ? <>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit editBlue" onClick={() => {
                                        this.redirectTo("SignUpList")
                                    }}>報名列表</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit" variant="success"
                                            onClick={() => {
                                                this.redirectTo("create")
                                            }}>新增課程</Button>
                                </Col>
                                {this.type === 1 ?
                                    <Col xs={"auto"}>
                                        <Button className="edit kanEdit editBlue" onClick={() => {
                                            this.showEditSafetyMemo()
                                        }}>編輯注意事項</Button>
                                    </Col>
                                    : <></>}
                            </> : <></>}
                        </Row>
                        <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                        <div className="subLabel">
                            <Row noGutters={true}>
                                <Col xs={1}/>
                                <Col xs={3} sm={2} className="lectureID">課程編號</Col>
                                <Col className="lectureName">課程名稱</Col>
                                <Col xs={3} sm={2} className="hiddenDate">上課日期</Col>
                                <Col xs={2} sm={1}>編輯</Col>
                                <Col xs={2} sm={1}>刪除</Col>
                            </Row>
                        </div>
                        <hr/>
                        <div className="content">
                            {this.state.contentNo.map((item, index) => {
                                return (
                                    <div className="announceContentItem contentBorder lectureContentItem" key={index}>
                                        <Row noGutters={true} className="align-items-center">
                                            <Col className='lectureNo' xs={1}>
                                                <div>{index + 1 + 12 * (this.state.nowPage - 1)}</div>
                                            </Col>
                                            <Col className="lectureID" xs={3} sm={2}>{item.id}</Col>
                                            <Col className="lectureName ellipsis" onClick={() => {
                                                this.showDetail(item.id)
                                            }}>
                                                <div>{item.name}</div>
                                            </Col>
                                            <Col className="hiddenDate" xs={3} sm={2}>{item.startClassTime}</Col>
                                            <Col xs={2} sm={1}>
                                                <img src={edit} className="imgIcon" alt=""
                                                     onClick={() => this.redirectTo(`edit/${item.id}`)}/>
                                            </Col>
                                            <Col xs={2} sm={1}>
                                                <img src={deleteIcon} className="imgIcon" alt=""
                                                     onClick={() => this.showDel(item.id)}/>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </div>
                        <Paging pageTotal={this.state.pageTotal}
                                pageChange={this.pageChange}
                                nowPage={this.state.nowPage}/>
                        <ModalCourseInfo lectureItem={this.state.lectureItem} show={this.state.showlectureInfo}
                                         close={() => {
                                             this.showDetail()
                                         }} type={this.type}
                                         safetyMemo={this.state.safetyMemo}/>
                        <ModalBase show={this.state.showInfo} close={() => {
                            this.showInfo()
                        }} ok={() => {
                            this.showInfo()
                        }} message={this.state.infoMessage}/>
                        <ModalSureSend show={this.state.showDel} message={"確認刪除"} close={() =>
                            this.showDel()}
                                       ok={() => {
                                           this.delCourse()
                                       }}
                        />
                        <ModalEditSafetyMemo show={this.state.isEditSafetyMemo} close={() => {
                            this.showEditSafetyMemo()
                        }} ok={() => {
                            this.editSafetyMemo()
                        }}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}

export const maintainLecture = {
    routeProps: {
        path: "/Education-And-Train/Lecture/maintain",
        component: MaintainCourse
    },
    name: "講習課程"
}

export const maintainOSH = {
    routeProps: {
        path: "/Education-And-Train/Occupational-Safety-And-Health/maintain",
        component: MaintainCourse
    },
    name: "職安衛課程"
}

export const maintainOther = {
    routeProps: {
        path: "/Education-And-Train/Other/maintain",
        component: MaintainCourse
    },
    name: "其他課程"
}





