import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Recruit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.recruit.id,
            editorTypeName: aboutType.recruit.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "會員服務" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}


export default {
    routeProps: {
        path: "/recruit",
        component: Recruit
    },
    name: "人才招募",
}
