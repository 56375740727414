import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Vision extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.vision.id,
            editorTypeName: aboutType.vision.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "關於本會" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}

export default {
    routeProps: {
        path: "/vision",
        component: Vision
    },
    name: "願景與使命",
}
