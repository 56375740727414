import React from "react";
import {Col, Row, Button} from "react-bootstrap"
import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import "react-awesome-slider/dist/styles.css";
import {ActivityRequest} from "../../../js/request/activityRequest";
import {ModalAddPhoto, ModalBase, ModalTopSelect, ModalDeletePhoto, ModalModifyAlbum} from "../../../js/modal.js"
import {Paging} from "../../../js/pagination"
import {createGlobalStyle} from "styled-components/macro";
import close from "../../../img/close.png";
import left from "../../../img/left.png";
import right from "../../../img/right.png";


class Album extends React.Component {
    errorPage = "album"
    errorId = `${this.errorPage}ErrorInfo`
    imgs = []
    pageItem = []
    pageTotal = 0

    constructor(props) {
        super(props)
        this.state = {
            "isLogin": !!localStorage.getItem("token"),
            "isAdmin": localStorage.getItem("identity") === "admin",
            "showModifyAlbum": false,
            "showAdd": false,
            "showDelete": false,
            "showSlider": false,
            "showInfo": false,
            "photoPage": 0,
            "page": 1,
            "pageTotal": this.pageTotal,
            "nowPage": 1,
            "imgs": [{
                "id": "",
                "url": ""
            }],
            "cover": "",
            "clickPhoto": 0,
            "showTopSelect": false,
            "photo": [],
            "photoSelect": [],
            "forPreview": "",
            "isImgShow": false,
            "isLeftArrow": true,
            "isRightArrow": true,
            "modifyAlbum": {},
        }
    }

    componentDidMount = () => {
        this.getAlbum()
    }

    show = (clickPhoto) => {
        this.setState({"showSlider": !this.state.showSlider, "clickPhoto": clickPhoto})
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showTopSelect = () => {
        this.setState({"showTopSelect": !this.state.showTopSelect})
    }

    topSelect = () => {
        let topSelect = document.getElementsByName("topSelect")
        let checkSelect = false
        let photoSelect = []
        let photoCheck = 0;
        let forPreview = ""
        for (let i = 0; i < topSelect.length; i++) {
            photoSelect.push(topSelect[i].checked)
            if (topSelect[i].checked) {
                photoCheck = i
                checkSelect = true
                forPreview = this.state.photo[i]
            }
        }
        if (checkSelect) {
            this.setState({
                "showTopSelect": !this.state.showTopSelect,
                "photoSelect": photoSelect,
                "photoChecked": photoCheck,
                "forPreview": forPreview
            })
            this.patchCover(this.state.imgs[photoCheck].id)
        } else {
            this.showInfo("請選擇封面圖片")
        }
    }

    deletePhotoSelect = () => {
        let select = document.getElementsByName("deleteSelect")
        let deleteSelect = []
        let checkSelect = false
        for (let i = 0; i < select.length; i++) {
            if (select[i].checked) {
                deleteSelect.push(i)
                checkSelect = true
            }
        }
        if (checkSelect) {
            let photo = []
            deleteSelect.forEach(placement => {
                photo.push(this.state.imgs[placement].id)
            })
            let data = {
                "photoIdArray": photo
            }
            trackPromise(
                ActivityRequest.deletephoto(data)
                    .then(response => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("刪除成功")
                        window.location.href = window.location.href.split("/album")[0] + "/album";
                    })
                    .catch(error => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("刪除失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )

        } else {
            this.showInfo("請選擇要刪除的圖片")
        }
    }

    showModifyAlbum = () => {
        this.setState({"showModifyAlbum": !this.state.showModifyAlbum})
    }
    nameChange = (event) => {
        this.setState({name: event.target.value})
    }
    placeChange = (event) => {
        this.setState({place: event.target.value})
    }
    contentChange = (event) => {
        this.setState({content: event.target.value})
    }
    topChange = (event) => {
        this.setState({top: !this.state.top})

    }
    modifyAlbum = () => {
        let id = window.location.href.split("album/")[1]
        let activity = {
            "title": this.state.name,
            "activityTime": document.getElementById("activityTime").value,
            "place": this.state.place,
            "content": this.state.content,
            "top": this.state.top
        }
        trackPromise(
            ActivityRequest.patchAlbum(id, activity).then(response => {
                this.setState({"showModifyAlbum": !this.state.showModifyAlbum});
                this.showInfo("修改成功");
                window.location.reload();
            }).catch(error => {
                this.setState({"showModifyAlbum": !this.state.showModifyAlbum});
                this.showInfo("修改失敗");
                document.getElementById(this.errorId).innerHTML = error.message
            })
        )

    }

    showAddPhoto = () => {
        this.setState({"showAdd": !this.state.showAdd})
    }

    showDeletePhoto = () => {
        this.setState({"showDelete": !this.state.showDelete})
    }

    sendAdd = () => {
        let formData = new FormData()
        let photoInput = document.getElementById("photoFileArray")
        let topPhoto = this.state.photoSelect
        for (let i = 0; i < photoInput.files.length; i++) {
            formData.append("photoFileArray", photoInput.files[i]);
            formData.append("typeArray", topPhoto[i] ? false : topPhoto[i]);
        }
        let no = window.location.href.split("album/")[1]
        let info = "";
        if (!formData.get("photoFileArray")) {
            info = "請選擇圖片";
        } else {
            trackPromise(
                ActivityRequest.addActivityPhoto(no, formData).then(response => {
                    this.setState({"showAdd": !this.state.showAdd})
                    this.showInfo("新增成功")
                    window.location.reload()
                }).catch(error => {
                    this.showInfo("新增失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                    console.log(error.messge)
                })
            )
        }
        if (info) {
            this.showInfo(info);
        }
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
    }

    getAlbum = () => {
        trackPromise(
            ActivityRequest.getAlbum(window.location.href.split("album/")[1]).then(response => {
                if (response.data.photoArray.length > 0) {
                    response.data.photoArray.forEach(placement => {
                        this.imgs.push({"id": placement.id, "url": placement.url})
                    })
                    this.pageTotal = Math.ceil(this.imgs.length / 12)
                    for (let i = 1; i <= (this.pageTotal < 3 ? this.pageTotal : 3); i++) {
                        this.pageItem.push({"active": i === 1, "page": i})
                    }
                    this.setState({
                        "modifyAlbum": response.data,
                        "top": response.data.top,
                        "cover": response.data.cover,
                        "content": response.data.content,
                        "activityTime": response.data.activityTime,
                        "name": response.data.name,
                        "place": response.data.place,
                        "imgs": this.imgs,
                        "pageTotal": this.pageTotal,
                        "pageItem": this.pageItem
                    })
                } else {
                    window.location.href = window.location.href.split("album")[0] + "activity/-1/1";
                }
            }).catch(error => {
                console.log(error)
            })
        )
    }

    patchCover = (id) => {
        let data = {
            topPhotoId: id,
        };
        trackPromise(
            ActivityRequest.patchCover(window.location.href.split("album/")[1], data)
                .then(response => {
                    this.setState({"showSend": !this.state.showSend})
                    this.showInfo("更新成功")
                    window.location.reload();
                })
                .catch(error => {
                    this.setState({"showSend": !this.state.showSend})
                    this.showInfo("更新失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                })
        )
    }

    imgShow = (index) => {
        this.setState(prevState => ({isImgShow: !prevState.isImgShow}))
        this.setState({clickPhoto: index})
    }

    clickLeftArrow = () => {
        this.setState(prevstate => ({clickPhoto: prevstate.clickPhoto - 1}))
    }

    clickRightArrow = () => {
        this.setState(prevstate => ({clickPhoto: prevstate.clickPhoto + 1}))
    }


    render() {
        const GlobalStyle = createGlobalStyle`
            body {
                overflow:hidden;
            }
        `;
        return (
            <div className="album">
                {this.state.isImgShow ?
                    <div className="albumImg">
                        <img src={close} alt="" className="albumImgClose" onClick={this.imgShow}/>
                        <Row className="albumImgPosition">
                            <Col xs={1}>{this.state.clickPhoto === 0 ? "" :
                                <img src={left} alt="" className="albumArrowImg"
                                     onClick={this.clickLeftArrow}/>}</Col>
                            <Col>
                                <center className="albumClickImg"><img
                                    src={this.state.imgs[this.state.clickPhoto].url} alt=""/></center>
                            </Col>
                            <Col xs={1}>{this.state.clickPhoto + 1 === this.state.imgs.length ? "" :
                                <img src={right} alt="" className="albumArrowImg"
                                     onClick={this.clickRightArrow}/>}</Col>
                        </Row>
                        <GlobalStyle/>
                    </div>
                    : ""
                }

                <Row className="controlButtons">
                    <Col xs={"auto"} className="label">相簿展示</Col>
                    {this.state.isLogin && this.state.isAdmin ?
                        <>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editYellow" variant="warning" onClick={() => {
                                    this.showTopSelect(false)
                                }}>修改封面
                                </Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editYellow" variant="warning" onClick={() => {
                                    this.showModifyAlbum(false)
                                }}>修改相簿
                                </Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit" variant="success" onClick={() => {
                                    this.showAddPhoto(false)
                                }}>新增圖片
                                </Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editRed" variant="danger" onClick={() => {
                                    this.showDeletePhoto(false)
                                }}>刪除圖片</Button>
                            </Col>
                        </> :
                        <></>
                    }
                </Row>
                <BreadcrumbObject secondTitle={"活動花絮"}/>
                <Row className="info">
                    <Col sm={5}>
                        <img src={this.state.cover} alt="" className="cover"/>
                    </Col>
                    <Col>
                        <div>
                            <p>活動名稱：{this.state.name}</p>
                            <p>活動時間：{this.state.activityTime}</p>
                            <p>活動地點：{this.state.place}</p>
                            <p>活動內容：</p>
                            <div className="content">{this.state.content}</div>
                        </div>
                    </Col>
                </Row>
                <Row className="content">
                    {this.state.imgs.slice((this.state.nowPage - 1) * 12, (this.state.nowPage - 1) * 12 + 12).map((placement, index) => {
                        return (<Col sm={6} lg={4} key={index}>
                            <div className="outImg"><img src={placement.url} alt="" onClick={() => {
                                this.imgShow((this.state.nowPage - 1) * 12 + index)
                            }}/></div>
                        </Col>)
                    })}
                </Row>
                <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange}
                        nowPage={this.state.nowPage}/>
                <ModalBase show={this.state.showInfo} close={() => {
                    this.showInfo()
                }} ok={() => {
                    this.showInfo()
                }} message={this.state.infoMessage} error={this.errorPage}/>
                <ModalAddPhoto addShow={this.state.showAdd} addClose={() => {
                    this.showAddPhoto()
                }} addOk={() => {
                    this.sendAdd()
                }}/>
                <ModalDeletePhoto deleteShow={this.state.showDelete} deleteClose={() => {
                    this.showDeletePhoto()
                }} deleteOk={() => {
                    this.deletePhotoSelect()
                }} photo={this.state.imgs} isNeedUrl={true}/>
                <ModalTopSelect show={this.state.showTopSelect} close={() => {
                    this.showTopSelect()
                }} ok={() => {
                    this.topSelect()
                }} photo={this.state.imgs} isNeedUrl={true}/>
                <ModalModifyAlbum modifyShow={this.state.showModifyAlbum} modifyClose={() => {
                    this.showModifyAlbum()
                }} modifyOk={() => {
                    this.modifyAlbum()
                }} modifyAlbumNameChange={this.nameChange}
                                  modifyAlbumPlaceChange={this.placeChange}
                                  modifyAlbumContentChange={this.contentChange}
                                  modifyAlbumTopChange={this.topChange}
                                  modifyAlbumName={this.state.name}
                                  modifyAlbumPlace={this.state.place}
                                  modifyAlbumContent={this.state.content}
                                  modifyAlbumTop={this.state.top}/>
            </div>
        );
    }
}

export const album = {
    routeProps: {
        path: "/album/:id",
        component: Album
    },
    name: "活動花絮"
}
