import React from "react";
import {Col} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import {AnnouncementMaintain} from "../../../js/announcementMaintain";
import {FormDownloadRequest} from "../../../js/request/formDownloadRequest";


class FormMaintain extends React.Component {
    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <AnnouncementMaintain secondTitle={"表單下載"} thirdTitle={"表單維護"} subLabel={
                        <>
                            <Col style={{textAlign: "left"}}>主旨</Col>
                            <Col xs={2} sm={1}>編輯</Col>
                            <Col xs={2} sm={1}>刪除</Col>
                        </>
                    } isFormDownload={true} getSize={FormDownloadRequest.getSize}
                                          searchList={FormDownloadRequest.searchList}/> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}

export default {
    routeProps: {
        path: "/form/maintain",
        component: FormMaintain
    },
    name: "表單維護"
}






