import React from "react";
import {Button, Col, Row} from "react-bootstrap"
import BreadcrumbObject from "../../../../js/breadcrumb"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../css/main.css";
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "../../../../js/request/trainRequest"
import {Paging} from "../../../../js/pagination"
import {ModalSignDetail, ModalSureSend} from "../../../../js/modal"

class SignUpLecture extends React.Component {
    type = 0;
    pageTotal = 0

    constructor(props) {
        super(props);
        this.state = {
            "isLogin": !!localStorage.getItem("token"),
            "isAdmin": localStorage.getItem("identity") === "admin",
            "contentNo": [],
            "pageTotal": this.pageTotal,
            "nowPage": 1,
            "showInfo": false,
            "sureSend": false,
            "showCancel": false,
            "showSignDetail": false,
            "infoMessage": "",
            "courseName": [{"id": "", "name": "請選擇課程"}],
            "signupItem": [],
            "signItem": {},
            "downloadCourseId": "",
        }
    }

    componentDidMount = () => {
        this.checkType()
        trackPromise(
            TrainRequest.getCourseName({"type": this.type}).then(response => {
                this.setState({"courseName": this.state.courseName.concat(response.data)})
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
    }

    checkType = () => {
        switch (window.location.href.split("SignUpList")[0].split("Education-And-Train")[1]) {
            case "/Lecture/":
                this.setState({"pageName": "講習課程"});
                this.type = 0;
                break;
            case "/other/":
                this.setState({"pageName": "其他課程"});
                this.type = 3;
                break;
            default:
                break;

        }
    }

    getList = () => {
        let value = document.getElementById("courseName").value
        let data = {"type": this.type}
        if (value) data.id = value
        this.setState({"downloadCourseId": value})
        if(value !== "") {
            trackPromise(
                TrainRequest.getSignUp(data).then(response => {
                    this.setState({
                        "signupItem": response.data,
                        "pageTotal": Math.ceil(response.data.length / 12),
                        "nowPage":1
                    })
                })
            )
        }
    }

    getDetail = (id) => {
        let data = {
            "type": this.type
        }
        trackPromise(
            TrainRequest.getSignUpDetail(id, data).then(response => {
                this.setState({"signItem": response.data})
            })
        )
    }

    cancelSignUp = () => {
        let data = new FormData();
        data.append("type", this.type);
        trackPromise(TrainRequest.cancelSignUp(this.state.id, data)
            .then(response => {
                this.showInfo("取消成功")
                window.location.reload();
            })
        )
    }

    redirectTo = (aim) => {
        window.location.href = window.location.href.replace("SignUpList", aim);
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showCancel = (id) => {
        this.setState({
            "showCancel": !this.state.showCancel,
            "id": id
        })
    }

    showSignDetail = (id) => {
        this.setState({"showSignDetail": !this.state.showSignDetail})
        if (!this.state.showSignDetail) {
            this.getDetail(id)
        }
    }

    downloadEmployeeData = () => {
        let course = ""
        switch (this.type) {
            case 0:
                course = "lecture"
                if (this.state.downloadCourseId === "") {
                    this.setState({"downloadCourseId": "講習課程"})
                }
                break;
            case 3:
                course = "other"
                if (this.state.downloadCourseId === "") {
                    this.setState({"downloadCourseId": "其他課程"})
                }
                break;
            default:
                break;
        }
        const url = course === "lecture" ?
            TrainRequest.getLectureDownLoadURL({courseId: this.state.downloadCourseId}) :
            TrainRequest.getOtherDownLoadURL({courseId: this.state.downloadCourseId});
        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem("token")
            },
            responseType: "arraybuffer"
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.state.downloadCourseId}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        return (
            <div className="signUpList">
                <Row className="align-items-center controlButtons">
                    <Col xs={"auto"} className="label">報名列表</Col>
                    {this.state.isLogin && this.state.isAdmin ? (<>
                        <Col xs={"auto"}>
                            <select id="courseName" onChange={() => {
                                this.getList()
                            }}>{
                                this.state.courseName.map((placement, index) => {
                                    return (
                                        <option key={index} value={placement.id}
                                                className="ellipsis">{placement.name}</option>)
                                })
                            }</select>
                        </Col>
                        <Col xs={"auto"}>
                            <Button className="edit kanEdit editBlue"
                                    onClick={() => {
                                        this.redirectTo("maintain")
                                    }}>課程維護
                            </Button>
                        </Col>
                        <Col xs={"auto"}>
                            <Button className="edit kanEdit" variant="success"
                                    onClick={() => {
                                        this.redirectTo("add")
                                    }}>新增課程
                            </Button>
                        </Col>
                        <Col xs={"auto"}>
                            {this.state.signupItem.length>0 ?
                                <Button className="editDownload kanEdit" variant="outline-secondary"
                                        onClick={() => {
                                            this.downloadEmployeeData()
                                        }}>下載
                                </Button>
                                :
                                <Button className="editDownload kanEdit" disabled variant="outline-secondary"
                                        onClick={() => {
                                            this.downloadEmployeeData()
                                        }}>下載
                                </Button>
                            }
                        </Col>
                    </>) : (<></>)}
                </Row>
                <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                <div className="subLabel">
                    <Row noGutters={true}>
                        <Col xs={1}/>
                        <Col xs={3} sm={2} className="lectureID">課程編號</Col>
                        <Col className="lectureName">課程名稱</Col>
                        <Col xs={2}>姓名</Col>
                        <Col xs={"auto"} sm={2}>報名狀態</Col>
                        <Col xs={2} lg={1}/>
                    </Row>
                </div>
                <hr/>
                {this.state.signupItem.length>0 ?
                    <div className="content">
                        {this.state.signupItem.slice((this.state.nowPage - 1) * 12, this.state.nowPage * 12).map((item, index) => {
                            return (
                                <div className="announceContentItem contentBorder lectureContentItem" key={index}>
                                    <Row noGutters={true} className="align-items-center">
                                        <Col className='lectureNo' xs={1}>
                                            <div>{index + 1 + 12 * (this.state.nowPage - 1)}</div>
                                        </Col>
                                        <Col className="lectureID" xs={3} sm={2}>
                                            <div>{item.courseId}</div>
                                        </Col>
                                        <Col className="lectureName ellipsis"
                                             onClick={() => this.showSignDetail(item.id)}>
                                            <div>{item.courseName}</div>
                                        </Col>
                                        <Col xs={"auto"} sm={2}
                                             onClick={() => this.showSignDetail(item.id)}>{item.name}</Col>
                                        <Col xs={2}>{(item.available ? "有效" : "無效")}</Col>
                                        <Col xs={2} lg={1}>
                                            <Button className="edit kanEdit editBlue"
                                                    onClick={() => this.showCancel(item.id)}
                                                    disabled={!item.available}>取消</Button></Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="courseNoPeopleSignUp">尚無人報名！</div>
                }
                {this.state.signupItem.length>0 ?
                    <Paging pageTotal={this.state.pageTotal}
                            pageChange={this.pageChange}
                            nowPage={this.state.nowPage}/>
                    : <></>}
                <ModalSureSend show={this.state.showCancel} close={this.showCancel} ok={this.cancelSignUp}
                               message={"確定取消"}/>
                <ModalSignDetail show={this.state.showSignDetail} ok={this.showSignDetail}
                                 close={this.showSignDetail}
                                 signItem={this.state.signItem}/>
            </div>
        );
    }
}

export const signUpLecture = {
    routeProps: {
        path: "/Education-And-Train/Lecture/SignUpList",
        component: SignUpLecture
    },
    name: "講習課程"
}

export const signUpOther = {
    routeProps: {
        path: "/Education-And-Train/other/SignUpList",
        component: SignUpLecture
    },
    name: "其他課程"
}




