import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class ServiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.serviceList.id,
            editorTypeName: aboutType.serviceList.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "會員服務" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}


export default {
    routeProps: {
        path: "/serviceList",
        component: ServiceList
    },
    name: "本會14項服務列表",
}
