import { navUrl } from "../../src/modules/router.js"

export const navItem = [
    {
        "mainItem": "關於本會",
        "urlArray": navUrl.about,
        "subItemArray": ["宗旨與願景", "會館介紹", "組織架構", "歷屆理事長", "理監事簡介", "聯誼團體", "專業委員會", "會務人員",]

    },
    {
        "mainItem": "會務公告",
        "urlArray": navUrl.club,
        "subItemArray": []
    },
    {
        "mainItem": "會員服務",
        "urlArray": navUrl.memberService,
        "subItemArray": ["服務項目", "人才招募", "法律服務", "場地租借"]
    },
    {
        "mainItem": "法令公告",
        "urlArray": navUrl.decree,
        "subItemArray": []
    },
    {
        "mainItem": "教育訓練",
        "urlArray": navUrl.lecture,
        "subItemArray": ["訓練政策", "講習課程", "職安衛課程", "產投課程", "其他課程"]
    },
    {
        "mainItem": "活動花絮",
        "urlArray": navUrl.activity.split("/:id")[0],
        "subItemArray": [],
        "linkParam":true
    },
    {
        "mainItem": "工業期刊",
        "urlArray": navUrl.journal,
        "subItemArray": []
    },
    {
        "mainItem": "我要入會",
        "urlArray": navUrl.join,
        "subItemArray": []
    },
    {
        "mainItem": "表單下載",
        "urlArray": navUrl.form,
        "subItemArray": []
    },
    {
        "mainItem": "交通方式",
        "urlArray": navUrl.traffic,
        "subItemArray": []
    },
    {
        "mainItem": "相關連結",
        "urlArray": navUrl.links,
        "subItemArray": []
    },
]