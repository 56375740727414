import React from "react";
import aboutType from "../../../js/about";
import BreadcrumbObject from "../../../js/breadcrumb";
import { Editors } from "../../../js/editorTool";

class ClubStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.clubStaff.id,
            editorTypeName: aboutType.clubStaff.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "關於本會" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );
    }
}


export default {
    routeProps: {
        path: "/clubStaff",
        component: ClubStaff
    },
    name: "會務人員",
}
