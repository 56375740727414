import React from "react";
import {Button, Col, Row} from "react-bootstrap"
import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "../../../js/request/trainRequest"
import {DynamicFormInput} from "../../../js/formInput"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import {ModalBase, ModalSureSend} from "../../../js/modal"

class AddLecture extends React.Component {

    errorPage = "addLecture"
    errorId = `${this.errorPage}ErrorInfo`
    type = 0;

    constructor(props) {
        super(props)
        this.state = {
            "pageName": "",
            "sureSend": false,
            "showInfo": false,
            "infoMessage": "",
            "contact": [],
            "osf": []
        }
    }

    componentDidMount = () => {
        switch (window.location.href.split("Education-And-Train")[1]) {
            case "/Lecture/create":
                this.setState({"pageName": "講習課程"});
                this.type = 0;
                break;
            case "/other/create":
                this.setState({"pageName": "其他課程"});
                this.type = 3;
                break;
            default:
                break;
        }
        this.getContact()
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showSend = () => {
        this.setState({"showSend": !this.state.showSend})
    }

    getContact = () => {
        trackPromise(
            TrainRequest.getContact({"type": this.type})
                .then(response => {
                    this.setState({"contact": response.data})
                })
        )
    }


    send = () => {
        let formData = new FormData();
        formData.append("id", document.getElementById("id").value);
        formData.append("top", document.getElementById("top").checked);
        formData.append("topAtCourse", document.getElementById("topAtCourse").checked);
        formData.append("address", document.getElementById("address").value);
        formData.append("name", document.getElementById("name").value);
        formData.append("studentCount", document.getElementById("studentCount").value);
        formData.append("startClassTime", document.getElementById("startClassTime").value);
        formData.append("endClassTime", document.getElementById("endClassTime").value);
        formData.append("deadLine", document.getElementById("deadLine").value);
        formData.append("memberPrice", document.getElementById("memberPrice").value);
        formData.append("notMemberPrice", document.getElementById("notMemberPrice").value);
        document.getElementsByName("contact").forEach(
            (item, index) => {
                if (item.checked) {
                    formData.append("contactArray", item.value)
                }
            })
        formData.append("content", document.getElementById("content").value);
        formData.append("teacherName", document.getElementById("teacherName").value);
        formData.append("teacherDescription", document.getElementById("teacherDescription").value);
        formData.append("memo", document.getElementById("memo").value);
        formData.append("classHour", document.getElementById("classHour").value);
        formData.append("attachmentFile", document.getElementById("attachment").files[0]);
        formData.append("topAtCourse", false);
        let info = ""
        if (!formData.get("id")) {
            info = "請輸入課程編號"
        } else if (!formData.get("address")) {
            info = "請輸入課程地址"
        } else if (!formData.get("startClassTime")) {
            info = "請輸入開課日期"
        } else if (!formData.get("endClassTime")) {
            info = "請輸入開課結束日期"
        } else if (!formData.get("name")) {
            info = "請輸入課程名稱"
        } else if (!formData.get("teacherName")) {
            info = "請輸入講課講師"
        } else if (!formData.get("teacherDescription")) {
            info = "請輸入講師簡歷"
        } else if (!formData.get("classHour")) {
            info = "請輸入上課時數"
        } else if (!formData.get("content")) {
            info = "請輸入課程內容"
        } else if (!formData.get("deadLine")) {
            info = "請輸入報名截止日期"
        } else if (!formData.get("studentCount")) {
            info = "請輸入課程人數"
        } else if (!formData.get("memberPrice")) {
            info = "請輸入會員費用"
        } else if (!formData.get("notMemberPrice")) {
            info = "請輸入非會員費用"
        } else if (!formData.get("contactArray")) {
            info = "請選擇聯絡人"
        } else {
            if (this.type === 0) {
                trackPromise(
                    TrainRequest.createLecture(formData, formData.get("name"))
                        .then(response => {
                            this.setState({"showSend": !this.state.showSend})
                            this.showInfo("新增成功")
                            window.location.href = window.location.href.split("/create")[0];
                        })
                        .catch(error => {
                            this.setState({"showSend": !this.state.showSend})
                            this.showInfo("新增失敗")
                            document.getElementById(this.errorId).innerHTML = error.message
                            console.log(error.message)
                        })
                )
            } else if (this.type === 3) {
                trackPromise(
                    TrainRequest.createOther(formData, formData.get("name"))
                        .then(response => {
                            this.setState({"showSend": !this.state.showSend})
                            this.showInfo("新增成功")
                            window.location.href = window.location.href.split("/create")[0];
                        })
                        .catch(error => {
                            this.setState({"showSend": !this.state.showSend})
                            this.showInfo("新增失敗")
                            document.getElementById(this.errorId).innerHTML = error.message
                            console.log(error.message)
                        })
                )
            }

        }
        if (info) {
            this.showInfo(info)
            this.showSend()
        }
    }

    render() {
        let lectureItem = [
            {"title": "課程編號", "require": true, "input": (<><input type="text" id="id"/></>)},
            {"title": "課程名稱", "require": true, "input": (<><input id="name"/></>)},
            {"title": "上課地點", "require": true, "input": (<><input type="text" id="address"/></>)},
            {
                "title": "開課日期",
                "require": true,
                "input": (<><input type="date" id="startClassTime"/></>)
            },
            {
                "title": "開課結束日期",
                "require": true,
                "input": (<><input type="date" id="endClassTime"/></>)
            },
            {"title": "報名截止日期", "require": true, "input": (<><input type="date" id="deadLine"/></>)},
            {"title": "上課時數", "require": true, "input": (<><input type="number" id="classHour"/></>)},
            {"title": "課程人數", "require": true, "input": (<><input type="number" id="studentCount"/></>)},
            {"title": "會員費用", "require": true, "input": (<><input type="number" id="memberPrice"/></>)},
            {"title": "非會員費用", "require": true, "input": (<><input type="number" id="notMemberPrice"/></>)},
            {"title": "授課講師", "require": true, "input": (<><input type="text" id="teacherName"/></>)},
            {"title": "講師簡歷", "require": true, "input": (<><input type="text" id="teacherDescription"/></>)},
            {"title": "課程內容", "require": true, "input": (<><textarea id="content"/></>)},
            {"title": "注意事項", "require": false, "input": (<><textarea id="memo"/></>)},
            {"title": "簡章上傳", "require": false, "input": (<><input type="file" id="attachment"/></>)},
            {"title": "是否置頂於首頁", "require": false, "input": (<><input type="checkbox" id="top"/></>)},
            {"title": "是否置頂於課程頁面", "require": false, "input": (<><input type="checkbox" id="topAtCourse"/></>)},
            {
                "title": "聯絡人", "require": true, "input": (<>{this.state.contact.map((placement, index) => {
                    return (<div key={index}>
                        <input type="checkbox" value={placement.email} id={`contact${index}`} name="contact"/>
                        <label for={`contact${index}`}>{placement.name}</label>
                    </div>)
                })}</>)
            }
        ]

        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage">
                        <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                        <DynamicFormInput data={lectureItem}/>
                        <Row className="justify-content-end">
                            <Col sm={"auto"}>
                                <Button variant="success" onClick={this.showSend}>送出資料</Button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.showSend} close={() => {
                            this.showSend()
                        }} ok={() => {
                            this.send()
                        }} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => {
                            this.showInfo()
                        }} close={() => {
                            this.showInfo()
                        }} message={this.state.infoMessage} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}


export const addLecture = {
    routeProps: {
        path: "/Education-And-Train/Lecture/create",
        component: AddLecture
    },
    name: "新增講習課程"
}

export const addOtherLecture = {
    routeProps: {
        path: "/Education-And-Train/other/create",
        component: AddLecture
    },
    name: "新增其他課程"
}
