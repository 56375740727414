import React from "react";
import {Button, Col, Row} from "react-bootstrap"

import BreadcrumbObject from "../../../../js/breadcrumb"
import {trackPromise} from "react-promise-tracker";
import {TrainRequest} from "../../../../js/request/trainRequest"
import {DynamicFormInput} from "../../../../js/formInput"

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../css/main.css";
import {ModalBase, ModalSureSend} from "../../../../js/modal"

class EditCourse extends React.Component {
    type = 0;
    errorPage = "addLecture"
    errorId = `${this.errorPage}ErrorInfo`
    courseID = ""
    courseName = []

    constructor(props) {
        super(props)
        this.state = {
            "pageName": "",
            "sureSend": false,
            "showInfo": false,
            "infoMessage": "",
            "lectureItem": {},
            "formData": new FormData(),
            "osf": [],
            "courseContentArray": [],
            "courseMemoArray": [],
            "isTop": false,
            "isTopAtCourse": false,
            "safetyContent": [],
            "safetyMemo": [],
            "courseName": [],
            "courseNameIndex": 0,
            "isMemoChange": false,
            "memoFirstChange": false,
        }
    }

    componentDidMount = () => {
        this.checkType()
        this.courseID = this.props.match.params.id
        this.getDetail()
    }

    checkType = () => {
        switch (window.location.href.split("edit/")[0].split("Education-And-Train")[1]) {
            case "/Lecture/":
                this.setState({"pageName": "講習課程"});
                this.type = 0;
                break;
            case "/Occupational-Safety-And-Health/":
                this.setState({"pageName": "職安衛課程"});
                this.type = 1;
                break;
            case "/other/":
                this.setState({"pageName": "其他課程"});
                this.type = 3;
                break;
            default:
                break;

        }
    }

    getDetail = () => {
        trackPromise(
            TrainRequest.getDetail(this.courseID)
                .then(response => {
                    response.data.startClassTime = this.dateformmat(response.data.startClassTime)
                    response.data.endClassTime = this.dateformmat(response.data.endClassTime)
                    response.data.deadLine = this.dateformmat(response.data.deadLine)
                    this.setState({
                        "lectureItem": response.data,
                        "isTop": response.data.top,
                        "isTopAtCourse": response.data.topAtCourse,
                        "courseContentArray": response.data.courseContentArray.join(`\r\n`),
                        "courseMemoArray": response.data.courseMemoArray.join(`\r\n`),
                        "courseNameIndex": response.data.templateId - 1
                    })
                    this.getOSFContentAndMemo(this.state.courseNameIndex + 1)
                }).catch(error => console.log(error))
        )
        if (this.type === 1) {
            trackPromise(
                TrainRequest.getOSF().then(response => {
                    // eslint-disable-next-line array-callback-return
                    response.data.map((placement) => {
                        this.courseName.push(placement.name)
                    })
                    this.setState({"courseName": this.courseName})
                    this.setState({"osf": response.data})
                })
            )
        }

    }

    getOSFContentAndMemo = (id) => {
        trackPromise(
            TrainRequest.getOSFContent(id)
                .then(response => {
                    this.setState({"safetyContent": response.data})
                })
        )
        trackPromise(
            TrainRequest.getOSFMemo(id)
                .then(response => {
                    this.setState({"safetyMemo": response.data.courseMemoArray})
                })
        )
    }

    dateformmat = (data) => {
        if (data) {
            let format = data.split("/")
            if (format[1].length === 1) format[1] = `0${format[1]}`
            if (format[2].length === 1) format[2] = `0${format[2]}`
            return `${format[0]}-${format[1]}-${format[2]}`
        } else {
            return ""
        }
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showSend = () => {
        this.setState({"showSend": !this.state.showSend})
    }

    handleValueChange = (prop) => {
        let target = prop.target;
        let name = target.name;
        let value = target.value;
        this.sendFormData(name, value)
        let copystate = this.state.lectureItem
        copystate[name] = value
        this.setState({"lectureItem": copystate})
    }

    categoryChange = (event) => {
        this.setState({"courseNameIndex": event.target.value - 1})
        this.getOSFContentAndMemo(event.target.value)
        this.sendFormData(event.target.name, event.target.value)
    }

    handleContentValueChange = (event) => {
        this.setState({"courseContentArray": event.target.value})
        this.sendFormData(event.target.name, event.target.value)
    }

    handleMemoValueChange = (event) => {
        this.setState({"memoFirstChange": true})
        this.setState({"courseMemoArray": event.target.value})
        this.sendFormData(event.target.name, event.target.value)
    }

    sendFormData = (name, value) => {
        let copyFormData = this.state.formData
        copyFormData.set(name, value)
        this.setState({"formData": copyFormData})
    }

    handleTopChange = () => {
        this.setState({isTop: !this.state.isTop})
        this.sendFormData("top", document.getElementById("top").checked)
    }

    TopAtCourseChange = () => {
        this.setState({isTopAtCourse: !this.state.isTopAtCourse})
        this.sendFormData("topAtCourse", document.getElementById("topAtCourse").checked)
    }


    handleFileChange = () => {
        this.sendFormData("attachmentFile", document.getElementById("attachmentFile").files[0])
    }

    send = () => {
        if (this.type === 0) {
            if (!this.state.memoFirstChange) {
                this.sendFormData("memo", this.state.courseMemoArray)
            }
            trackPromise(
                TrainRequest.editLecture(this.courseID, this.state.formData)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯成功")
                        this.redirectTo()
                    })
                    .catch(error => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )
        } else if (this.type === 1) {
            trackPromise(
                TrainRequest.editOSF(this.courseID, this.state.formData)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯成功")
                        this.redirectTo()
                    })
                    .catch(error => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )

        } else if (this.type === 3) {
            if (!this.state.memoFirstChange) {
                this.sendFormData("memo", this.state.courseMemoArray)
            }
            trackPromise(
                TrainRequest.editOther(this.courseID, this.state.formData)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯成功")
                        this.redirectTo()
                    })
                    .catch(error => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("編輯失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )
        }

    }

    redirectTo = () => {
        window.location.href = window.location.href.split("/edit")[0] + "/maintain";
    }

    render() {
        let lectureAndOtherItem = [
            {
                "title": "課程編號", "require": false, "input": (
                    <>{this.state.lectureItem.id || ""}</>)
            },
            {
                "title": "課程名稱", "require": true, "input": (
                    <><input type="text" id="name" name="name"
                             value={this.state.lectureItem.name || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "上課地點", "require": true, "input": (
                    <><input type="text" id="address" name="address"
                             value={this.state.lectureItem.address || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "開課日期",
                "require": true,
                "input": (
                    <><input type="date" id="startClassTime" name="startClassTime"
                             value={this.state.lectureItem.startClassTime || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "開課結束日期", "require": true, "input": (
                    <><input type="date" id="endClassTime" name="endClassTime"
                             value={this.state.lectureItem.endClassTime || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "報名截止日期", "require": true, "input": (
                    <><input type="date" id="deadLine" name="deadLine"
                             value={this.state.lectureItem.deadLine || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "上課時數", "require": true, "input": (
                    <><input type="number" id="classHour" name="classHour"
                             value={this.state.lectureItem.classHour || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "課程人數", "require": true, "input": (
                    <><input type="number" id="studentCount" name="studentCount"
                             value={this.state.lectureItem.studentCount || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "會員費用", "require": true, "input": (
                    <><input type="number" id="memberPrice" name="memberPrice"
                             value={this.state.lectureItem.memberPrice || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "非會員費用", "require": true, "input": (
                    <><input type="number" id="notMemberPrice" name="notMemberPrice"
                             value={this.state.lectureItem.notMemberPrice || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "講課講師", "require": true, "input": (
                    <><input type="text" id="teacherName" name="teacherName"
                             value={this.state.lectureItem.teacherName || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "講師簡歷",
                "require": true,
                "input": (
                    <><input type="text" id="teacherDescription" name="teacherDescription"
                             value={this.state.lectureItem.teacherDescription || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "課程內容", "require": true, "input": (
                    <><textarea type="text" id="content" name="content"
                                value={this.state.courseContentArray || ""}
                                onChange={this.handleContentValueChange}/></>)
            },
            {
                "title": "注意事項", "require": false, "input": (
                    <><textarea type="text" id="memo" name="memo"
                                value={this.state.courseMemoArray || ""}
                                onChange={this.handleMemoValueChange}/></>)
            },
            {
                "title": "簡章上傳", "require": false, "input": (
                    <><input type="file" id="attachmentFile" onChange={this.handleFileChange}/>
                        <p style={{marginTop: "1%"}}>如未上傳新簡章，則將繼續沿用上次上傳的簡章</p></>)
            },
            {
                "title": "是否置頂於首頁", "require": false, "input": (
                    <><input type="checkbox" id="top" name="top"
                             checked={this.state.isTop || ""}
                             onChange={this.handleTopChange}/></>)
            },
            {
                "title": "是否置頂於課程頁面", "require": false, "input": (
                    <><input type="checkbox" id="topAtCourse" name="topAtCourse"
                             checked={this.state.isTopAtCourse || ""}
                             onChange={this.TopAtCourseChange}/></>)
            }
        ]
        let oSHItem = [
            {
                "title": "課程編號", "require": false, "input": (<>{this.state.lectureItem.id || ""} </>)
            },
            {
                "title": "課程名稱", "require": true, "input": (<>
                    <select id="name" onChange={this.categoryChange} name="templateId">{
                        this.state.osf.map((placement, index) => {
                            return (<option key={index} value={placement.templateId}
                                            selected={placement.templateId === this.state.lectureItem.templateId ? "selected" : ""}>{placement.name}</option>)
                        })
                    }</select>
                </>)
            },
            {
                "title": "上課地點", "require": true, "input": (
                    <><input type="text" id="address" name="address"
                             value={this.state.lectureItem.address || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "開課日期",
                "require": false,
                "input": (
                    <><input type="date" id="startClassTime" name="startClassTime"
                             value={this.state.lectureItem.startClassTime || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "預計開課日期",
                "require": false,
                "input": (<><input type="text" id="expectedStartClassTime" name="expectedStartClassTime"
                                   value={this.state.lectureItem.expectedStartClassTime || ""}
                                   onChange={this.handleValueChange}/></>)
            },

            {
                "title": "開課結束日期",
                "require": false,
                "input": (
                    <><input type="date" id="endClassTime" name="endClassTime"
                             value={this.state.lectureItem.endClassTime || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "報名截止日期", "require": true, "input": (
                    <><input type="date" id="deadLine" name="deadLine"
                             value={this.state.lectureItem.deadLine || ""}
                             onChange={this.handleValueChange}/></>)
            },

            {
                "title": "課程人數", "require": true, "input": (
                    <><input type="number" id="studentCount" name="studentCount"
                             value={this.state.lectureItem.studentCount || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "會員費用", "require": true, "input": (
                    <><input type="number" id="memberPrice" name="memberPrice"
                             value={this.state.lectureItem.memberPrice || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "非會員費用",
                "require": true,
                "input": (
                    <><input type="number" id="notMemberPrice" name="notMemberPrice"
                             value={this.state.lectureItem.notMemberPrice || ""}
                             onChange={this.handleValueChange}/></>)
            },
            {
                "title": "是否置頂於首頁", "require": false, "input": (
                    <><input type="checkbox" id="top" name="top"
                             checked={this.state.isTop || ""}
                             onChange={this.handleTopChange}/></>)
            },
            {
                "title": "是否置頂於課程頁面", "require": false, "input": (
                    <><input type="checkbox" id="topAtCourse" name="topAtCourse"
                             checked={this.state.isTopAtCourse || ""}
                             onChange={this.TopAtCourseChange}/></>)
            }

        ]
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage">
                        <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                        <DynamicFormInput
                            data={this.type === 0 || this.type === 3 ? lectureAndOtherItem : this.type === 1 ? oSHItem : ""}/>
                        {this.type === 1 ?
                            <Row>
                                <Col xs={6} className="table-responsive"><b>課程內容</b>
                                    <table className="table oSFCourseContent">
                                        <thead>
                                        <tr>
                                            <td colSpan={2}>{this.state.courseName[this.state.courseNameIndex]}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.safetyContent.map((placement, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}. {placement.content}</td>
                                                    <td style={{textAlign: "center"}}>{placement.hour}hr</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={6}><b>注意事項</b>
                                    <table className="oSFCourseContent">
                                        <thead>
                                        <tr>
                                            <td colSpan={2}>{this.state.courseName[this.state.courseNameIndex]}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{this.state.safetyMemo.map((placement, index) => {
                                                return (
                                                    <p key={index}>
                                                        {placement}
                                                    </p>
                                                )
                                            })}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            : <></>}
                        <Row className="justify-content-end">
                            <Col sm={"auto"}>
                                <Button variant="success" onClick={this.showSend}>送出資料</Button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.showSend} close={() => {
                            this.showSend()
                        }} ok={() => {
                            this.send()
                        }} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} close={() => {
                            this.showInfo()
                        }} ok={() => {
                            this.showInfo()
                        }} message={this.state.infoMessage} error={this.errorPage}> </ModalBase>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}


export const editLecture = {
    routeProps: {
        path: "/Education-And-Train/Lecture/edit/:id",
        component: EditCourse
    },
    name: "編輯講習課程"
}

export const editOSH = {
    routeProps: {
        path: "/Education-And-Train/Occupational-Safety-And-Health/edit/:id",
        component: EditCourse
    },
    name: "編輯職安衛課程"
}

export const editOtherLecture = {
    routeProps: {
        path: "/Education-And-Train/other/edit/:id",
        component: EditCourse
    },
    name: "編輯其他課程"
}
