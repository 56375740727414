import React from "react";
import BreadcrumbObject from "../../../../js/breadcrumb";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CommitteeRequest } from "../../../../js/request/committeeRequest";
import { trackPromise } from "react-promise-tracker";

// import content01 from "../../../../img/content01.jpg";

class Committee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token"),
            info: "",
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        };
    }

    redirectTo = () => {
        window.location.href = `/committee/create`;
    }

    componentDidMount() {
        trackPromise(
            CommitteeRequest.searchList()
                .then(response => {
                    this.setState({ responseData: response.data })
                })
        )
    }

    render() {

        return (
            <>
                { this.state.isLogin && this.state.isAdmin ?
                    <Row className="controlButtons">
                        <Col sm={ "auto" }>
                            <Button className="edit kanEdit" variant="success"
                                    onClick={ this.redirectTo }>新增委員會</Button>
                        </Col>
                    </Row> : <></> }
                <BreadcrumbObject secondTitle={ "專業委員會" }/>
                <Row className="subgroup">
                    { this.state.responseData &&
                        this.state.responseData.map((data, index) => {
                            return (
                                <Col key={ index } sm={ 6 } lg={ 4 } className="subgroupContent">
                                    <Link to={ this.state.responseData &&
                                        "committee/detail/" + data.id }>
                                        <img src={ data.coverImage } alt=""/>
                                        <div>{ data.title }</div>
                                    </Link>
                                </Col>
                            )
                        }) }
                </Row>
            </>
        );
    }
}

export default {
    routeProps: {
        path: "/committee",
        component: Committee
    },
    name: "專業委員會",
}
