import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Place extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.place.id,
            editorTypeName: aboutType.place.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "會員服務" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}


export default {
    routeProps: {
        path: "/place",
        component: Place
    },
    name: "會議場地租借",
}
