export default {
    vision: {
        id: 1,
        name: "宗旨與願景"
    },
    hall: {
        id: 2,
        name: "會館介紹"
    },
    organization: {
        id: 3,
        name: "組織架構"
    },
    chairman: {
        id: 4,
        name: "歷屆理事長"
    },
    supervisor: {
        id: 5,
        name: "理監事"
    },
    subgroup: {
        id: 6,
        name: "次團介紹"
    },
    committee: {
        id: 7,
        name: "專業委員會"
    },
    clubStaff: {
        id: 8,
        name: "會務人員"
    },
    traffic: {
        id: 9,
        name: "交通方式"
    },
    serviceList: {
        id: 10,
        name: "新北市工業會服務項目"
    },
    place: {
        id: 11,
        name: "場地租借"
    },
    recruit: {
        id: 12,
        name: "人才招募"
    },
    legal: {
        id: 13,
        name: "法律服務"
    },
    join: {
        id: 14,
        name: "我要入會"
    },
    policy: {
        id: 15,
        name: "訓練政策"
    },
    links: {
        id: 16,
        name: "相關連結"
    },
    propertyInvestment: {
        id: 17,
        name: "產投課程"
    },
    supervisorChairman: {
        id: 18,
        name: "理事長/名、榮譽理事長",
    },
    supervisorDirector: {
        id: 19,
        name: "常務理事/理事/候補理事",
    },
    supervisorStanding : {
        id: 20,
        name: "常務監事/監事/候補監事",
    },
    supervisorAdviser: {
        id: 21,
        name: "會務顧問/法律顧問",
    },

}