import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Organization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.organization.id,
            editorTypeName: aboutType.organization.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "關於本會" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}

export default {
    routeProps: {
        path: "/organization",
        component: Organization
    },
    name: "組織架構",
}
