import React from "react";
import {Row, Col, Button} from "react-bootstrap"
import {Link} from "react-router-dom";
import BreadcrumbObject from "../../../js/breadcrumb"
import {ActivityRequest} from "../../../js/request/activityRequest";
import {trackPromise} from "react-promise-tracker";
import {
    ModalBase,
    ModalAddCategory,
    ModalModifyCategory,
    ModalDeleteCategory,
    ModalDeleteAlbum
} from "../../../js/modal.js"
import {Paging} from "../../../js/pagination"
import previousPage from "../../../../src/img/icon/reply-24px.svg"

class Activity extends React.Component {
    errorPage = "activity"
    errorId = `${this.errorPage}ErrorInfo`
    imgData = []
    pageTotal = 0
    category = [{"name": "全部", "value": -1}]
    size = 9

    constructor(props) {
        super(props)
        this.state = {
            "isLogin": localStorage.getItem("token") ? true : false,
            "isAdmin": localStorage.getItem("identity") === "admin" ? true : false,
            "pageTotal": 0,
            "nowPage": parseInt(window.location.href.split("activity/")[1].split("/")[1]),
            "categoryId": parseInt(window.location.href.split("activity/")[1].split("/")[0]),
            "category": this.category,
            "showAddCategory": false,
            "showModifyCategory": false,
            "showDeleteCategory": false,
            "showDeleteAlbum": false,
            "showInfo": false,
            "isAlbumManage": true,
            "isAlbumManageBtn": false,
            "isCategoryManage": true,
            "isCategoryManageBtn": false,
            "imgData": [],
            "selectDeleteImgData": []
        }
    }

    componentDidMount = () => {
        this.getTotalPage(this.state.categoryId)
        this.getActivity(this.state.categoryId, this.state.nowPage)
        this.getCategory()
        this.getSelectDeleteImgData()
    }

    getTotalPage = (categoryId) => {
        trackPromise(ActivityRequest.getTotalPage({
            "size": this.size,
            "categoryId": categoryId
        }).then(response => {
            this.setState({"pageTotal": response.data.totalPage, "nowPage": 1})
        }).catch(error => {

        }))
    }

    getActivity = (nowCategory, nowPage) => {
        trackPromise(
            ActivityRequest.getActivityPageCategory(nowCategory, nowPage, {
                "count": this.size,
                "page": nowPage
            }).then(response => {
                this.setState({"imgData": response.data})
            }).catch(error => {
            })
        )
    }

    getCategory = () => {
        trackPromise(
            ActivityRequest.getCategory().then(response => {
                response.data.forEach(placement => {
                    this.category.push({"name": placement.category, "value": placement.id})
                })
                this.setState({"category": this.category})
            }).catch(error => {
            })
        )
    }

    categoryChange = () => {
        let value = document.getElementById("category")
        let categoryId = value.options[value.selectedIndex].value
        this.getTotalPage(categoryId)
        this.setState({categoryId: categoryId})
        window.location.href = window.location.href.split("activity/")[0] + "activity/" + categoryId + "/" + 1
        let data = {
            "categoryId": value.options[value.selectedIndex].value,
            "count": this.size,
            "page": 1
        }
        trackPromise(
            ActivityRequest.getActivityPageCategory(value.options[value.selectedIndex].value, 1, data).then(response => {
                this.setState({
                    "imgData": response.data,
                    "nowPage": 1,
                })
            }).catch(error => {
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        window.location.href = window.location.href.split("activity/")[0] + "activity/" + this.state.categoryId + "/" + now
        this.getActivity(this.state.categoryId, now);
    }

    getSelectDeleteImgData = () => {
        trackPromise(
            ActivityRequest.getActivityPageCategory(-1, this.state.nowPage).then(response => {
                this.setState({"selectDeleteImgData": response.data})
            }).catch(error => {
            })
        )
    }

    redirectToAdd = () => {
        const path = window.location.href.split(`activity`)[0] + "activity/create"
        window.location.href = path;
    }

    showAddCategory = () => {
        this.setState({"showAddCategory": !this.state.showAddCategory})
    }

    showModifyCategory = () => {
        this.setState({"showModifyCategory": !this.state.showModifyCategory})
    }

    showDeleteCategory = () => {
        this.setState({"showDeleteCategory": !this.state.showDeleteCategory})
    }

    showDeleteAlbum = () => {
        this.setState({"showDeleteAlbum": !this.state.showDeleteAlbum})
    }

    addCategory = () => {
        let catagory = {"categoryTitle": document.getElementById("addCatagory").value}
        if (!catagory.categoryTitle) {
            this.showInfo("請輸入相簿類別")
        } else {
            trackPromise(
                ActivityRequest.addCatagory(catagory).then(response => {
                    this.setState({"showAddCategory": !this.state.showAddCategory})
                    this.showInfo("新增成功")
                    window.location.reload();
                }).catch(error => {
                    this.setState({"showAddCategory": !this.state.showAddCategory})
                    this.showInfo("新增失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                })
            )
        }
    }

    modifyCategory = () => {
        let value = document.getElementById("modifyCategory")
        let id = value.options[value.selectedIndex].value
        let category = {
            "title": document.getElementById("afterModifyCategory").value
        }
        if (!category.title) {
            this.showInfo("請輸入類別名稱")
        } else {
            trackPromise(
                ActivityRequest.patchCategory(id, category).then(response => {
                    this.setState({"showModifyCategory": !this.state.showModifyCategory})
                    this.showInfo("修改成功")
                    window.location.reload();
                }).catch(error => {
                    this.setState({"showModifyCategory": !this.state.showModifyCategory})
                    this.showInfo("修改失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                })
            )
        }
    }

    deleteCategory = () => {
        let value = document.getElementById("deleteCategory")
        let id = value.options[value.selectedIndex].value
        trackPromise(
            ActivityRequest.deleteCategory(id).then(response => {
                this.setState({"showDeleteCategory": !this.state.showDeleteCategory})
                this.showInfo("刪除成功")
                window.location.reload();
            }).catch(error => {
                this.setState({"showDeleteCategory": !this.state.showDeleteCategory})
                this.showInfo("刪除失敗")
                document.getElementById(this.errorId).innerHTML = error.message
            })
        )
    }

    deleteAlbum = () => {
        let select = document.getElementsByName("deleteAlbumSelect")
        let deleteSelect = []
        let checkSelect = false
        for (let i = 0; i < select.length; i++) {
            if (select[i].checked) {
                deleteSelect.push(i)
                checkSelect = true
            }
        }
        if (checkSelect) {
            let photo = []
            deleteSelect.forEach(placement => {
                photo.push(this.state.selectDeleteImgData[placement].id)
            })
            let data = {
                "albumIdArray": photo
            }
            trackPromise(
                ActivityRequest.deleteAlbum(data)
                    .then(response => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("刪除成功")
                        window.location.reload()
                    })
                    .catch(error => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("刪除失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                    })
            )

        } else {
            this.showInfo("請選擇要刪除的相簿")
        }
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showAlbumManage = () => {
        this.setState({"isAlbumManage": !this.state.isAlbumManage})
        this.setState({"isAlbumManageBtn": !this.state.isAlbumManageBtn})
        this.setState({"isCategoryManage": !this.state.isCategoryManage})
    }

    showCategoryManage = () => {
        this.setState({"isCategoryManage": !this.state.isCategoryManage})
        this.setState({"isCategoryManageBtn": !this.state.isCategoryManageBtn})
        this.setState({"isAlbumManage": !this.state.isAlbumManage})
    }

    render() {
        return (
            <div className="activity">
                <Row className="align-items-center controlButtons">
                    <Col xs={"auto"} className="label">相簿展示</Col>
                    <Col xs={"auto"}>
                        <select onChange={this.categoryChange} id="category">
                            {this.state.category.map((placement, index) => {
                                if (placement.value === this.state.categoryId) {
                                    return (
                                        <option selected value={placement.value} key={index}>{placement.name}</option>)
                                } else {
                                    return (<option value={placement.value} key={index}>{placement.name}</option>)
                                }
                            })}
                        </select>
                    </Col>
                    {this.state.isLogin && this.state.isAdmin && this.state.isAlbumManage ?
                        <>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editBlue"
                                        onClick={this.showAlbumManage}>相簿管理</Button>
                            </Col>
                        </>
                        :
                        this.state.isAlbumManageBtn ?
                            <>
                                <img src={previousPage} alt="" onClick={this.showAlbumManage}
                                     style={{cursor: "pointer"}}/>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit" variant="success"
                                            onClick={this.redirectToAdd}>新增相簿</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit editRed" variant="danger" onClick={() => {
                                        this.showDeleteAlbum()
                                    }}>刪除相簿</Button>
                                </Col>
                            </>
                            :
                            <></>
                    }
                    {this.state.isLogin && this.state.isAdmin && this.state.isCategoryManage ?
                        <>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editBlue"
                                        onClick={this.showCategoryManage}>類別管理</Button>
                            </Col>
                        </>
                        :
                        this.state.isCategoryManageBtn ?
                            <>
                                <img src={previousPage} alt="" onClick={this.showCategoryManage}
                                     style={{cursor: "pointer"}}/>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit" variant="success" onClick={() => {
                                        this.showAddCategory()
                                    }}>新增類別</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit editBlue" onClick={() => {
                                        this.showModifyCategory()
                                    }}>修改類別</Button>
                                </Col>
                                <Col xs={"auto"}>
                                    <Button className="edit kanEdit editRed" variant="danger" onClick={() => {
                                        this.showDeleteCategory()
                                    }}>刪除類別</Button>
                                </Col>
                            </>
                            : <></>
                    }
                </Row>
                <BreadcrumbObject secondTitle={"活動花絮"}/>
                <Row className="activityImgs">
                    {this.state.imgData.map((placement, index) => {
                        return (<Col sm={6} lg={4} key={index}>
                            <Link to={"/album/" + placement.id}>
                                <div className="outImg"><img src={placement.cover} alt=""/></div>
                                <center className="ellipsis">{placement.title}</center>
                            </Link>
                        </Col>)
                    })}
                </Row>
                <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange} nowPage={this.state.nowPage}/>
                <ModalAddCategory addShow={this.state.showAddCategory} addClose={() => {
                    this.showAddCategory()
                }} addOk={() => {
                    this.addCategory()
                }}/>
                <ModalModifyCategory modifyShow={this.state.showModifyCategory} modifyClose={() => {
                    this.showModifyCategory()
                }} modifyOk={() => {
                    this.modifyCategory()
                }} modifyCategory={this.state.category}/>
                <ModalDeleteCategory deleteShow={this.state.showDeleteCategory} deleteClose={() => {
                    this.showDeleteCategory()
                }} deleteOk={() => {
                    this.deleteCategory()
                }} deleteCategory={this.state.category}/>
                <ModalDeleteAlbum deleteShow={this.state.showDeleteAlbum} deleteClose={() => {
                    this.showDeleteAlbum()
                }} deleteOk={() => {
                    this.deleteAlbum()
                }} deleteAlbum={this.state.selectDeleteImgData}/>
                <ModalBase show={this.state.showInfo} close={() => {
                    this.showInfo()
                }} message={this.state.infoMessage} ok={() => {
                    this.showInfo()
                }} error={this.errorPage}/>
            </div>
        );
    }
}

export const activity = {
    routeProps: {
        path: "/activity/:id/:id",
        component: Activity
    },
    name: "活動花絮"
}
