import React from "react";
import {Col} from "react-bootstrap";

import {AnnouncementRequest} from "../../../js/request/announcementRequest";
import {DisplayList} from "../../../js/displayList";

class Announcement extends React.Component {
    render() {
        return (
            <>
                <DisplayList secondTitle={"會務公告"} thirdTitle={""} createButtonTitle={"新增公告"}
                             maintainButtonTitle={"公告維護"}
                             subLabel={
                                 <>
                                     <Col style={{textAlign: "left"}}>主旨</Col>
                                     <Col xs={3} sm={2} style={{textAlign: "right"}}>公告日期</Col>
                                 </>
                             }
                             getSize={AnnouncementRequest.getSize} searchList={AnnouncementRequest.searchList}/>
            </>
        );
    }
}


export default {
    routeProps: {
        path: "/announcement",
        component: Announcement
    },
    name: "會務公告",
}
