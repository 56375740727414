import React from "react";
import {Col, Row} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import BreadcrumbObject from "../../../js/breadcrumb"
import {AnnouncementRequest} from "../../../js/request/announcementRequest";
import {trackPromise} from "react-promise-tracker";
import {EditorTool} from "../../../js/editorTool";
import {ModalBase, ModalSureSend} from "../../../js/modal";


class AnnouncementEdit extends React.Component {
    errorPage = "announcement"
    errorId = `${this.errorPage}ErrorInfo`
    size = 11;

    constructor(props) {
        super(props);
        this.state = {
            id: window.location.href.split("edit/")[1],
            identity: false,
            isEditorOpen: false,
            info: "",
            sureSend: false,
            showInfo: false,
            infoMessage: "",
            isTop: false,
            title: "",
        };
    }

    componentDidMount() {
        this.getEdit()
    }

    componentDidUpdate = () => {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity === "admin") {
            if (!this.state.identity) {
                this.setState({"identity": "true"})
            }
        }
    }

    handleModalShow = (content) => {
        this.setState({modalTitle: content});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    handleEditorOpen = () => {
        this.setState(prevstate => ({isEditorOpen: !prevstate.isEditorOpen}))
    }

    getEdit = () => {
        trackPromise(
            AnnouncementRequest.getEdit(this.state.id).then(response => {
                this.setState({
                    isTop: response.data.top,
                    title: response.data.title,
                    info: response.data.content
                })
            }).catch(error => {
            })
        )
    }

    patchEdit = (toSend) => {
        if (toSend && this.state.sureSend) {
            trackPromise(
                AnnouncementRequest.patchEdit(this.state.id,
                    {
                        "top": document.getElementById("top").checked,
                        "title": document.getElementById("title").value,
                        "content": this.state.info
                    }
                ).then(response => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("修改成功")
                    this.redirectTo()
                }).catch(error => {
                    this.setState({"sureSend": !this.state.sureSend})
                    this.showInfo("修改失敗")
                    document.getElementById("errorInfo").innerHTML = error.message
                })
            )
        } else {
            this.setState({"sureSend": !this.state.sureSend})
        }
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    handleTopChange = () => {
        this.setState({isTop: !this.state.isTop})
    }

    handleTitleChange = (event) => {
        this.setState({title: event.target.value})
    }

    redirectTo = () => {
        window.location.href = window.location.href.split("/edit")[0] + "/maintain";
    }

    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div>
                        <BreadcrumbObject secondTitle={"會務公告"} thirdTitle={"編輯公告"}/>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2}>是否置頂</Col>
                            <Col><input id="top" type="checkbox" required checked={this.state.isTop}
                                        onChange={this.handleTopChange}/></Col>
                        </Row>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2} className="addRedStar">主旨</Col>
                            <Col><input className="title_input" id="title" type="text" required
                                        value={this.state.title} onChange={this.handleTitleChange}/></Col>
                        </Row>
                        <div className="editor-content">
                            {this.state.isEditorOpen ?
                                (
                                    <>
                                        <button className="edit kanEdit ckeditorBtn"
                                                onClick={this.handleEditorOpen}>切換
                                        </button>
                                        <EditorTool accessText={(text) => {
                                            this.setState({
                                                info: text,
                                            })
                                        }}
                                                    outputHTML={this.state.info}/>
                                    </>
                                ) :
                                (
                                    <>
                                        <button className="edit kanEdit ckeditorBtn"
                                                onClick={this.handleEditorOpen}>編輯
                                        </button>
                                        <div className={this.state.identity ? "" : "ckeditorDiv"}
                                             dangerouslySetInnerHTML={{__html: this.state.info}}/>
                                    </>
                                )
                            }
                        </div>

                        <Row style={{paddingTop: "5%"}}>
                            <Col xs={2} style={{margin: "0 auto"}}>
                                <button className="kanEdit ckeditorBtn btn btn-success"
                                        onClick={() => this.patchEdit(false)}>送出
                                </button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.sureSend} close={() => this.patchEdit(false)}
                                       ok={() => this.patchEdit(true)} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                                   message={this.state.infoMessage} error={this.errorPage}/>
                    </div>
                    : this.props.history.push("/")
                }
            </>);
    }
}

export default {
    routeProps: {
        path: "/announcement/edit/:id",
        component: AnnouncementEdit
    },
    name: "編輯公告"
}






