import React from "react";
import {Button, Col, Row} from "react-bootstrap"
import BreadcrumbObject from "../../../js/breadcrumb"
import {trackPromise} from 'react-promise-tracker';
import {TrainRequest} from "../../../js/request/trainRequest"
import {DynamicFormInput} from "../../../js/formInput"
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../css/main.css";
import {ModalBase, ModalSureSend} from "../../../js/modal"

class AddLecture extends React.Component {
    errorPage = "addLecture"
    errorId = `${this.errorPage}ErrorInfo`
    type = 1;
    courseName = []

    constructor(props) {
        super(props)
        this.state = {
            "pageName": "職安衛訓練",
            "sureSend": false,
            "showInfo": false,
            "infoMessage": "",
            "contact": [],
            "osf": [],
            "templateId": -1,
            "safetyContent": [],
            "safetyMemo": [],
            "courseName": [],
            "courseNameIndex": 0
        }
    }

    componentDidMount = () => {
        trackPromise(
            TrainRequest.getContact({"type": this.type})
                .then(response => {
                    this.setState({"contact": response.data})
                })
        )
        trackPromise(
            TrainRequest.getOSF().then(response => {
                // eslint-disable-next-line array-callback-return
                response.data.map((placement) => {
                    this.courseName.push(placement.name)
                })
                this.setState({"courseName": this.courseName})
                this.setState({"osf": response.data})
            })
        )
        this.getOSFContentAndMemo(1)
    }

    getOSFContentAndMemo = (id) => {
        trackPromise(
            TrainRequest.getOSFContent(id)
                .then(response => {
                    this.setState({"safetyContent": response.data})
                })
        )
        trackPromise(
            TrainRequest.getOSFMemo(id)
                .then(response => {
                    this.setState({"safetyMemo": response.data.courseMemoArray})
                })
        )
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showSend = () => {
        this.setState({"showSend": !this.state.showSend})
    }

    categoryChange = () => {
        let value = document.getElementById("name")
        this.setState({"courseNameIndex": value.options[value.selectedIndex].value - 1})
        this.getOSFContentAndMemo(value.options[value.selectedIndex].value)
    }

    send = () => {
        let formData = new FormData();
        formData.append("id", document.getElementById("id").value);
        formData.append("top", document.getElementById("top").checked);
        formData.append("topAtCourse", document.getElementById("topAtCourse").checked);
        formData.append("address", document.getElementById("address").value);
        formData.append("name", document.getElementById("name").value);
        formData.append("studentCount", document.getElementById("studentCount").value);
        formData.append("startClassTime", document.getElementById("startClassTime").value);
        formData.append("endClassTime", document.getElementById("endClassTime").value);
        formData.append("deadLine", document.getElementById("deadLine").value);
        formData.append("memberPrice", document.getElementById("memberPrice").value);
        formData.append("notMemberPrice", document.getElementById("notMemberPrice").value);
        document.getElementsByName("contact").forEach(
            (item, index) => {
                if (item.checked) {
                    formData.append("contactArray", item.value)
                }
            })
        formData.append("expectedStartClassTime", document.getElementById("expectedStartClassTime").value);
        let info = ""
        if (!formData.get("id")) {
            info = "請輸入課程編號"
        } else if (!formData.get("address")) {
            info = "請輸入課程地址"
        } else if (!formData.get("deadLine")) {
            info = "請輸入報名截止日期"
        } else if (!formData.get("studentCount")) {
            info = "請輸入課程人數"
        } else if (!formData.get("memberPrice")) {
            info = "請輸入會員費用"
        } else if (!formData.get("notMemberPrice")) {
            info = "請輸入非會員費用"
        } else if (!formData.get("contactArray")) {
            info = "請選擇聯絡人"
        } else {
            trackPromise(
                TrainRequest.osfCreate(formData, formData.get("name"))
                    .then(response => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("新增成功")
                        window.location.href = window.location.href.split("/create")[0];
                    })
                    .catch(error => {
                        this.setState({"showSend": !this.state.showSend})
                        this.showInfo("新增失敗")
                        document.getElementById(this.errorId).innerHTML = error.message
                        console.log(error.message)
                    })
            )
        }
        if (info) {
            this.showInfo(info)
            this.showSend()
        }
    }

    render() {
        let osfItem = [
            {"title": "課程編號", "require": true, "input": (<><input type="text" id="id"/></>)},
            {
                "title": "課程名稱", "require": true, "input": (<><select id="name" onChange={this.categoryChange}>{
                    this.state.osf.map((placement) => {
                        return (<option key={placement.templateId} value={placement.templateId}
                                        id="courseName">{placement.name}</option>)
                    })
                }</select></>)
            },
            {"title": "上課地點", "require": true, "input": (<><input type="text" id="address"/></>)},
            {"title": "開課日期", "require": false, "input": (<><input type="date" id="startClassTime"/></>)},
            {"title": "預計開課日期", "require": false, "input": (<><input type="text" id="expectedStartClassTime"/></>)},
            {"title": "開課結束日期", "require": false, "input": (<><input type="date" id="endClassTime"/></>)},
            {"title": "報名截止日期", "require": true, "input": (<><input type="date" id="deadLine"/></>)},
            {"title": "課程人數", "require": true, "input": (<><input type="number" id="studentCount"/></>)},
            {"title": "會員費用", "require": true, "input": (<><input type="number" id="memberPrice"/></>)},
            {"title": "非會員費用", "require": true, "input": (<><input type="number" id="notMemberPrice"/></>)},
            {"title": "是否置頂於首頁", "require": false, "input": (<><input type="checkbox" id="top"/></>)},
            {"title": "是否置頂於課程頁面", "require": false, "input": (<><input type="checkbox" id="topAtCourse"/></>)},
            {
                "title": "聯絡人", "require": true,
                "input": (
                    <>
                        {this.state.contact.map((placement, index) => {
                            return (<div key={index}>
                                <input type="checkbox" value={placement.email} id={`contact${index}`}
                                       name="contact"/>
                                <label form={`contact${index}`}>{placement.name}</label>
                            </div>)
                        })}
                    </>)
            }
        ]

        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div className="addPage">
                        <BreadcrumbObject secondTitle={"教育訓練"} thirdTitle={this.state.pageName}/>
                        <DynamicFormInput data={osfItem}/>
                        <hr/>
                        <Row>
                            <Col xs={6} className="table-responsive"><b>課程內容</b>
                                <table className="table oSFCourseContent">
                                    <thead>
                                    <tr>
                                        <td colSpan={2}>{this.state.courseName[this.state.courseNameIndex]}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.safetyContent.map((placement, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}. {placement.content}</td>
                                                <td style={{textAlign: "center"}}>{placement.hour}hr</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                            <Col xs={6}><b>注意事項</b>
                                <table className="oSFCourseContent">
                                    <thead>
                                    <tr>
                                        <td colSpan={2}>{this.state.courseName[this.state.courseNameIndex]}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            {this.state.safetyMemo.map((placement, index) => {
                                                return (
                                                    <p key={index}>{placement}</p>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>

                        <Row className="justify-content-end">
                            <Col/>
                            <Col xs={"auto"}>
                                <Button variant="success" onClick={this.showSend} className="test">送出資料</Button>
                            </Col>
                            <Col/>
                        </Row>
                        <ModalSureSend show={this.state.showSend} close={() => {
                            this.showSend()
                        }} ok={() => {
                            this.send()
                        }} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => {
                            this.showInfo()
                        }} close={() => {
                            this.showInfo()
                        }} message={this.state.infoMessage} error={this.errorPage}/>
                    </div> :
                    this.props.history.push("/")
                }
            </>
        );
    }
}

export const addOSFLecture = {
    routeProps: {
        path: '/Education-And-Train/Occupational-Safety-And-Health/create',
        component: AddLecture
    },
    name: '新增職安衛課程'
}
