import React from "react";

import logo from "../../img/icon/02-4.png";
import ntciaQrcode from "../../img/icon/ntciaQRcode.png";
import ntcialineQrcode from "../../img/icon/ntcialineQRcode.png";
import { Collapse } from "react-bootstrap"

import { navItem } from "../../js/navItem"

export default class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "open": [false]
        }
    }

    handleClick = (index) => {
        let copyState = { ...this.state };
        copyState.open = copyState.open.slice();
        copyState.open[index] = !copyState.open[index];
        this.setState(copyState);
    }

    render() {
        const item = navItem;
        const activityCategory = -1
        const activityId = 1
        return (
            <div className="nav">
                { item.map((property, index) => {
                    return (
                        <div className="menu" key={ index }>
                            { property.urlArray && !property.subItemArray.length ?
                                property.linkParam ?
                                    <a href={ `${ property.urlArray }/${ activityCategory }/${ activityId }` }>
                                        <button className="menuBtn" id="navBtn"
                                                onClick={ () => this.handleClick(index) }
                                                aria-expanded={ this.state.open[index] }>
                                            <img src={ logo } alt="logo" width="20%" height="20%" className="icon"/>
                                            <b>{ property.mainItem }</b>
                                        </button>
                                    </a>
                                    :
                                    <a href={ `${ property.urlArray }` }>
                                        <button className="menuBtn" id="navBtn"
                                                onClick={ () => this.handleClick(index) }
                                                aria-expanded={ this.state.open[index] }>
                                            <img src={ logo } alt="logo" width="20%" height="20%" className="icon"/>
                                            <b>{ property.mainItem }</b>
                                        </button>
                                    </a>
                                :
                                <button className="menuBtn" id="navBtn" onClick={ () => this.handleClick(index) }
                                        aria-expanded={ this.state.open[index] }>
                                    <img src={ logo } alt="logo" width="20%" height="20%" className="icon"/>
                                    <b>{ property.mainItem }</b>
                                </button>
                            }
                            <Collapse in={ this.state.open[index] } key={ index }>
                                <div className="aboutNtcia">
                                    { property.subItemArray.map((subItem, index) => {
                                        return (
                                            <div className="menuWd" key={ index }>
                                                <a href={ property.urlArray && property.urlArray[index] ? `${ property.urlArray[index] }` : "/" }>
                                                    <b>{ subItem }</b>
                                                </a>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </Collapse>
                        </div>)
                }) }
                <div className="qrcode">
                    <div className="webQrcode">
                        <img src={ ntciaQrcode } alt="Qrcode" width="50%" height="50%"/>
                        <div>官網</div>
                    </div>
                    <div className="lineQrcode">
                        <img src={ ntcialineQrcode } alt="Line Qrcode" width="50%" height="50%"/>
                        <div>Line</div>
                    </div>
                </div>
            </div>
        );
    }
}


