import {FetchUtil} from "../util/fetchUtil";

const BASE_PATH = "/activity-album"

export class ActivityRequest {

    static getCategory() {///get相簿類別
        return FetchUtil.getAPI(`${BASE_PATH}/category`)
    }

    static getTotalPage(data) {
        return FetchUtil.getAPI(`${BASE_PATH}/size`, data)
    }

    static addCatagory(data) {
        return FetchUtil.postHasTokenAPI(`${BASE_PATH}/category`, data)
    }

    static addActivity(data) {
        return FetchUtil.postFormDataAPI(BASE_PATH, data)
    }

    static addActivityPhoto(id, data) {
        return FetchUtil.postFormDataAPI(`${BASE_PATH}/${id}/photo`, data)
    }

    static getActivityPageCategory(categoryId, page, data) {
        return FetchUtil.getAPI(`${BASE_PATH}/byPageCategory/${categoryId}/${page}`, data)
    }

    static getAlbum(id) {
        return FetchUtil.getAPI(`${BASE_PATH}/${id}`)
    }

    static getTop() {
        return FetchUtil.getAPI(`${BASE_PATH}/top`)
    }

    static patchCover(id, data) {
        return FetchUtil.patchAPI(`${BASE_PATH}/${id}`, data)
    }

    static patchCategory(id, data) {
        return FetchUtil.patchAPI(`${BASE_PATH}/category/${id}`, data)
    }

    static patchAlbum(id, data) {
        return FetchUtil.patchAPI(`${BASE_PATH}/updateAlbum/${id}`, data)
    }

    static deleteCategory(id) {
        return FetchUtil.deleteHasTokenAPI(`${BASE_PATH}/category/${id}`)
    }

    static deletephoto(data) {
        return FetchUtil.deleteHasData(`${BASE_PATH}/`, data)
    }

    static deleteAlbum(data) {
        return FetchUtil.deleteHasData(`${BASE_PATH}/album`, data)
    }
}