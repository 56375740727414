import { FetchUtil } from "../util/fetchUtil";

const BASE_URL = "/editor";

export class EditorRequest {
    static createContent(data) {
        return FetchUtil.postHasTokenAPI(BASE_URL, data);
    }

    static createImage(data) {
        return FetchUtil.postFormDataAPI(`/ck-editor/upload-image`, data);
    }

    static getEditor(id) {
        return FetchUtil.getAPI(`${ BASE_URL }/${ id }`)
    }
};