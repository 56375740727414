import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb"

export default class BreadcrumbObject extends React.Component {
    render() {
        return (
            <>
                <Breadcrumb className="breadcrumbFontSize">
                    <Breadcrumb.Item href="/">首頁</Breadcrumb.Item>
                    <Breadcrumb.Item active>{ this.props.secondTitle }</Breadcrumb.Item>
                    { this.props.thirdTitle ? <Breadcrumb.Item active>{ this.props.thirdTitle }</Breadcrumb.Item> : "" }
                </Breadcrumb>

            </>
        );

    }
}