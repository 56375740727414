import { FetchUtil } from "../util/fetchUtil";

const BASE_PATH = "/banner"
const MEMBER_PATH = "/member/"
const PASSWORD_PATH = "/password"

export class KanbanRequest {
    static getKanbanPhoto() {
        return FetchUtil.getAPI(BASE_PATH);
    }

    static updatePhoto(data) {
        return FetchUtil.postFormDataAPI(BASE_PATH, data)
    }

    static updatePassword(account, data) {
        return FetchUtil.patchAPI(`${MEMBER_PATH}${account}${PASSWORD_PATH}`, data)
    }
};