import {FetchUtil} from "../util/fetchUtil";

const BASE_PATH = "/course";
const SIZE_PATH = `${BASE_PATH}/size`
const SIGN_UP = `${BASE_PATH}/sign-up`
const TOP = `${BASE_PATH}/top`
const Contact = `${BASE_PATH}/contact`
const COURSE_NAME = `${BASE_PATH}/name`
const COURSE_SIGN_UP = `${BASE_PATH}/sign-up`
const OSF = "/safety-health"


export class TrainRequest {
    static createLecture(data) {
        return FetchUtil.postFormDataAPI(`${BASE_PATH}/lecture`, data);
    }

    static createOther(data) {
        return FetchUtil.postFormDataAPI(`${BASE_PATH}/other`, data);
    }

    static osfCreate(data, id) {
        return FetchUtil.postFormDataAPI(`${OSF}/${id}`, data);
    }

    static createSignUpLecture(data) {
        return FetchUtil.postAPI(`${SIGN_UP}/lecture`, data);
    }

    static createSignUpOther(data) {
        return FetchUtil.postAPI(`${SIGN_UP}/other`, data);
    }

    static createSignUpSafetyHealth(data) {
        return FetchUtil.postAPI(`${SIGN_UP}/safety-health`, data);
    }

    static getSize(data) {
        return FetchUtil.getAPI(SIZE_PATH, data);
    }

    static searchList(data) {
        return FetchUtil.getAPI(BASE_PATH, data);
    }

    static getTop() {
        return FetchUtil.getAPI(TOP);
    }

    static getContact(data) {
        return FetchUtil.getHasTokenAPI(Contact, data);
    }

    static getCourseName(data) {
        return FetchUtil.getHasTokenAPI(COURSE_NAME, data)
    }

    static getSignUp(data) {
        return FetchUtil.getHasTokenAPI(COURSE_SIGN_UP, data)
    }

    static cancelSignUp(no, data) {
        return FetchUtil.deleteFormDataAPI(`${COURSE_SIGN_UP}/${no}`, data)
    }

    static editLecture(no, data) {
        return FetchUtil.patchFormDataAPI(`${BASE_PATH}/lecture/${no}`, data)
    }

    static delLecture(no) {
        return FetchUtil.deleteHasTokenAPI(`${BASE_PATH}/${no}`)
    }

    static editOther(id, data) {
        return FetchUtil.patchFormDataAPI(`${BASE_PATH}/other/${id}`, data)
    }

    static getDetail(no) {
        return FetchUtil.getAPI(`${BASE_PATH}/${no}`);
    }

    static getOSF() {
        return FetchUtil.getHasTokenAPI(OSF)
    }

    static editOSF(no, data) {
        return FetchUtil.patchFormDataAPI(`${BASE_PATH}${OSF}/${no}`, data)
    }

    static delOSF(no) {
        return FetchUtil.deleteHasTokenAPI(`${BASE_PATH}${OSF}/${no}`)
    }

    static getSignUpDetail(id, data) {
        return FetchUtil.getHasTokenAPI(`${COURSE_SIGN_UP}/${id}`, data);
    }

    static getOSFContent(id) {
        return FetchUtil.getAPI(`${OSF}/${id}/content`)
    }

    static getOSFMemo(id) {
        return FetchUtil.getAPI(`${OSF}/${id}/memo`)
    }

    static getOSFDownLoadURL(data) {
        return this.getDownloadURL(`${OSF}/download`, data);
    }

    static getLectureDownLoadURL(data) {
        return this.getDownloadURL(`${BASE_PATH}/lecture/download`, data);
    }

    static getOtherDownLoadURL(data) {
        return this.getDownloadURL(`${BASE_PATH}/other/download`, data);
    }

    static getDownloadURL(url, data) {
        if (data && typeof data === "object") {
            url += `?${FetchUtil.objectToRequestParams(data)}`;
        }
        return `${url}`;
    }

    static editSafetyMemo(id, data) {
        return FetchUtil.patchAPI(`${OSF}/${id}/memo`, data)
    }

}

