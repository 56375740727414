import React from "react";

import BreadcrumbObject from "./breadcrumb"
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {trackPromise} from "react-promise-tracker";

import {ModalHasContent} from "./modal";
import {Paging} from "./pagination";
import download from "../img/download.png";

export class DisplayList extends React.Component {
    pageTotal = 0;
    size = 11;

    constructor(props) {
        super(props);
        this.state = {
            "nowPage": 1,
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token"),
            "responseData": [],
            "targetIndex": 0,
            "modalShow": false,
        };
    }

    componentDidMount = () => {
        this.getSize();
    }

    getSize = () => {
        trackPromise(
            this.props.getSize({
                "size": this.size
            })
                .then(response => {
                    this.setState({"pageTotal": response.data.totalPage})
                    this.getList(1);
                })
        )
    }

    getList = (now) => {
        trackPromise(
            this.props.searchList({
                "count": this.size,
                "page": now
            }).then(response => {
                this.setState({"responseData": response.data})
            })
        )
    }

    handleModalShow = (index) => {
        this.setState({targetIndex: index});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        this.getList(now);
    }

    redirectTo = () => {
        window.location.href = `${window.location.href}/create`;
    }

    redirectToMaintain = () => {
        window.location.href = `${window.location.href}/maintain`;
    }

    render() {
        return (
            <div className="lecture">
                <Row className="controlButtons">
                    {this.state.isLogin && this.state.isAdmin ?
                        <>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editBlue"
                                        onClick={this.redirectToMaintain}>{this.props.maintainButtonTitle}</Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit" variant="success"
                                        onClick={this.redirectTo}>{this.props.createButtonTitle}</Button>
                            </Col>
                        </> :
                        <></>
                    }
                </Row>
                <BreadcrumbObject secondTitle={this.props.secondTitle} thirdTitle={this.props.thirdTitle}/>
                <div className="subLabel">
                    <Row noGutters={true}>
                        {this.props.subLabel}
                    </Row>
                </div>
                <hr/>
                <div className="announceContent content">
                    {this.state.responseData &&
                        this.state.responseData.map((data, index) => {
                            return (
                                <div className="contentBorder announceContentItem" key={index}>
                                    <Row noGutters={true}>
                                        {this.props.isFormDownload ?
                                            <>
                                                <Col className="ellipsis verticalCenter" style={{textAlign: "left"}}>
                                                    {data.title}
                                                </Col>
                                                <Col className="lecutreDownload" xs={2}>
                                                    <center>
                                                        <OverlayTrigger key={index} placement={"left"}
                                                                        overlay={<Tooltip>下載</Tooltip>}>
                                                            <a download href={data.url}
                                                               title="ImageName">
                                                                <img src={download} alt="" className="imgIconBlue"/>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </center>
                                                </Col>
                                            </> :
                                            <>
                                                <Col className="ellipsis verticalCenter" style={{textAlign: "left"}}
                                                     onClick={() => this.handleModalShow(index)}>{data.title}</Col>
                                                <Col xs={3} sm={2} style={{textAlign: "right"}}>{data.createDate}</Col>
                                            </>
                                        }
                                    </Row>
                                </div>
                            )
                        })}
                </div>
                <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange} nowPage={this.state.nowPage}/>
                <ModalHasContent show={this.state.modalShow} close={this.handleModalClose}
                                 content={this.state.responseData[this.state.targetIndex] && this.state.responseData[this.state.targetIndex].title}
                                 innerHTML={{__html: this.state.responseData[this.state.targetIndex] && this.state.responseData[this.state.targetIndex].content}}/>
            </div>

        );

    }

}