import {FetchUtil} from "../util/fetchUtil"

const BASE_URL = "/periodical"

export class JournalRequest {

    static create(data) {
        return FetchUtil.postFormDataAPI(BASE_URL, data);
    }

    static getSize(data) {
        return FetchUtil.getAPI(`${BASE_URL}/size`,data);
    }

    static searchList(data) {
        return FetchUtil.getAPI(BASE_URL, data);
    }

    static getEdit(id) {
        return FetchUtil.getHasTokenAPI(`${BASE_URL}/${id}`)
    }

    static patchEdit(id, data) {
        return FetchUtil.patchFormDataAPI(`${BASE_URL}/${id}`, data)
    }

    static deleteList(id) {
        return FetchUtil.deleteHasTokenAPI(`${BASE_URL}/${id}`)
    }
}