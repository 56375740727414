import React from "react";
import { Row, Col } from "react-bootstrap"
import logo from "../../img/logo.png";

export default class Footer extends React.Component {
    render() {
        const mapURL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.8137482986267!2d121.45960021500575!3d25.006443983985307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346802a73eadda4d%3A0x3e0732dc4a5770ba!2z5paw5YyX5biC5bel5qWt5pyD!5e0!3m2!1szh-TW!2stw!4v1590667481085!5m2!1szh-TW!2stw"
        return (
            <div className="footer ">
                <div className="infoFooter">
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="text">
                                <h3><img src={logo} alt="" />新北市工業會</h3>
                                <p>地址：新北市板橋區重慶路66號4樓</p>
                                <p>電話：(02) 2955-9077</p>
                                <p>傳真：(02) 2955-9090</p>
                                <p>電子郵件：ntcia@ntcia.org.tw</p>
                            </div>
                        </Col>
                        <Col>
                            <iframe src={mapURL} title="map"/>
                        </Col>
                    </Row>
                </div>
                <div className="smallFooter">COPYRIGHT &#169; 2021 NTCIA ALL RIGHTS RESERVED</div>
            </div>
        );
    }
}
