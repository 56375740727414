import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"


class Policy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.policy.id,
            editorTypeName: aboutType.policy.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "教育訓練" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }
}

export default {
    routeProps: {
        path: "/Education-And-Train/policy",
        component: Policy
    },
    name: "教務政策",
}
