import React from "react";
import { Button, Col, Row } from "react-bootstrap"
import { Link } from 'react-router-dom';

import BreadcrumbObject from "../../../../js/breadcrumb"
import { trackPromise } from "react-promise-tracker";
import { SubgroupRequest } from "../../../../js/request/subgroupRequest";


class Subgroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token")
        }
    }

    componentDidMount() {
        trackPromise(
            SubgroupRequest.searchList()
                .then(response => {
                    this.setState({ responseData: response.data })
                })
        )
    }

    redirectTo = () => {
        window.location.href = `/subgroup/create`;
    }

    render() {
        return (
            <>
                <Row className="controlButtons">
                    <Col sm={ "auto" }>
                        { this.state.isLogin && this.state.isAdmin ?
                            <Button className="edit kanEdit" variant="success"
                                    onClick={ this.redirectTo }>新增團體</Button> :
                            <></>
                        }
                    </Col>
                </Row>
                <BreadcrumbObject secondTitle={ "聯誼團體" }/>
                <Row className="subgroup">
                    { this.state.responseData &&
                        this.state.responseData.map((data, index) => {
                            return (
                                <Col key={ index } sm={ 6 } lg={ 4 } className="subgroupContent">
                                    <Link to={ this.state.responseData &&
                                        "subgroup/detail/" + data.id }>
                                        <img src={ data.coverImage } alt=""/>
                                        <div>{ data.title }</div>
                                    </Link>
                                </Col>
                            )
                        }) }
                </Row>
            </>
        );
    }
}

export default {
    routeProps: {
        path: '/subgroup',
        component: Subgroup
    },
    name: '聯誼團體',
}
