import Home from "./pages/home.js"
import Login from "./pages/login"
import Vision from "./pages/about/vision.js"
import Hall from "./pages/about/hall.js"
import Organization from "./pages/about/organization.js"
import Chairman from "./pages/about/chairman.js"
import Supervisor from "./pages/about/supervisor.js"
import Subgroup from "./pages/about/subgroup/subgroup.js"
import EditSub from "./pages/about/subgroup/detail.js"
import CreateAndEditSub from "./pages/about/subgroup/createAndEdit"
import Committee from "./pages/about/committee/committee.js"
import CreateAndEditCommittee from "./pages/about/committee/createAndEdit.js"
import DetailCommittee from "./pages/about/committee/detail.js"
import ClubStaff from "./pages/about/clubStaff.js"
import {lecture, other} from "./pages/train/lecture.js"
import {osf} from "./pages/train/occupationSafetyAndHealth"
import Traffic from "./pages/traffic.js"
import Journal from "./pages/journal/journal.js"
import CreateJournal from "./pages/journal/create.js"
import Announcement from "./pages/announcement/announcement.js"
import AddAnnouncement from "./pages/announcement/create.js"
import {activity} from "./pages/activity/activity.js"
import {addActivity} from "./pages/activity/addActivity.js"
import {album} from "./pages/activity/album.js"
import {addLecture, addOtherLecture} from './pages/train/addLecture.js'
import {addOSFLecture} from './pages/train/addOSF.js'
import ServiceList from "./pages/memberService/serviceList.js"
import Place from "./pages/memberService/place.js"
import Recruit from "./pages/memberService/recruit.js"
import Legal from "./pages/memberService/legal.js"
import Join from "./pages/join.js"
import Form from "./pages/formDownload/form.js"
import CreateForm from "./pages/formDownload/create"
import Decree from "./pages/decree/decree.js"
import CreateDecree from "./pages/decree/create.js"
import Links from "./pages/links"
import Policy from "./pages/train/policy"
import PropertyInvestment from "./pages/train/propertyInvestment"
import {maintainLecture, maintainOSH, maintainOther} from "./pages/train/maintain/maintainCourse"
import {signUpLecture,signUpOther} from "./pages/train/signUpList/signUpLecture"
import signUpOSF from "./pages/train/signUpList/signUpOSF"
import {editLecture,editOSH,editOtherLecture} from "./pages/train/maintain/editCourse"
import maintain from "./pages/announcement/maintain"
import announcementEdit from "./pages/announcement/announcementEdit"
import decreeMaintain from "./pages/decree/decreeMaintain"
import decreeEdit from "./pages/decree/decreeEdit"
import journalMaintain from "./pages/journal/journalMaintain"
import journalEdit from "./pages/journal/journalEdit"
import formMaintain from "./pages/formDownload/formMaintain"
import formEdit from "./pages/formDownload/formEdit"


export const main = [
    Home,
    Login,
    Vision,
    Hall,
    Organization,
    Chairman,
    Supervisor,
    Subgroup,
    EditSub,
    CreateAndEditSub.create,
    CreateAndEditSub.edit,
    Committee,
    CreateAndEditCommittee.create,
    CreateAndEditCommittee.edit,
    DetailCommittee,
    ClubStaff,
    Policy,
    lecture,
    osf,
    other,
    Traffic,
    Journal,
    CreateJournal,
    activity,
    album,
    addActivity,
    addLecture,
    addOSFLecture,
    addOtherLecture,
    Announcement,
    AddAnnouncement,
    ServiceList,
    Place,
    Recruit,
    Legal,
    Join,
    Form,
    CreateForm,
    Decree,
    CreateDecree,
    Links,
    PropertyInvestment,
    maintainLecture,
    maintainOSH,
    maintainOther,
    signUpLecture,
    signUpOther,
    signUpOSF,
    editLecture,
    editOSH,
    editOtherLecture,
    maintain,
    announcementEdit,
    decreeMaintain,
    decreeEdit,
    journalMaintain,
    journalEdit,
    formMaintain,
    formEdit,
]

const visionPath = Vision.routeProps.path;
const hallPath = Hall.routeProps.path;
const organizationPath = Organization.routeProps.path;
const chairmanPath = Chairman.routeProps.path;
const supervisorPath = Supervisor.routeProps.path;
const subgroupPath = Subgroup.routeProps.path;
const committeePath = Committee.routeProps.path;
const clubStaffPath = ClubStaff.routeProps.path;

const policyPath = Policy.routeProps.path;
const lPath = lecture.routeProps.path;
const osfPath = osf.routeProps.path;
const propertyInvestmentPath = PropertyInvestment.routeProps.path;
const otherPath = other.routeProps.path;
const trafficPath = Traffic.routeProps.path;
const journalPath = Journal.routeProps.path;
const activityPath = activity.routeProps.path;

const clubPath = Announcement.routeProps.path;
const serviceListPath = ServiceList.routeProps.path;
const placePath = Place.routeProps.path;
const recruitPath = Recruit.routeProps.path;
const legalPath = Legal.routeProps.path;
const joinPath = Join.routeProps.path;
const formPath = Form.routeProps.path;
const decreePath = Decree.routeProps.path;
const linksPath = Links.routeProps.path;

export const navUrl = {
    about: [visionPath, hallPath, organizationPath, chairmanPath, supervisorPath, subgroupPath, committeePath, clubStaffPath],
    traffic: trafficPath,
    journal: journalPath,
    lecture: [policyPath, lPath, osfPath, propertyInvestmentPath, otherPath],
    activity: activityPath,
    club: clubPath,
    memberService: [serviceListPath, recruitPath, legalPath, placePath],
    join: joinPath,
    form: formPath,
    decree: decreePath,
    links: linksPath,
}
