import React from "react";
import { Row, Col } from "react-bootstrap"

export class FormInput extends React.Component {
    render() {
        return (
            <Row>
                <Col sm={4} className={this.props.require ? "require" : ""}>{this.props.title}</Col>
                <Col>
                    {this.props.input}
                </Col>
            </Row>
        )
    }
}

export class DynamicFormInput extends React.Component {
    render() {
        return (
            <>
                {this.props.data &&
                    this.props.data.map((data, index) => {
                        return <FormInput key={index} require={data.require} title={data.title} input={data.input} />
                    })

                }
            </>
        )
    }
}