import React from "react"
import BraftEditor from "braft-editor"
import {Button} from "react-bootstrap";
import "braft-editor/dist/index.css"
import "braft-extensions/dist/table.css"
import Table from "braft-extensions/dist/table"
import {Upload} from "antd";
import {FileAddOutlined, PictureOutlined} from "@ant-design/icons";
import {ContentUtils} from "braft-utils";

import {EditorRequest} from "./request/editorRequest"
import {trackPromise} from "react-promise-tracker";
import {ModalBase, ModalSureSend} from "./modal";


export class Editors extends React.Component {
    errorPage = "editor"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: this.props.editorTypeId || "",
            identity: false,
            isEditorOpen: false,
            info: "",
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        };
    }

    componentDidMount = () => {
        this.getEditor()
    }

    componentDidUpdate = () => {
        this.checkIdentity()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //该方法内禁止访问this
        if (nextProps.editorTypeId !== prevState.editorTypeId) {
            //通过对比nextProps和prevState，返回一个用于更新状态的对象
            return {
                editorTypeId: nextProps.editorTypeId
            }
        }
        //不需要更新状态，返回null
        return null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getEditor()
    }

    checkIdentity = () => {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity === "admin") {
            if (!this.state.identity) {
                this.setState({"identity": "true"})
            }
        }
    }

    getEditor = () => {
        trackPromise(
            EditorRequest.getEditor(this.state.editorTypeId)
                .then(response => {
                    let info = response.data.info
                    if (this.props.isLinkPage) {
                        info = info.replace(/<br \/>/g, "")
                        info = info.replace(/class="media-wrap image-wrap"/g, "class='media-wrap image-wrap float-left'")
                    }
                    this.setState({info: info})
                })
                .catch((err) => {
                    console.log(err);
                })
        )
    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    showSend = () => {
        this.setState({"showSend": !this.state.showSend})
    }

    handleEditorOpen = () => {
        this.setState(prevstate => ({isEditorOpen: !prevstate.isEditorOpen}))
        if (this.props.isLinkPage) {
            let info = this.state.info
            if (this.props.isLinkPage) {
                info = info.replace(/<br \/>/g, "")
                info = info.replace(/class="media-wrap image-wrap"/g, "class='media-wrap image-wrap float-left'")
            }
            this.setState({info: info})
        }
    }

    handleSaveClick = () => {
        let info = this.state.info
        if (this.props.isLinkPage) {
            info = info.replace(/<br \/>/g, "")
            info = info.replace(/class="media-wrap image-wrap"/g, "class='media-wrap image-wrap float-left'")
        }
        let data = {
            info: info,
            editorTypeId: this.state.editorTypeId,
            registrationMan: localStorage.getItem("identity"),
        };
        trackPromise(
            EditorRequest.createContent(data)
                .then(response => {
                    this.setState({"showSend": !this.state.showSend})
                    this.showInfo("新增成功")
                    window.location.reload();
                })
                .catch(error => {
                    this.setState({"showSend": !this.state.showSend})
                    this.showInfo("新增失敗")
                    document.getElementById(this.errorId).innerHTML = error.message
                    console.log(error.message)
                })
        )
    }

    render() {
        return (
            <div>
                <div className="Editor-content">
                    {this.state.isEditorOpen ?
                        (
                            <>
                                <Button className="edit kanEdit ckeditorBtn" variant="success"
                                        onClick={this.handleEditorOpen}>瀏覽
                                </Button>
                                <span> ※ 因上傳圖片需要幾秒鐘的時間，如切換為瀏覽模式時圖片消失，代表圖片尚未上傳完成，請重新上傳</span>
                                <EditorTool accessText={(text) => {
                                    this.setState({
                                        info: text,
                                    })
                                }}
                                            outputHTML={this.state.info}/>
                            </>
                        ) :
                        (
                            <>
                                <div className="editContent">
                                    {this.state.identity ?
                                        (<Button className="edit kanEdit ckeditorBtn" variant="success"
                                                 onClick={this.handleEditorOpen}>編輯</Button>) : ""
                                    }
                                    {this.state.identity ?
                                        (<Button className="edit kanEdit ckeditorBtn" variant="success"
                                                 onClick={this.showSend}>儲存</Button>) : ""
                                    }
                                    <div className={this.state.identity ? "pMargin" : "ckeditorDiv pMargin"}
                                         dangerouslySetInnerHTML={{__html: this.state.info}}/>
                                </div>
                            </>
                        )
                    }
                </div>
                <ModalSureSend show={this.state.showSend} close={() => this.showSend()}
                               ok={() => this.handleSaveClick()} message={"確定儲存?"}/>
                <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                           message={this.state.infoMessage} error={this.errorPage}/>
            </div>
        );

    }

}

export class EditorTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: BraftEditor.createEditorState("<p>Hello <b>World!</b></p>"), // 设置编辑器初始内容
            outputHTML: "<p></p>",
        }
    }

    componentDidMount = () => {
        this.setState({
            editorState: BraftEditor.createEditorState(this.props.outputHTML),
            outputHTML: (this.props.outputHTML)
        })
    }

    handleChange = (editorState) => {
        this.setState({
            editorState: editorState,
            outputHTML: editorState.toHTML().replace(/<p><\/p>/g, "<br />")
        })
        this.handleEditor()
    }

    handleEditor() {
        this.props.accessText(this.state.outputHTML)
    }

    handleImgAndVideoUpload = (param) => {
        if (!param.file) {
            return false
        }

        let formData = new FormData();
        formData.append("upload", param.file);
        const {editorState} = this.state
        trackPromise(
            EditorRequest.createImage(formData)
                .then(response => {
                    if (param.file.type.startsWith("video") || param.file.type.startsWith("image")) {
                        this.setState({
                            editorState: ContentUtils.insertMedias(editorState, [{
                                type: param.file.type.startsWith("video") ? "VIDEO" : "IMAGE",
                                url: response.data.url,
                            }]),
                        })
                    } else {
                        this.setState({
                            editorState: ContentUtils.insertHTML(editorState,
                                `<a href=${response.data.url} class="editorHyperLink">${param.file.name}</a>`)
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        )
    }

    handleFileUpload = (param) => {
        if (!param.file) {
            return false
        }

        let formData = new FormData();
        formData.append("upload", param.file);
        const {editorState} = this.state
        trackPromise(
            EditorRequest.createImage(formData)
                .then(response => {
                    const fileName = param.file.type.startsWith("video") || param.file.type.startsWith("image") ?
                        param.file.name.replace(/\.[^/.]+$/, "") : param.file.name;
                    this.setState({
                        editorState: ContentUtils.insertHTML(editorState,
                            `<a href=${response.data.url} class="editorHyperLink">${fileName}</a>`)
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        )
    }

    render() {
        const controls = [
            "undo", "redo", "separator",
            "font-size", "line-height", "letter-spacing", "separator",
            "text-color", "bold", "italic", "underline", "strike-through", "separator",
            "superscript", "subscript", "remove-styles", "emoji", "separator", "text-indent", "text-align", "separator",
            "headings", "list-ul", "list-ol", "blockquote", "code", "separator",
            "link", "separator", "hr", "separator",
            "separator",
            "table", "clear"
        ]
        const {editorState} = this.state
        const extendControls = [
            {
                key: "antd-uploader-file",
                type: "component",
                component: (
                    <Upload
                        accept="file/*"
                        showUploadList={false}
                        customRequest={this.handleFileUpload}
                    >
                        <button type="button" className="control-item button upload-button" data-title="插入文件"
                                style={{margin: 0}}>
                            <FileAddOutlined/>
                        </button>
                    </Upload>
                )
            }, {
                key: "antd-uploader",
                type: "component",
                component: (
                    <Upload
                        accept="image/*, video/*"
                        showUploadList={false}
                        customRequest={this.handleImgAndVideoUpload}
                    >
                        <button type="button" className="control-item button upload-button" data-title="插入圖片及影片"
                                style={{margin: 0}}>
                            <PictureOutlined theme="filled"/>
                        </button>
                    </Upload>
                )
            }
        ]
        BraftEditor.use(Table({withDropdown: true, columnResizable: true,}))
        return (
            <div>
                <div className="editor-wrapper">
                    <BraftEditor
                        value={editorState}
                        onChange={this.handleChange}
                        language="zh-hant"
                        controls={controls}
                        extendControls={extendControls}
                    />
                </div>
            </div>
        )

    }

}