import React from "react";

import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap"

import BreadcrumbObject from "../../../js/breadcrumb"
import download from "../../../img/download.png";
import {Paging} from "../../../js/pagination"
import {trackPromise} from "react-promise-tracker";
import {JournalRequest} from "../../../js/request/journalRequest"

class Journal extends React.Component {
    pageTotal = 0;
    size = 12;

    constructor(props) {
        super(props);
        this.state = {
            "nowPage": 1,
            "isAdmin": localStorage.getItem("identity") === "admin",
            "isLogin": !!localStorage.getItem("token"),
            "responseData": [],
            "targetIndex": 0,
            "modalShow": false,
        };
    }

    componentDidMount = () => {
        this.getSize();
    }

    getSize = () => {
        trackPromise(
            JournalRequest.getSize({"size": this.size})
                .then(response => {
                    this.setState({"pageTotal": response.data.totalPage})
                    this.getList(1);
                })
        )
    }

    getList = (now) => {
        trackPromise(
            JournalRequest.searchList({
                "size": this.size,
                "page": now
            }).then(response => {
                this.setState({"responseData": response.data})
            })
        )
    }

    pageChange = (now) => {
        this.setState({"nowPage": now})
        this.getList(now);
    }

    redirectTo = () => {
        window.location.href = `${window.location.href}/create`;
    }

    redirectToMaintain = () => {
        window.location.href = `${window.location.href}/maintain`;
    }

    render() {
        return (
            <div className="lecture">
                <Row className="controlButtons">
                    {this.state.isLogin && this.state.isAdmin ?
                        <>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit editBlue"
                                        onClick={this.redirectToMaintain}>期刊維護</Button>
                            </Col>
                            <Col xs={"auto"}>
                                <Button className="edit kanEdit" variant="success"
                                        onClick={this.redirectTo}>新增期刊</Button>
                            </Col>
                        </> :
                        <></>
                    }

                </Row>
                <BreadcrumbObject secondTitle={"工業期刊"} thirdTitle={""}/>
                <div className="subLabel">
                    <Row noGutters={true}>
                        <Col xs={1}></Col>
                        <Col>期別</Col>
                        <Col xs={3}>出刊日</Col>
                        <Col xs={3}>下載</Col>
                    </Row>
                </div>
                <hr/>
                <div className="announceContent content">
                    {this.state.responseData.map((data, index) => {
                        return (
                            <div className="contentBorder announceContentItem" key={index}>
                                <Row noGutters={true}>
                                    <Col className="lectureNo" xs={1}>
                                        <div>{index + 1}</div>
                                    </Col>
                                    <Col className="journalVerticalCenter">{data.title}</Col>
                                    <Col xs={3} className="journalVerticalCenter">{data.publishDate}</Col>
                                    <Col className="lecutreDownload" xs={3}>
                                        <center>
                                            <OverlayTrigger key={index} placement={"left"}
                                                            overlay={<Tooltip>下載</Tooltip>}>
                                                <a download href={data.url} title="ImageName">
                                                    <img src={download} alt="" className="imgIconBlue"/>
                                                </a>
                                            </OverlayTrigger>
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>
                <Paging pageTotal={this.state.pageTotal} pageChange={this.pageChange}
                        nowPage={this.state.nowPage}/>
            </div>
        );

    }

}


export default {
    routeProps: {
        path: "/journal",
        component: Journal
    },
    name: "工業期刊",
}
