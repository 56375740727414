import React from "react";

import BreadcrumbObject from "../../../../js/breadcrumb"
import {EditorTool} from "../../../../js/editorTool"
import {trackPromise} from "react-promise-tracker";
import {CommitteeRequest} from "../../../../js/request/committeeRequest";
import {Button, Col, Row} from "react-bootstrap";
import {ModalBase, ModalSureSend} from "../../../../js/modal";

class CreateAndEdit extends React.Component {
    method = this.props.match.params.method;
    errorPage = "committee"
    errorId = `${this.errorPage}ErrorInfo`

    constructor(props) {
        super(props);
        this.state = {
            isEditorOpen: this.method === "create",
            info: "",
            sureSend: false,
            showInfo: false,
            infoMessage: "",
        };
    }

    componentDidMount = () => {
        if (this.method === "edit") {
            trackPromise(
                CommitteeRequest.getDetail(this.props.match.params.id)
                    .then(response => {
                        this.setState(response.data)
                    })
            )
        }
    }

    handleModalShow = (content) => {
        this.setState({modalTitle: content});
        this.setState({modalShow: true})
    }

    handleModalClose = () => {
        this.setState({modalShow: false})
    }

    handleEditorOpen = () => {
        this.setState(prevState => ({isEditorOpen: !prevState.isEditorOpen}))
    }

    handleValueChange = (prop) => {
        let target = prop.target;
        let name = target.name;
        let value = target.value;
        this.setState({[name]: value})
    }

    sureSend = (toSend) => {
        if (toSend && this.state.sureSend) {
            const requestMethod = this.method === "edit" ? CommitteeRequest.update : CommitteeRequest.create;
            let formData = new FormData();
            formData.append("title", this.state.title);
            formData.append("content", this.state.content);
            formData.append("coverImageFile", document.getElementById("coverImageFile").files[0]);
            trackPromise(
                requestMethod(formData, this.props.match.params.id)
                    .then(response => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo(this.method === "create" ? "新增成功" : "更新成功")
                        window.location.href = (this.method === "create" ? window.location.href.split("/create")[0] : window.location.href.split("/edit")[0]);
                    })
                    .catch(error => {
                        this.setState({"sureSend": !this.state.sureSend})
                        this.showInfo("新增失敗")
                        document.getElementById("errorInfo").innerHTML = error.message
                        console.log(error.messge)
                    })
            )
        } else {
            this.setState({"sureSend": !this.state.sureSend})
        }

    }

    showInfo = (message) => {
        this.setState({"showInfo": !this.state.showInfo})
        this.setState({"infoMessage": message});
    }

    render() {
        return (
            <>
                {!!localStorage.getItem("token") ?
                    <div>
                        <BreadcrumbObject secondTitle={"專業委員會"}
                                          thirdTitle={this.method === "create" ? "新增委員會" : "編輯委員會"}/>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2} className={this.method === "create" ? "addRedStar" : ""}>委員會名稱</Col>
                            <Col>
                                <input className="title_input" id="title" name="title" type="text"
                                       value={this.state.title || ""}
                                       required={this.method === "create"}
                                       onChange={this.handleValueChange}/>
                            </Col>
                        </Row>
                        <Row noGutters={true} className="addAnnounceContent">
                            <Col xs={2} className={this.method === "create" ? "addRedStar" : ""}>封面照片</Col>
                            <Col>
                                <input className="title_input" id="coverImageFile" type="file"
                                       required={this.method === "create"}/>
                            </Col>
                        </Row>
                        <div className="editor-content">
                            {this.state.isEditorOpen ?
                                (
                                    <>
                                        <Button className="edit kanEdit ckeditorBtn" variant="success"
                                                onClick={this.handleEditorOpen}>瀏覽
                                        </Button>
                                        <EditorTool accessText={(text) => {
                                            this.setState({
                                                content: text,
                                            })
                                        }}
                                                    outputHTML={this.state.content || ""}/>
                                    </>
                                ) :
                                (
                                    <>
                                        <div className="editContent">
                                            <Button className="edit kanEdit ckeditorBtn" variant="success"
                                                    onClick={this.handleEditorOpen}>編輯
                                            </Button>
                                            <div className="ckeditorDiv"
                                                 dangerouslySetInnerHTML={{__html: this.state.content || ""}}/>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <Row style={{paddingTop: "5%"}}>
                            <Col xs={2} style={{margin: "0 auto"}}>
                                <button className="kanEdit ckeditorBtn btn btn-success"
                                        onClick={() => this.sureSend(false)}>送出
                                </button>
                            </Col>
                        </Row>
                        <ModalSureSend show={this.state.sureSend} close={() => this.sureSend(false)}
                                       ok={() => this.sureSend(true)} message={"確認送出"}/>
                        <ModalBase show={this.state.showInfo} ok={() => this.showInfo()} close={() => this.showInfo()}
                                   message={this.state.infoMessage} error={this.errorPage}/>
                    </div>
                    : this.props.history.push("/")
                }
            </>
        );
    }
}

export default {
    create: {
        routeProps: {
            path: '/committee/:method(create)',
            component: CreateAndEdit
        },
        name: '新增專業委員會'
    },
    edit: {
        routeProps: {
            path: '/committee/:method(edit)/:id',
            component: CreateAndEdit
        },
        name: '修改專業委員會'
    }
}