import React from "react";

import BreadcrumbObject from "../../js/breadcrumb"
import { Editors } from "../../js/editorTool"
import aboutType from "../../js/about.js"


class Join extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.join.id,
            editorTypeName: aboutType.join.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }

}

export default {
    routeProps: {
        path: "/join",
        component: Join
    },
    name: "我要入會",
}
