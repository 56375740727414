import React from "react";

import BreadcrumbObject from "../../../js/breadcrumb"
import { Editors } from "../../../js/editorTool"
import aboutType from "../../../js/about.js"

class Legal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.legal.id,
            editorTypeName: aboutType.legal.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "會員服務" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );
    }

}


export default {
    routeProps: {
        path: "/legal",
        component: Legal
    },
    name: "法律服務",
}
