import React from "react";
import { Button, Col, Row } from "react-bootstrap"

import BreadcrumbObject from "../../../../js/breadcrumb"

import "bootstrap/dist/css/bootstrap.min.css";
import "../../../../css/main.css";

import { trackPromise } from "react-promise-tracker";
import { TrainRequest } from "../../../../js/request/trainRequest"
import { Paging } from "../../../../js/pagination"
import { ModalSignDetail, ModalSureSend } from "../../../../js/modal"

class SignUpOSF extends React.Component {
    type = 1;
    pageTotal = 0
    courseItem = []

    constructor(props) {
        super(props);
        this.state = {
            "isLogin": !!localStorage.getItem("token"),
            "isAdmin": localStorage.getItem("identity") === "admin",
            "contentNo": this.courseItem,
            "pageTotal": this.pageTotal,
            "nowPage": 1,
            "showInfo": false,
            "sureSend": false,
            "showCancel": false,
            "showSignDetail": false,
            "infoMessage": "",
            "courseName": [],
            "signupItem": [],
            "signItem": {
                "memberId": "",
                "name": "",
                "birthday": "",
                "identity": "",
                "education": "",
                "graduatedSchool": "",
                "serviceUnit": "",
                "companyName": "",
                "email": "",
                "cellPhone": "",
                "phone": "",
                "faxNumber": "",
                "address": "",
                "mealRequirement": "",
                "memo": ""
            },
            "downloadCourseId": "",
        }
    }

    componentDidMount = () => {
        this.getCourseName();
        this.getList()
    }

    pageChange = (now) => {
        this.setState({ "nowPage": now })
    }

    getCourseName = () => {
        trackPromise(
            TrainRequest.getCourseName({ "type": this.type })
                .then(response => {
                    this.setState({ "courseName": this.state.courseName.concat(response.data) })
                })
        )
    }

    getList = () => {
        let value = document.getElementById("courseName").value
        let data = { "type": this.type }
        if (value) data.id = value
        this.setState({ "downloadCourseId": value })
        trackPromise(
            TrainRequest.getSignUp(data).then(response => {
                this.setState({
                    "signupItem": response.data,
                    "pageTotal": Math.ceil(response.data.length / 12),
                    "nowPage": 1
                })
            })
        )
        console.log(this.state.pageTotal)

    }

    getDetail = (id) => {
        let data = {
            "type": this.type
        }
        trackPromise(
            TrainRequest.getSignUpDetail(id, data).then(response => {
                this.setState({ "signItem": response.data })
            })
        )
    }

    cancelSignUp = () => {
        let data = new FormData();
        data.append("type", this.type);
        trackPromise(TrainRequest.cancelSignUp(this.state.id, data)
            .then(response => {
                this.showInfo("取消成功")
                window.location.reload();
            })
        )
    }

    redirectTo = (aim) => {
        window.location.href = window.location.href.replace("SignUpList", aim);
    }

    showInfo = (message) => {
        this.setState({ "showInfo": !this.state.showInfo })
        this.setState({ "infoMessage": message });
    }

    showCancel = (id) => {
        this.setState({
            "showCancel": !this.state.showCancel,
            "id": id
        })
    }

    showSignDetail = (id) => {
        this.setState({ "showSignDetail": !this.state.showSignDetail })
        if (!this.state.showSignDetail) {
            this.getDetail(id)
        }
    }

    downloadEmployeeData = () => {
        if (this.state.downloadCourseId === "") {
            this.setState({ "downloadCourseId": "職安衛課程" })
        }

        const url = TrainRequest.getOSFDownLoadURL({ courseId: this.state.downloadCourseId });
        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem("token")
            },
            responseType: "arraybuffer"
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${ this.state.downloadCourseId }.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        return (
            <div className="signUpList">
                <Row className="align-items-center controlButtons">
                    <Col xs={ "auto" } className="label">報名列表 </Col>
                    { this.state.isLogin && this.state.isAdmin ? (<>
                        <Col xs={ 7 } sm={ 5 } md={ 4 }>
                            <select id="courseName" className="oSFCourseName" onChange={ () => {
                                this.getList()
                            } }>
                                <option key={ 0 } value={ "" }
                                        className="ellipsis">全部課程
                                </option>
                                {
                                    this.state.courseName.map((placement, index) => {
                                        return (
                                            <option key={ index + 1 } value={ placement.id }
                                                    className="ellipsis">{ placement.name }</option>)
                                    })
                                }
                            </select>
                        </Col>
                        <Col xs={ "auto" }>
                            <Button className="edit kanEdit editBlue signOSFBtn"
                                    onClick={ () => {
                                        this.redirectTo("maintain")
                                    } }>課程維護
                            </Button>
                        </Col>
                        <Col xs={ "auto" }>
                            <Button className="edit kanEdit signOSFBtn" variant="success"
                                    onClick={ () => {
                                        this.redirectTo("create")
                                    } }>新增課程
                            </Button>
                        </Col>
                        <Col xs={ "auto" }>
                            { this.state.signupItem.length > 0 ?
                                <Button className="editDownload kanEdit" variant="outline-secondary"
                                        onClick={ () => {
                                            this.downloadEmployeeData()
                                        } }>下載
                                </Button>
                                :
                                <Button className="editDownload kanEdit" disabled variant="outline-secondary"
                                        onClick={ () => {
                                            this.downloadEmployeeData()
                                        } }>下載
                                </Button>
                            }
                        </Col>
                    </>) : (<></>) }
                </Row>
                <BreadcrumbObject secondTitle={ "教育訓練" } thirdTitle={ "職安衛課程" }/>
                <div className="subLabel">
                    <Row noGutters={ true }>
                        <Col xs={ 1 }/>
                        <Col xs={ 3 } sm={ 2 } className="lectureID">課程編號</Col>
                        <Col className="lectureName">課程名稱</Col>
                        <Col xs={ 2 }>姓名</Col>
                        <Col xs="auto" sm={ 2 }>報名狀態</Col>
                        <Col xs={ 2 } lg={ 1 }/>
                    </Row>
                </div>
                <hr/>
                { this.state.signupItem.length > 0 ?
                    <div className="content">
                        { this.state.signupItem.slice((this.state.nowPage - 1) * 12, this.state.nowPage * 12).map((item, index) => {
                            return (
                                <div className="announceContentItem contentBorder lectureContentItem" key={ index }>
                                    <Row noGutters={ true } className="align-items-center">
                                        <Col className='lectureNo' xs={ 1 }>
                                            <div>{ index + 1 + 12 * (this.state.nowPage - 1) }</div>
                                        </Col>
                                        <Col className="lectureID" xs={ 3 } sm={ 2 }>
                                            <div>{ item.courseId }</div>
                                        </Col>
                                        <Col className="lectureName ellipsis"
                                             onClick={ () => this.showSignDetail(item.id) }>
                                            <div>{ item.courseName }</div>
                                        </Col>
                                        <Col xs={ "auto" } sm={ 2 }
                                             onClick={ () => this.showSignDetail(item.id) }>{ item.name }</Col>
                                        <Col xs={ 2 }>{ (item.available ? "有效" : "無效") }</Col>
                                        <Col xs={ 2 } lg={ 1 }><Button className="edit kanEdit editBlue"
                                                                       onClick={ () => this.showCancel(item.id) }
                                                                       disabled={ !item.available }>取消</Button></Col>
                                    </Row>
                                </div>
                            )
                        }) }
                    </div>
                    :
                    <div className="courseNoPeopleSignUp">尚無人報名！</div>
                }
                { this.state.signupItem.length > 0 ?
                    <Paging pageTotal={ this.state.pageTotal }
                            pageChange={ this.pageChange }
                            nowPage={ this.state.nowPage }/>
                    : <></> }
                <ModalSureSend show={ this.state.showCancel } close={ this.showCancel } ok={ this.cancelSignUp }
                               message={ "確定取消" }/>
                <ModalSignDetail show={ this.state.showSignDetail } ok={ this.showSignDetail }
                                 close={ this.showSignDetail }
                                 signItem={ this.state.signItem } type={ 1 }/>
            </div>
        );
    }
}

export default {
    routeProps: {
        path: "/Education-And-Train/Occupational-Safety-And-Health/SignUpList",
        component: SignUpOSF
    },
    name: "講習課程"
}




