import React from "react";

import { Col, Collapse, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import logo2 from "../../img/icon/icon.png";
import { navItem } from "../../js/navItem"
import hamburger from "../../img/icon/hamburger.png"
import ntciaQrcode from "../../img/icon/ntciaQRcode.png";
import ntcialineQrcode from "../../img/icon/ntcialineQRcode.png";
import { ModalBase, ModalEditPhoto, ModalLogin } from "../../js/modal"
import { trackPromise } from "react-promise-tracker";


export default class Hamburger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "open": [false],
            "identity": "",
            "isLogin": false,
        }
    }

    componentDidMount() {
        this.checkIdentity();
    }

    checkIdentity() {
        let checkIdentity = localStorage.getItem("identity");
        if (checkIdentity && checkIdentity !== "") {
            if (this.state.identity === "") {
                this.setState({ "identity": checkIdentity })
                this.setState({ "isLogin": true });
            }
        } else {
            this.setState({ "isLogin": false });
        }
    }

    login = () => {
        let account = document.getElementById("account").value;
        let password = document.getElementById("password").value;
        let info = ""
        if (!account) {
            info = "請輸入帳號"
        } else if (!password) {
            info = "請輸入密碼"
        } else {
            trackPromise(
                fetch(`/login`, {
                    method: "POST",
                    body: JSON.stringify({
                        "account": account,
                        "password": password
                    }),
                    headers: {
                        "Content-type": "application/json",
                    }
                }).then(response => {
                    for (const header of response.headers) {
                        if (header[0] === "x-auth-token") {
                            localStorage.setItem("token", "Bearer " + header[1])
                        }
                    }

                    if (response.status === 200) {
                        return response.json()
                    } else {
                        return { "result": false, "message": "登入失敗" }
                    }
                }).then(response => {
                    if (response.result) {
                        const identity = response.data.identity;
                        localStorage.setItem("identity", identity)
                        localStorage.setItem("account", account)
                        this.setState({ "identity": identity })
                        this.setState({ "isLogin": true });

                        this.showLogin();
                        this.showInfo(response.message);
                        window.location.reload();
                    } else {
                        document.getElementById("loginErrorInfo").innerHTML = response.message
                    }
                })
                    .catch(e => {
                        console.log("catch error:", e)
                        document.getElementById("loginErrorInfo").innerHTML = "登入失敗"

                    })
            )
        }
        if (info !== "") {
            this.showInfo(info)
        }

    }

    logout = () => {
        localStorage.setItem("identity", "")
        localStorage.removeItem("token")
        localStorage.removeItem("account")
        this.setState({ "identity": "" });
        this.setState({ "isLogin": false });
        this.showLogout(false);
        let homePath = window.location.origin
        let pageArrayPath = window.location.pathname.split("/")
        let pagePath = "";
        let change = false;
        let activity = false;
        for (var i = 0; i < pageArrayPath.length; i++) {
            if (pageArrayPath[i] === "create" || pageArrayPath[i] === "maintain" || pageArrayPath[i] === "edit" || pageArrayPath[i] === "SignUpList") {
                change = true;
            }
            if (pageArrayPath[i] === "activity") {
                activity = true;
            }
        }
        if (change) {
            if (activity) {
                pagePath = "activity/-1/1"
            } else {
                for (let i = 0; i < pageArrayPath.length - 1; i++) {
                    if (i === pageArrayPath.length - 2) {
                        pagePath += pageArrayPath[i]
                    } else {
                        pagePath += pageArrayPath[i] + "/"
                    }
                }
            }
            window.location.href = homePath + pagePath
        } else {
            window.location.reload()
        }
    }

    openMenu = (index) => {
        let copyState = { ...this.state };
        copyState.open = copyState.open.slice();
        copyState.open[index] = !copyState.open[index];
        this.setState(copyState);
    }

    closeMenu = () => {
        let copyState = { ...this.state };
        copyState.open = copyState.open.slice();
        for (var i = 0; i < copyState.open.length; i++) {
            copyState.open[i] = false
        }
        this.setState(copyState);
    }

    showLogin = () => {
        this.setState({ "showLogin": !this.state.showLogin });
    }

    showLogout = () => {
        this.setState({ "showLogout": !this.state.showLogout });
    }

    render() {
        let iconInfo = [{ "title": "首頁", "className": "kanHome", "href": "/", "tarrget": "" },
            { "title": "登入", "className": "kanUser", "href": "/", "tarrget": "" },
            {
                "title": "連結舊網站",
                "className": "kanOutSite",
                "href": "http://www.ntcia.org.tw/WebMaster/",
                "tarrget": "_blank"
            },
            {
                "title": "本會地點",
                "className": "kanLogin",
                "href": "https://www.google.com/maps?ll=25.006444,121.461789&z=15&t=m&hl=zh-TW&gl=TW&mapclient=embed&cid=4469597076909945018",
                "tarrget": "_blank"
            }]
        const item = navItem;
        const activityCategory = -1
        const activityId = 1
        return (
            <>
                <div className="hamburger">
                    <div className="hamburgerMenu">
                        <img src={ hamburger } onClick={ () => this.openMenu(0) } aria-expanded={ this.state.open[0] }
                             width="10%" height="10%" alt=""/>
                    </div>
                    <div className="rwdLogo">
                        <img className="logo2" src={ logo2 } width="60%" height="80%" alt=""/>
                    </div>
                    <Collapse in={ this.state.open[0] }>
                        <div className="rwdMenu">
                            <div className="rwdkanButtons">
                                <div className="kanButtons2">
                                    { [0, 1, 2, 3].map((placement, index) => {
                                        let item;
                                        if (Number(placement) === 1) {
                                            item = (
                                                <div className="iconContent" key={ index }>
                                                    <div className={ `kanIcon2 ${ iconInfo[placement].className }` }
                                                         onClick={ () => {
                                                             if (this.state.isLogin) {
                                                                 this.showLogout()
                                                             } else {
                                                                 this.showLogin()
                                                             }
                                                         } }>
                                                    </div>
                                                </div>
                                            )
                                            iconInfo[placement].title = this.state.isLogin ? "登出" : "登入"
                                        } else {
                                            item = (
                                                <div className="iconContent">
                                                    <a href={ `${ iconInfo[placement].href }` }
                                                       className={ `kanIcon2 ${ iconInfo[placement].className }` }
                                                       target={ `${ iconInfo[placement].tarrget }` }>
                                                    </a>
                                                </div>
                                            )
                                        }
                                        return (
                                            <OverlayTrigger key={ index } placement={ "bottom" }
                                                            overlay={
                                                                <Tooltip id={ `${ placement }` }>
                                                                    { iconInfo[placement].title }
                                                                </Tooltip>
                                                            }>
                                                { item }
                                            </OverlayTrigger>
                                        )
                                    }) }
                                </div>
                            </div>
                            <div className="kanNav">
                                { item.map((property, index) => {
                                    return (
                                        <Row className="rwdNav" key={ index }>
                                            <Col>
                                                { property.urlArray && !property.subItemArray.length ?
                                                    property.linkParam ?
                                                        <a href={ `${ property.urlArray }/${ activityCategory }/${ activityId }` }>
                                                            <b onClick={ () => this.closeMenu() }
                                                               aria-controls="aboutNtcia"
                                                               aria-expanded={ this.state.open[index + 1] }>{ property.mainItem }</b>
                                                        </a>
                                                        :
                                                        <a href={ `${ property.urlArray }` }>
                                                            <b onClick={ () => this.closeMenu() }
                                                               aria-controls="aboutNtcia"
                                                               aria-expanded={ this.state.open[index + 1] }>{ property.mainItem }</b>
                                                        </a>
                                                    :
                                                    <b onClick={ () => this.openMenu(index + 1) }
                                                       aria-controls="aboutNtcia"
                                                       aria-expanded={ this.state.open[index + 1] }>{ property.mainItem }</b>
                                                }
                                                <Collapse in={ this.state.open[index + 1] }>
                                                    <div id="aboutNtcia">
                                                        { property.subItemArray.map((subItem, index) => {
                                                            return (
                                                                <div className="rwdMenuWd" key={ index }>
                                                                    <a onClick={ () => this.closeMenu() }
                                                                       href={ property.urlArray && property.urlArray[index] ? `${ property.urlArray[index] }` : "" }>
                                                                        <b>{ subItem }</b>
                                                                    </a>
                                                                </div>)
                                                        }) }
                                                    </div>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    )
                                }) }
                                <div className="qrcode">
                                    <div className="webQrcode">
                                        <img src={ ntciaQrcode } alt="Qrcode" width="50%" height="50%"/>
                                        <div>官網</div>
                                    </div>
                                    <div className="lineQrcode">
                                        <img src={ ntcialineQrcode } alt="Line Qrcode" width="50%" height="50%"/>
                                        <div>Line</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <ModalBase show={ this.state.showInfo } ok={ () => {
                    this.showInfo()
                } } close={ () => {
                    this.showInfo()
                } } message={ this.state.message } error={ this.errorPage }/>
                <ModalBase message={ "會員登出" } show={ this.state.showLogout } ok={ this.logout }
                           close={ this.showLogout }/>
                <ModalLogin show={ this.state.showLogin } ok={ this.login } close={ this.showLogin }/>
                <ModalEditPhoto show={ this.state.showPicture } ok={ () => {
                    this.editPicture()
                } } close={ this.showPictureModal }/>

            </>
        );
    }
}
