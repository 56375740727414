import React from "react";
import {Col, Pagination, Row} from "react-bootstrap"

export class Paging extends React.Component {
    pageItem = []

    constructor(props) {
        super(props)
        this.state = {
            "pageItem": [],
            "pageTotal": 0,
            "nowPage": 1
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //该方法内禁止访问this
        if (prevState.pageTotal === 0 | nextProps.pageTotal !== prevState.pageTotal) {
            //通过对比nextProps和prevState，返回一个用于更新状态的对象
            let total = nextProps.pageTotal
            let array = []
            if (nextProps.nowPage <= 2) {
                for (let i = 1; i <= (total < 3 ? total : 3); i++) {
                    array.push({"active": i === 1, "page": i})
                }
            } else {
                for (let i = nextProps.nowPage; i - 2 <= (total >= nextProps.nowPage ? nextProps.nowPage : total); i++) {
                    array.push({"active": i - 2 === nextProps.nowPage, "page": i - 2})
                }
            }


            return {
                pageTotal: total,
                nowPage: nextProps.nowPage,
                pageItem: array
            }
        }
        //不需要更新状态，返回null
        return null
    }

    pageChange = (now) => {
        this.props.pageChange(now)
    }

    paginationChange = (value) => {
        let array = []
        let now = this.state.nowPage
        let pagetotal = this.state.pageTotal

        switch (value) {
            case 1:
            case "first":
                if (now !== 1) {
                    now = 1
                    for (let i = now; i <= (pagetotal <= 3 ? pagetotal : 3); i++) {
                        array.push({"active": i === now, "page": i})
                    }
                }
                break;
            case "prev":
                if (now !== 1) {
                    now--
                    for (let i = (now === 1 ? 1 : now - 1); i <= (pagetotal <= 3 ? pagetotal : (now === 1 ? now + 2 : now + 1)); i++) {
                        array.push({"active": i === now, "page": i})
                    }
                }
                break;
            case pagetotal:
            case "last":
                if (now !== pagetotal) {
                    now = pagetotal
                    for (let i = now; i > (pagetotal <= 3 ? 0 : now - 3); i--) {
                        array.push({"active": i === now, "page": i})
                    }
                    array.reverse()
                }
                break;
            case "next":
                if (now !== pagetotal) {
                    now++
                    for (let i = (pagetotal <= 3 ? pagetotal : (now === pagetotal ? pagetotal : now + 1)); i > (pagetotal <= 3 ? 0 : (now === pagetotal ? now - 3 : now - 2)); i--) {
                        array.push({"active": i === now, "page": i})
                    }
                    array.reverse()
                }
                break;
            default:
                now = value
                for (let i = now - 1; i <= now + 1; i++) {
                    array.push({"active": i === now, "page": i})
                }
                break;
        }

        this.setState({"nowPage": now})
        if (array.length !== 0) {
            this.setState({"pageItem": array})
        }
        this.pageChange(now)
    }

    render() {
        return (<>
            <div className="changePage paginationFontSize">
                {(this.state.pageTotal > 0 ?
                    <Row>
                        <Col/>
                        <Col xs={"auto"}>
                            <Pagination>
                                <Pagination.First id="paginationFirst" disabled={this.state.nowPage === 1}
                                                  onClick={() => {
                                                      this.paginationChange("first")
                                                  }}>第一頁</Pagination.First>
                                <Pagination.Prev id="paginationPrev" disabled={this.state.nowPage === 1}
                                                 onClick={() => {
                                                     this.paginationChange("prev")
                                                 }}/>
                                {this.state.pageItem.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Pagination.Item className="paginationItem"
                                                             active={item.page === this.state.nowPage} onClick={() => {
                                                this.paginationChange(item.page)
                                            }}>{item.page}</Pagination.Item>
                                        </React.Fragment>
                                    )
                                })}
                                <Pagination.Next id="paginationNext"
                                                 disabled={this.state.pageTotal === this.state.nowPage} onClick={() => {
                                    this.paginationChange("next")
                                }}/>
                                <Pagination.Last id="paginationLast"
                                                 disabled={this.state.pageTotal === this.state.nowPage} onClick={() => {
                                    this.paginationChange("last")
                                }}>最末頁</Pagination.Last>
                            </Pagination>
                        </Col>
                        <Col/>
                    </Row> : <></>)}
            </div>
        </>)
    }
}