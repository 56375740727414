import { FetchUtil } from "../util/fetchUtil"

const BASE_URL = "/team-description"

export class SubgroupRequest {
    static create(data) {
        return FetchUtil.postFormDataAPI(BASE_URL, data);
    }

    static update(data, id) {
        return FetchUtil.patchFormDataAPI(`${ BASE_URL }/${ id }`, data);
    }

    static delete(id) {
        return FetchUtil.deleteHasTokenAPI(`${ BASE_URL }/${ id }`);
    }

    static searchList() {
        return FetchUtil.getAPI(BASE_URL);
    }

    static getDetail(id) {
        return FetchUtil.getAPI(`${ BASE_URL }/${ id }`)
    }
}