import React from "react";
import BreadcrumbObject from "../../../js/breadcrumb";
import { Editors } from "../../../js/editorTool";
import aboutType from "../../../js/about";

class Chairman extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.chairman.id,
            editorTypeName: aboutType.chairman.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "關於本會" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );
    }
}

export default {
    routeProps: {
        path: "/chairman",
        component: Chairman
    },
    name: "歷屆理事長",
}
