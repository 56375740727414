import React from "react";
import aboutType from "../../../js/about";
import BreadcrumbObject from "../../../js/breadcrumb";
import { Editors } from "../../../js/editorTool";


class PropertyInvestment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorTypeId: aboutType.propertyInvestment.id,
            editorTypeName: aboutType.propertyInvestment.name,
        };
    }

    render() {
        return (
            <div>
                <BreadcrumbObject secondTitle={ "教育訓練" } thirdTitle={ this.state.editorTypeName }/>
                <Editors editorTypeId={ this.state.editorTypeId }/>
            </div>
        );

    }
}

export default {
    routeProps: {
        path: "/Education-And-Train/Property-Investment",
        component: PropertyInvestment
    },
    name: "產投課程"
}
